import { defineStore } from 'pinia'
import type { ContractAssets, SubAccountAssets, SubAccountBalance, UserAssetInfo } from '../types/index'

import { useFutureStore } from './future'
import {
  getAssetInfo,
  getBonusTradeable,
  getFollowAccount,
  getFollowTradeable as getFollowAccountTradeable,
  getStakingAasset,
  getUserAsset,
  getUserContractAsset,
  getUserFutureTradeable
  , getUserTransferAssets
} from '~/api'
import type {
  CoinAssetInfo,
  FutureTradeable,
  UserTransferAssets
} from '~/types'
import Cookie from '~/utils/cookie'
import type { QuoteWS } from '~/types/ws'
import { SubAccountType } from '~/types/enums'
import { MAIN_ACCOUNT_INDEX } from '~/config/const'

export interface AssetList {
  availableMargin: string
  coinAvailable: string
  margin: string
  orderMargin: string
  realisedPnl: string
  unrealisedPnl: string
  experienceBalance: string
  canTransfer: string
  totalEquity: string
}
export const useAssetStore = defineStore('asset', () => {
  const futureStore = useFutureStore()
  const { symbolList, symbolId, getSymbolInfoBySymbolId } = $(storeToRefs(futureStore))

  // 是否隐藏资产
  let isShow = $ref(true)

  let assetInfo = $ref<UserAssetInfo>({} as UserAssetInfo)

  let coinAssets = $ref<Record<string, CoinAssetInfo>>({})
  // 可划转余额
  let futureUSDTAssets = $ref<UserTransferAssets>({
    amount: '0',
    success: true,
    experienceBalance: '0'
  })
  // 合约USDT余额
  const futureAssets = $ref({
    availableMargin: '--',
    experienceBalance: '--',
    canTransfer: '--',
    totalEquity: '--'
  })
  //  合约币对可交易资产列表
  let futureAssetsList = $ref<FutureTradeable[]>([])
  //  跟单账户币对可交易资产列表
  let followAssetsList = $ref<FutureTradeable[]>([])
  // 合约资产列表
  let futureAssetDataList = $ref<ContractAssets[]>([])
  // 合约赠金帐号币对可交易资产列表
  let futureBonusAssetsList = $ref<FutureTradeable[]>([])
  // 理财资产列表
  let stakingAssetList = $ref([])
  // 跟单账户资产
  let followAssets = $ref<Partial<SubAccountAssets>>({
    balance: []
  })
  /**
   * 获取用户现货资产
   */
  const getUserAssets = async () => {
    const accountId = Cookie.get('account_id')

    if (!accountId)
      return

    const { res, err } = await getUserAsset({
      account_id: accountId
    })

    if (err)
      return

    coinAssets = Object.fromEntries(res.data.map(item => [item.tokenId, item]))
  }

  const userAssetsDataHandler = (data: QuoteWS.SpotCoinAssetsData) => {
    if (data.topic !== 'balance')
      return

    if (!data.data?.length)
      return

    data.data.forEach((item) => {
      coinAssets[item.tokenId] = item
    })
  }

  /**
   * 获取跟单账户资产
   */
  const getFollowAssets = async () => {
    const accountId = Cookie.get('account_id')

    if (!accountId)
      return

    const { res, err } = await getFollowAccount({})

    if (err)
      return

    followAssets = res.data
  }

  /**
   * 获取资产总览信息
   */
  const getAssetInfoAction = async () => {
    const accountId = Cookie.get('account_id')

    if (!accountId)
      return

    const { res, err } = await getAssetInfo({
      unit: 'USDT'
    })

    if (err)
      return

    assetInfo = res.data
  }

  /**
   * 获取理财资产列表
   */
  const getStakingAssetListAction = async () => {
    const accountId = Cookie.get('account_id')

    if (!accountId)
      return

    const { res, err } = await getStakingAasset()

    if (err)
      return

    stakingAssetList = res.data.assetInfoList
  }
  /**
   * 获取用户可划转余额
   */
  const getUserUSDTAssets = async () => {
    const accountId = Cookie.get('account_id')

    if (!accountId)
      return

    const { res, err } = await getUserTransferAssets({
      account_index: MAIN_ACCOUNT_INDEX, // 主账户index
      account_type: SubAccountType.FUTURE, // 合约账户
      token_id: 'USDT'
    })

    if (err)
      return

    futureUSDTAssets = res.data
    // futureUSDTAssets.amount = res.data.amount
    // futureUSDTAssets.experienceBalance = res.data.experienceBalance
    // futureUSDTAssets.success = res.data.success
  }
  /**
   * 获取用户合约资产
   */
  const getUserContractAssets = async (params?: { token_ids: string }) => {
    const { res, err } = await getUserContractAsset(params ?? {})
    if (err)
      return

    res.data.forEach((item) => {
      if (item.tokenId === 'USDT') {
        futureAssets.availableMargin = item.availableMargin
        futureAssets.totalEquity = item.totalEquity
        futureAssets.canTransfer = item.canTransfer
      }
      futureAssets.experienceBalance = item.experienceBalance
    })

    futureAssetDataList = res.data
  }

  /**
   * 获取用户合约资产
   */
  const getFutureTradeable = async () => {
    const { res, err } = await getUserFutureTradeable({
      token_ids: symbolList.map(item => item.symbolId).join(','),
      exchange_id: symbolList[0]?.exchangeId
    })
    if (err)
      return

    futureAssetsList = res.data
  }
  /**
   * 获取用户跟单账户合约资产
   */
  const getFollowTradeable = async () => {
    const { res, err } = await getFollowAccountTradeable({
      token_ids: symbolList.map(item => item.symbolId).join(','),
      exchange_id: symbolList[0]?.exchangeId
    })
    if (err)
      return

    followAssetsList = res.data
  }
  /**
   * 用户合约可交易资产map
   */
  const futureAssetsMap = $computed(() => {
    return Object.fromEntries(
      futureAssetsList.map(tradeable => [tradeable.tokenId, tradeable])
    )
  })
  /**
   * 用户合约可交易资产map
   */
  const futureAssetsDataMap = $computed(() => {
    return Object.fromEntries(
      futureAssetDataList.map(tradeable => [tradeable.tokenId, tradeable])
    )
  })
  /**
   * 用户跟单可交易资产map
   */
  const followAssetsMap = $computed(() => {
    return Object.fromEntries(
      followAssetsList.map(tradeable => [tradeable.tokenId, tradeable])
    )
  })
  /**
   * 用户现货可用资产
   */
  const tokenFreeGetter = $computed(() => {
    return (tokenId: string) => {
      if (!coinAssets[tokenId])
        return '0'

      return coinAssets[tokenId].free
    }
  })
  const balanceDataHandler = async (data: QuoteWS.FutureBalanceData) => {
    if (data.topic !== 'futures_balance')
      return

    if (data.data) {
      data.data.forEach((item) => {
        if (item.tokenId === 'USDT') {
          futureAssets.availableMargin = item.availableMargin
          futureAssets.totalEquity = item.totalEquity
          futureAssets.canTransfer = item.canTransfer
        }
        futureAssets.experienceBalance = item.experienceBalance
      })

      futureAssetDataList = data.data
    }
  }

  const tradeableDataHandler = (data: QuoteWS.TradeableData) => {
    if (data.topic !== 'futures_tradeable')
      return

    if (data.data) {
      const tempData: FutureTradeable[] = []

      data.data.forEach((item) => {
        if (futureAssetsMap[item.tokenId])
          Object.assign(futureAssetsMap[item.tokenId], item)
        else tempData.push(item)
      })

      futureAssetsList = [...futureAssetsList, ...tempData]
    }
  }

  const followTradeableDataHandler = (data: QuoteWS.TradeableData) => {
    if (data.topic !== 'futures_follow_tradeable')
      return

    if (data.data) {
      const tempData: FutureTradeable[] = []

      data.data.forEach((item) => {
        if (followAssetsMap[item.tokenId])
          Object.assign(followAssetsMap[item.tokenId], item)
        else tempData.push(item)
      })

      followAssetsList = [...followAssetsList, ...tempData]
    }
  }

  /**
   * 用户合约交易所有资产
   */
  const futureExchangeAssetList = $computed(() => {
    const initialAssets = reactive<AssetList>({
      availableMargin: '--',
      coinAvailable: '--',
      margin: '--',
      orderMargin: '--',
      realisedPnl: '--',
      unrealisedPnl: '--',
      experienceBalance: '--',
      canTransfer: '--',
      totalEquity: '--'
    })
    const symbolInfo = getSymbolInfoBySymbolId(symbolId)

    return Object.assign(initialAssets, {
      availableMargin: futureAssetsDataMap[symbolInfo.quoteTokenId]?.availableMargin,
      totalEquity: futureAssetsDataMap[symbolInfo.quoteTokenId]?.totalEquity,
      canTransfer: futureAssetsDataMap[symbolInfo.quoteTokenId]?.canTransfer,
      coinAvailable: futureAssetsMap[symbolId]?.profitLoss.coinAvailable,
      margin: futureAssetsMap[symbolId]?.profitLoss.margin,
      orderMargin: futureAssetsMap[symbolId]?.profitLoss.orderMargin,
      realisedPnl: futureAssetsMap[symbolId]?.profitLoss.realisedPnl,
      unrealisedPnl: futureAssetsMap[symbolId]?.profitLoss.unrealisedPnl,
      experienceBalance: futureAssetsDataMap[symbolInfo.quoteTokenId]?.experienceBalance
    })
  })
  /**
   * 全仓资产
   */
  const futureCrossAssetList = $computed(() => {
    return (quoteTokenId = 'USDT') => {
      const initialAssets = reactive<AssetList>({
        availableMargin: '--',
        coinAvailable: '--',
        margin: '--',
        orderMargin: '--',
        realisedPnl: '--',
        unrealisedPnl: '--',
        experienceBalance: '--',
        canTransfer: '--',
        totalEquity: '--'
      })

      return Object.assign(initialAssets, {
        availableMargin: futureAssets.availableMargin,
        totalEquity: futureAssets.totalEquity,
        canTransfer: futureAssets.canTransfer,
        coinAvailable: futureAssetsMap[`CROSS_${quoteTokenId}`]?.profitLoss.coinAvailable,
        margin: futureAssetsMap[`CROSS_${quoteTokenId}`]?.profitLoss.margin,
        orderMargin: futureAssetsMap[`CROSS_${quoteTokenId}`]?.profitLoss.orderMargin,
        realisedPnl: futureAssetsMap[`CROSS_${quoteTokenId}`]?.profitLoss.realisedPnl,
        unrealisedPnl: futureAssetsMap[`CROSS_${quoteTokenId}`]?.profitLoss.unrealisedPnl,
        experienceBalance: futureAssets.experienceBalance
      })
    }
  })
  /**
   * 用户当前合约可交易资产
   */
  const currentSymbolTradeable = $computed(() => {
    if (!futureAssetsMap[symbolId])
      return null

    return futureAssetsMap[symbolId]
  })
  /** 用户合约赠金账户可交易资产map */
  const futureBonusAssetsMap = $computed(() => {
    return Object.fromEntries(
      futureBonusAssetsList.map(tradeable => [tradeable.tokenId, tradeable])
    )
  })
  /**
   * 用户赠金账户当前合约可交易资产
   */
  const currentBonusSymbolTradeable = computed(() => {
    if (!futureBonusAssetsMap[symbolId])
      return null

    return futureBonusAssetsMap[symbolId]
  })

  const bonusTradeableDataHandler = (data: QuoteWS.TradeableData) => {
    if (data.topic !== 'futures_grant_gold_coupon_tradeable')
      return

    if (data.data) {
      const tempData: FutureTradeable[] = []

      data.data.forEach((item) => {
        if (futureBonusAssetsMap[item.tokenId])
          Object.assign(futureBonusAssetsMap[item.tokenId], item)
        else tempData.push(item)
      })

      futureBonusAssetsList = [...futureBonusAssetsList, ...tempData]
    }
  }
  /** 获取用户赠金账户可交易合约资产 */
  const getFutureBonusTradeable = async () => {
    const { res, err } = await getBonusTradeable({
      token_ids: symbolList.map(item => item.symbolId).join(','),
      exchange_id: symbolList[0]?.exchangeId
    })
    if (err)
      return

    futureBonusAssetsList = res.data
  }

  /**
   * 切换资产展示与隐藏
   */
  const toggleIsShow = () => {
    isShow = !isShow
  }

  /**
   * 跟单资产的映射
   */
  const followAccountMap = $computed(() => {
    const map = $ref<Record<string, SubAccountBalance>>({})
    followAssets?.balance?.forEach((item) => {
      map[item.tokenId] = item
    })

    return map
  })

  return {
    futureAssetDataList: $$(futureAssetDataList),
    stakingAssetList: $$(stakingAssetList),
    assetInfo: $$(assetInfo),
    coinAssets: $$(coinAssets),
    futureUSDTAssets: $$(futureUSDTAssets),
    isShow: $$(isShow),

    tokenFreeGetter: $$(tokenFreeGetter),
    futureExchangeAssetList: $$(futureExchangeAssetList),
    futureCrossAssetList: $$(futureCrossAssetList),
    futureAssetsMap: $$(futureAssetsMap),
    followAssetsMap: $$(followAssetsMap),
    currentSymbolTradeable: $$(currentSymbolTradeable),
    followAccountMap: $$(followAccountMap),
    futureAssetsDataMap: $$(futureAssetsDataMap),

    getUserAssets,
    tradeableDataHandler,
    getFollowTradeable,
    followTradeableDataHandler,
    getUserContractAssets,
    balanceDataHandler,
    getFutureTradeable,
    getUserUSDTAssets,
    userAssetsDataHandler,
    getAssetInfoAction,
    getStakingAssetListAction,
    toggleIsShow,
    getFollowAssets,
    bonusTradeableDataHandler,
    getFutureBonusTradeable,
    currentBonusSymbolTradeable
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useAssetStore, import.meta.hot))
