import type { IGridItem, IStrategyGridItem } from '~/types'
import { FutureOrderPriceType, FuturePlanOrderType, FutureStopConditionType, StrategyGridType } from '~/types/enums'

/** 极验  */
export const CAPTCHA_TYPE = 2
// export const SENSE_ID = 'cc8e795808d496188b1298b802b40b1c' // v3 极验的id todo 修改
export const SENSE_ID = 'ce0e1c1978c3babe147517930af8464f' // v4
export const SITE_KEY = '6LdC9_cdAAAAANi4kZ5Vyjx4i027busBrMwn0B4W' // google_captchaId todo 修改
export const PLATFORM_DATA_KEY = 'cust.platformData' // 获取平台自定义数据的key
export const USE_BINARY_DATA = useLocalStorage('useWSBinaryData', true) // 是否使用二进制数据
export const USER_SHARE_SHIP_KEY = 'SHARE_USER_SHIP' // 推荐带单关系map
export const GLOBAL_NOTICE_KEY = 'GLOBAL_NOTICE' // 全局公告
export const FUTURE_NO_MORE_BONUS_REMINDER_KEY = 'future-no-more-bonus-reminder'
export const FUTURE_LAYOUT_KEY = 'FUTURE_LAYOUT_NEW_4'
export const STRATEGY_LAYOUT_KEY = 'STRATEGY_LAYOUT'
export const FUTURE_TOUR_KEY = 'FUTURE_TOUR_ALREADY_SHOW'
export const FUTURE_RESOLUTION_KEY = 'FUTURE_RESOLUTION'
export const FUTURE_SHOW_HIGH_LOW_PRICE_AND_LABEL_KEY = 'FUTURE_SHOW_HIGH_LOW_PRICE_AND_LABEL_KEY'
export const FINGER_PRINT_KEY = 'FINGER_PRINT'
export const MARKET_TAB_KEY = 'MARKET_TAB'
export const GLOBAL_AD_KEY = 'GLOBAL_AD'
export const EVERY_TIME_AD_KEY = 'EVERY_TIME_AD'
export const HEADER_RESOLUTION_KEY = 'FUTURE_HEADER_RESOLUTION'
export const FUTURE_SEARCH_HISTORY_KEY = 'FUTURE_SEARCH_HISTORY'
export const SPOT_SEARCH_HISTORY_KEY = 'SPOT_SEARCH_HISTORY'
export const STRATEGY_HEADER_RESOLUTION_KEY = 'STRATEGY_HEADER_RESOLUTION_KEY'
export const SPOT_HEADER_RESOLUTION_KEY = 'SPOT_HEADER_RESOLUTION'
export const HISTORY_HEADER_RESOLUTION_KEY = 'HISTORY_HEADER_RESOLUTION_KEY'
export const MAIN_ACCOUNT_INDEX = 0 // 主账户的index
export const DEFAULT_THEME = {
  light: {
    primary: '#F5562A',
    success: '#38C066',
    warning: '#FFB000',
    error: '#E4464F',
    background: '#ffffff',
    exchangePageBg: '#F7F7F8',
    disabledBg: '#E1E5E9',
    inputBg: '#F7F7F8',
    pageBg: '#F5F5F5',
    subTitle: '#B3BCC9',
    title: '#858FA1',
    mainTitle: '#1C1B1B',
    splitLine: '#EFF2F5',
    grow: '#10B77E',
    down: '#E52A4B',
    sliderBg: '#C5CCD5',
    sliderForegroundColor: '#6F8096',
    homeBannerBg: '#17171F',
    white: '#ffffff',
    black: '#000000'
  },

  dark: {
    primary: '#F5562A',
    success: '#38C066',
    warning: '#FFB000',
    error: '#E4464F',
    background: '#0D0B19',
    exchangePageBg: '#2F3949',
    inputBg: '#232C3A',
    disabledBg: '#232C3A',
    pageBg: '#2F3949',
    title: '#858FA1',
    subTitle: '#515E71',
    mainTitle: '#FFFFFF',
    splitLine: '#222A39',
    grow: '#10B77E',
    down: '#E52A4B',
    sliderBg: '#3F4A5B',
    sliderForegroundColor: '#C5CCD5',
    homeBannerBg: '#F5562A',
    white: '#ffffff',
    black: '#000000'
  }
}

export const INITIAL_FUTURE_LAYOUT: IGridItem[] = [
  {
    x: 0,
    y: 0,
    w: 45,
    h: 6,
    i: 'info',
    static: false,
    resizable: true,
    draggable: true,
    dragAllowFrom: '.draggable-handler',
    dragIgnoreFrom: '.no-drag',
    moved: false
  },
  {
    x: 0,
    y: 6,
    w: 45,
    h: 61,
    i: 'chart',
    static: false,
    refName: 'chartRef',
    dragAllowFrom: '.draggable-handler',
    dragIgnoreFrom: '.no-drag',
    moved: false
  },
  {
    x: 0,
    y: 67,
    w: 57,
    h: 45,
    i: 'order',
    static: false,
    dragAllowFrom: '.draggable-handler',
    dragIgnoreFrom: '.no-drag',
    moved: false
  },
  {
    x: 45,
    y: 0,
    w: 12,
    h: 44,
    i: 'handicap',
    static: false,
    dragAllowFrom: '.draggable-handler',
    dragIgnoreFrom: '.no-drag',
    moved: false
  },
  {
    x: 45,
    y: 44,
    w: 12,
    h: 23,
    i: 'lastTrade',
    static: false,
    dragAllowFrom: '.draggable-handler',
    dragIgnoreFrom: '.no-drag',
    moved: false
  },
  {
    x: 57,
    y: 0,
    w: 15,
    h: 65,
    i: 'orderCreate',
    static: false,
    dragAllowFrom: '.draggable-handler',
    dragIgnoreFrom: '.no-drag',
    isResizable: false,
    minW: 15,
    maxW: 15,
    moved: false
  },
  {
    x: 57,
    y: 65,
    w: 15,
    h: 47,
    i: 'assets',
    static: false,
    dragAllowFrom: '.draggable-handler',
    dragIgnoreFrom: '.no-drag',
    moved: false
  }
]
export const FUTURE_PRICE_COMPARE_SYMBOL_MAP: Record<
FuturePlanOrderType,
string
> = {
  [FuturePlanOrderType.STOP_COMMON]: '',
  [FuturePlanOrderType.STOP_SHORT_LOSS]: '>=',
  [FuturePlanOrderType.STOP_SHORT_PROFIT]: '<=',
  [FuturePlanOrderType.STOP_LONG_LOSS]: '<=',
  [FuturePlanOrderType.STOP_LONG_PROFIT]: '>='
}

export const INITIAL_STRATEGY_LAYOUT: IStrategyGridItem[] = [
  {
    x: 0,
    y: 0,
    w: 72,
    h: 6,
    i: 'info',
    static: true,
    resizable: false,
    draggable: false,
    dragAllowFrom: '.draggable-handler',
    dragIgnoreFrom: '.no-drag'
  },
  {
    x: 0,
    y: 6,
    w: 45,
    h: 68,
    i: 'chart',
    static: true,
    resizable: false,
    draggable: false,
    refName: 'chartRef',
    dragAllowFrom: '.draggable-handler',
    dragIgnoreFrom: '.no-drag'
  },
  {
    x: 0,
    y: 74,
    w: 57,
    h: 42,
    i: 'order',
    static: true,
    resizable: false,
    draggable: false,
    dragAllowFrom: '.draggable-handler',
    dragIgnoreFrom: '.no-drag'
  },
  {
    x: 45,
    y: 6,
    w: 12,
    h: 42,
    i: 'handicap',
    static: true,
    resizable: false,
    draggable: false,
    dragAllowFrom: '.draggable-handler',
    dragIgnoreFrom: '.no-drag'
  },
  {
    x: 45,
    y: 48,
    w: 12,
    h: 26,
    i: 'lastTrade',
    static: true,
    resizable: false,
    draggable: false,
    dragAllowFrom: '.draggable-handler',
    dragIgnoreFrom: '.no-drag'
  },
  {
    x: 57,
    y: 6,
    w: 15,
    h: 110,
    i: 'orderCreate',
    static: true,
    resizable: false,
    draggable: false,
    dragAllowFrom: '.draggable-handler',
    dragIgnoreFrom: '.no-drag',
    isResizable: false,
    minW: 15,
    maxW: 15
  }

]
/**
 * 主流币列表
 */
export const MAIN_COIN = ['BTC-SWAP-USDT', 'ETH-SWAP-USDT']
/**
 * 主流币默认网格参数
 */
export const MAIN_COIN_GRID_INITIAL_PARAMS = {
  leverage: 10,
  maxPriceFactor: 1.05,
  minPriceFactor: 0.95,
  gridCount: 30,
  gridType: StrategyGridType.EQUAL_DIFF
}
/**
 * 山寨币默认网格参数
 */
export const OTHER_COIN_GRID_INTITAL_PARAMS = {
  leverage: 5,
  maxPriceFactor: 1.1,
  minPriceFactor: 0.9,
  gridCount: 60,
  gridType: StrategyGridType.EQUAL_DIFF
}

export const MIN_GRID_COUNT = 2
export const MAX_GRID_COUNT = 100
export const STOP_LOSS_CONDITION_TYPE = [
  { name: '标记价格', value: FutureStopConditionType.MARK },
  { name: '市场价格', value: FutureStopConditionType.MARKET }
]
export const FUTURE_PRICE_TYPE = [
  {
    label: '限价',
    value: FutureOrderPriceType.LIMIT
  },
  {
    label: '市价',
    value: FutureOrderPriceType.MARKET
  }
]

export const ONE_SECOND = 1000
export const ONE_MINUTES = 60 * ONE_SECOND
export const ONE_HOUR = 60 * ONE_MINUTES
export const ONE_DAY = 24 * ONE_HOUR
export const ONE_MONTH = 30 * 24 * 3600 * 1000
export const THREE_MONTH = 3 * ONE_MONTH
export const SIX_MONTH = 6 * ONE_MONTH
export const GF_LINK_URL = 'https://docs.google.com/forms/d/e/1FAIpQLSfTa8l40AAhR6cUzCJL1B0kt-TQTEt9p3VlHM0vEQE2tCf4uA/viewform?usp=sf_link'
