import type { Nullable } from 'vitest'
import { getFutureBonusInfo, getFutureBonusLeverage } from '~/api'
import { useFutureStore } from '~/store/future'
import type { Futures } from '~/types/futures'
import type { QuoteWS } from '~/types/ws'

export function useFutureBonus() {
  const { send: getAvailableBonusAction } = useRequest(getFutureBonusInfo, undefined, { immediate: false })
  const [availableBonus, setAvailableBonus] = useState<Nullable<Futures.AvailableBonus>>(null)
  const [currentBonusLongLeverage, setCurrentBonusLongLeverage] = useState(10)
  const [currentBonusShortLeverage, setCurrentBonusShortLeverage] = useState(10)

  async function getAvailableBonus() {
    const { res, err } = await getAvailableBonusAction()

    if (err)
      return

    setAvailableBonus(res?.data)
  }

  async function getCurrentBonusLeverage() {
    const futureStore = useFutureStore()
    const { symbolId } = $(storeToRefs(futureStore))

    const { res, err } = await getFutureBonusLeverage({ symbol_id: symbolId })

    if (err)
      return

    setCurrentBonusLongLeverage(res.data.leverageLong)
    setCurrentBonusShortLeverage(res.data.leverageShort)
  }

  const availableBonusDataHandler = (data: QuoteWS.FutureAvailableBonusData) => {
    if (data.topic !== 'futures_grant_gold_coupon_balance')
      return

    if (!data.data)
      return

    setAvailableBonus(data.data)
  }
  const bonusLeverageDataHandler = (data: QuoteWS.FutureLeverageData) => {
    if (data.topic !== 'futures_grant_gold_coupon_leverage')
      return

    if (!data.data)
      return

    getCurrentBonusLeverage()
  }

  return {
    availableBonus,
    currentBonusLongLeverage,
    currentBonusShortLeverage,
    getAvailableBonus,
    availableBonusDataHandler,
    getCurrentBonusLeverage,
    bonusLeverageDataHandler
  }
}
