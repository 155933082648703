import type { MaybeRef } from '@vueuse/core'
import type {
  GetEntrustParams,
  GetHistoryTradeParams,
  GetPositionListParams
} from '~/api'
import {
  getBonusCurrentEntrustList as getCurrentEntrustList,
  getBonusPositionList as getCurrentPositionList,
  getBonusStopProfitLossList as getCurrentStopLossEntrust,
  getBonusHistoryEntrustList as getHistoryEntrustList,
  getBonusStopProfitLossHistory as getHistoryStopLossEntrust,
  getBonusHistoryTradeList as getHistoryTradeList
} from '~/api'

import type { FutureHistoryTrade } from '~/types'
import {
  FutureOrderListType,
  FutureOrderStatus,
  FuturePlanOrderType
} from '~/types/enums'
import type { QuoteWS } from '~/types/ws'
import { excludeRepeatArray } from '~/utils/helper'

let currentOrders = $shallowRef<QuoteWS.OrderInfo[]>([])
let historyOrders = $shallowRef<QuoteWS.OrderInfo[]>([])
let currentPositions = $shallowRef<QuoteWS.PositionInfo[]>([])
let historyTradeOrders = $shallowRef<FutureHistoryTrade[]>([])

let noticeCount = $ref(0)
const [positionLoading, togglePositionLoading] = $(useToggle(false))
const [historyTradeLoading, toggleHistoryTradeLoading] = $(useToggle(false))
const [historyLimitOrderLoading, toggleHistoryLimitOrderLoading] = $(
  useToggle(false)
)
const [historyStopOrderLoading, toggleHistoryStopOrderLoading] = $(
  useToggle(false)
)
const [historyStopLossOrderLoading, toggleHistoryStopLossOrderLoading] = $(
  useToggle(false)
)
const [currentLimitOrderLoading, toggleCurrentLimitOrderLoading] = $(
  useToggle(false)
)

const [currentStopOrderLoading, toggleCurrentStopOrderLoading] = $(
  useToggle(false)
)
const [currentStopLossOrderLoading, toggleCurrentStopLossOrderLoading] = $(
  useToggle(false)
)

const [noMorePositionData, toggleNoMorePositionData] = $(useToggle(false))
const [noMoreCurrentLimitData, toggleNoMoreCurrentLimitData] = $(
  useToggle(false)
)
const [noMoreCurrentStopData, toggleNoMoreCurrentStopData] = $(useToggle(false))
const [noMoreCurrentStopLossData, toggleNoMoreCurrentStopLossData] = $(
  useToggle(false)
)
const [noMoreHistoryLimitData, toggleNoMoreHistoryLimitData] = $(
  useToggle(false)
)
const [noMoreHistoryStopData, toggleNoMoreHistoryStopData] = $(useToggle(false))
const [noMoreHistoryStopLossData, toggleNoMoreHistoryStopLossData] = $(
  useToggle(false)
)

const [noMoreHistoryTradeData, toggleNoMoreHistoryTradeData] = $(
  useToggle(false)
)

const showAllSymbolOrder = $ref(false)

function resetNoticeCount() {
  noticeCount = 0
}

export function useFutureBonusOrderData(symbolId: MaybeRef<string>) {
  const symbolIdRef = $ref(symbolId)

  const filterCurrentOrders = computed(() => {
    if (showAllSymbolOrder)
      return currentOrders

    return currentOrders.filter((item) => {
      return item.symbolId === symbolIdRef
    })
  })

  const filterCurrentStopOrders = computed(() => {
    return filterCurrentOrders.value.filter((item) => {
      return (
        item.type === FutureOrderListType.STOP
        && item.planOrderType !== FuturePlanOrderType.STOP_COMMON
      )
    })
  })

  const filterHistoryOrders = computed(() => {
    if (showAllSymbolOrder)
      return historyOrders

    return historyOrders.filter((item) => {
      return item.symbolId === symbolIdRef
    })
  })

  const filterHistoryStopOrders = computed(() => {
    return filterHistoryOrders.value.filter((item) => {
      return (
        item.type === FutureOrderListType.STOP
        && item.planOrderType !== FuturePlanOrderType.STOP_COMMON
      )
    })
  })

  const filterCurrentPositions = computed(() => {
    if (showAllSymbolOrder)
      return currentPositions

    return currentPositions.filter(item => item.symbolId === symbolIdRef)
  })

  const filterHistoryTradeOrders = computed(() => {
    if (showAllSymbolOrder)
      return historyTradeOrders

    return historyTradeOrders.filter(item => item.symbolId === symbolIdRef)
  })

  const dealOrderData = (orderData: QuoteWS.OrderInfo[], fromWS = false) => {
    let tempNoticeCount = noticeCount

    orderData.forEach((order) => {
      // 状态为NEW和PARTIAL_FILLED的订单视为当前委托
      if (
        [
          FutureOrderStatus.NEW,
          FutureOrderStatus.PARTIALLY_FILLED,
          FutureOrderStatus.ORDER_NEW,
          FutureOrderStatus.ORDER_PARTIALLY_FILLED
        ].includes(order.status)
      ) {
        if (
          !currentOrders.length
          || (currentOrders.length && +order.time - +currentOrders[0].time > 0)
        ) {
          currentOrders.unshift(order)
        }
        else {
          const sameOrder = currentOrders.find(
            item => item.orderId === order.orderId
          )

          if (sameOrder)
            Object.assign(sameOrder, order)
          else currentOrders.push(order)
        }
      }

      // 状态为FILLED和CANCELED的订单视为历史委托
      if (
        [
          FutureOrderStatus.FILLED,
          FutureOrderStatus.CANCELED,
          FutureOrderStatus.ORDER_CANCELED,
          FutureOrderStatus.ORDER_FILLED
        ].includes(order.status)
      ) {
        currentOrders = currentOrders.filter(
          item => item.orderId !== order.orderId
        )

        if (
          [FutureOrderStatus.FILLED, FutureOrderStatus.ORDER_FILLED].includes(
            order.status
          )
          && fromWS
        )
          tempNoticeCount += 1

        if (
          !historyOrders.length
          || (historyOrders.length && +order.time - +historyOrders[0].time > 0)
        ) {
          historyOrders.unshift(order)
        }
        else {
          const sameOrder = historyOrders.find(
            item => item.orderId === order.orderId
          )

          if (sameOrder)
            Object.assign(sameOrder, order)
          else historyOrders.push(order)
        }
      }
    })

    noticeCount = tempNoticeCount

    // 去除重复订单
    currentOrders = excludeRepeatArray('orderId', currentOrders)
    currentOrders.sort((a, b) => +b.time - +a.time)

    historyOrders = excludeRepeatArray('orderId', historyOrders)
    historyOrders.sort((a, b) => +b.time - +a.time)
  }

  const orderDataHandler = (data: QuoteWS.OrderData) => {
    if (data.topic !== 'futures_grant_gold_coupon_order')
      return

    const orderData = data.data
    if (!orderData?.length)
      return

    dealOrderData(orderData, true)
  }

  const orderHttpAction = async (
    isStopRequest = false,
    params: Partial<GetEntrustParams> = {}
  ) => {
    if (currentLimitOrderLoading && params.type === FutureOrderListType.LIMIT)
      return

    if (currentStopOrderLoading && params.type === FutureOrderListType.STOP)
      return

    if (currentStopLossOrderLoading && isStopRequest)
      return

    if (params.from_order_id) {
      if (params.type === FutureOrderListType.LIMIT && noMoreCurrentLimitData)
        return

      if (params.type === FutureOrderListType.STOP && noMoreCurrentStopData)
        return

      if (isStopRequest && noMoreCurrentStopLossData)
        return
    }

    const method = isStopRequest
      ? getCurrentStopLossEntrust
      : getCurrentEntrustList

    const mergedParams = {
      limit: 50,
      ...params
    }

    let toggleFunc = toggleCurrentLimitOrderLoading

    if (params.type === FutureOrderListType.STOP)
      toggleFunc = toggleCurrentStopOrderLoading
    if (isStopRequest)
      toggleFunc = toggleCurrentStopLossOrderLoading

    toggleFunc(true)
    const { res, err } = await method(mergedParams)
    toggleFunc(false)

    if (err)
      return

    if (res.data.length < mergedParams.limit) {
      if (params.type === FutureOrderListType.LIMIT)
        toggleNoMoreCurrentLimitData(true)

      if (params.type === FutureOrderListType.STOP)
        toggleNoMoreCurrentStopData(true)

      if (isStopRequest)
        toggleNoMoreCurrentStopLossData(true)
    }

    dealOrderData(res.data)
  }

  const dealPositionData = (positionData: QuoteWS.PositionInfo[]) => {
    // 去除重复订单
    // currentPositions = excludeRepeatArray('positionId', positionData)
    currentPositions = positionData
    // currentPositions.sort((a, b) => a.symbolId.localeCompare(b.symbolId))
  }

  const positionDataHandler = (data: QuoteWS.PositionData) => {
    if (data.topic !== 'futures_grant_gold_coupon_position' || data.event === 'subbed')
      return

    const positionData = data.data

    dealPositionData(positionData ?? [])
  }

  const getHistoryEntrustData = async (
    isStopRequest = false,
    params: Partial<GetEntrustParams> = {}
  ) => {
    if (params.type === FutureOrderListType.LIMIT && historyLimitOrderLoading)
      return

    if (params.type === FutureOrderListType.STOP && historyStopOrderLoading)
      return

    if (isStopRequest && historyStopLossOrderLoading)
      return

    // 不传是查最新的 传了是查更多 差更多才验证是否没有后继数据
    if (params.from_order_id) {
      if (params.type === FutureOrderListType.STOP && noMoreHistoryStopData)
        return

      if (params.type === FutureOrderListType.LIMIT && noMoreHistoryLimitData)
        return

      if (isStopRequest && noMoreHistoryStopLossData)
        return
    }

    const method = isStopRequest
      ? getHistoryStopLossEntrust
      : getHistoryEntrustList

    const mergedParams = {
      limit: 50,
      ...params
    }
    let toggleFunc = toggleHistoryLimitOrderLoading

    if (params.type === FutureOrderListType.STOP)
      toggleFunc = toggleHistoryStopOrderLoading
    if (isStopRequest)
      toggleFunc = toggleHistoryStopLossOrderLoading

    toggleFunc(true)
    const { res, err } = await method(mergedParams)
    toggleFunc(false)

    if (err)
      return

    if (res.data.length < mergedParams.limit) {
      if (params.type === FutureOrderListType.STOP)
        toggleNoMoreHistoryStopData(true)
      else if (params.type === FutureOrderListType.LIMIT)
        toggleNoMoreHistoryLimitData(true)
      else if (isStopRequest)
        toggleNoMoreHistoryStopLossData(true)
    }

    dealOrderData(res.data)
  }

  const positionHttpAction = async (
    params: Partial<GetPositionListParams> = {}
  ) => {
    if (positionLoading || (noMorePositionData && params.from_position_id))
      return

    const mergedParams: GetPositionListParams = {
      limit: 100,
      ...params
    }

    togglePositionLoading(true)
    const { res, err } = await getCurrentPositionList(mergedParams)
    togglePositionLoading(false)

    if (err)
      return

    if (res.data.length < mergedParams.limit)
      toggleNoMorePositionData(true)

    dealPositionData(res.data)
  }

  const getHistoryTradeData = async (
    params: Partial<GetHistoryTradeParams> = {}
  ) => {
    if (historyTradeLoading || (noMoreHistoryTradeData && params.from_trade_id))
      return

    const mergedParams: GetHistoryTradeParams = {
      limit: 50,
      ...params
    }

    toggleHistoryTradeLoading(true)
    const { res, err } = await getHistoryTradeList(mergedParams)
    toggleHistoryTradeLoading(false)

    if (err)
      return

    if (res.data.length < mergedParams.limit)
      toggleNoMoreHistoryTradeData(true)

    res.data.forEach((trade) => {
      if (
        !historyTradeOrders.length
        || (historyTradeOrders.length
        && +trade.time - +historyTradeOrders[0].time > 0)
      ) {
        historyTradeOrders.unshift(trade)
      }
      else {
        const sameTrade = historyTradeOrders.find(
          item => item.tradeId === trade.tradeId
        )

        if (sameTrade)
          Object.assign(sameTrade, trade)
        else historyTradeOrders.push(trade)
      }
    })

    // 去除重复订单
    historyTradeOrders = excludeRepeatArray(
      'tradeId',
      historyTradeOrders,
      'time'
    )
    historyTradeOrders.sort((a, b) => +b.time - +a.time)
  }

  return {
    currentBonusOrders: $$(currentOrders),
    historyBonusOrders: $$(historyOrders),
    currentBonusPositions: $$(currentPositions),
    historyBonusTradeOrders: $$(historyTradeOrders),
    bonusNoticeCount: $$(noticeCount),
    showAllSymbolBonusOrder: $$(showAllSymbolOrder),
    bonusPositionLoading: $$(positionLoading),
    historyLimitBonusOrderLoading: $$(historyLimitOrderLoading),
    historyStopBonusOrderLoading: $$(historyStopOrderLoading),
    historyStopLossBonusOrderLoading: $$(historyStopLossOrderLoading),
    currentLimitBonusOrderLoading: $$(currentLimitOrderLoading),
    currentStopBonusOrderLoading: $$(currentStopOrderLoading),
    currentStopBonusLossOrderLoading: $$(currentStopLossOrderLoading),
    historyBonusTradeLoading: $$(historyTradeLoading),
    noMoreCurrentLimitBonusData: $$(noMoreCurrentLimitData),
    noMoreHistoryLimitBonusData: $$(noMoreHistoryLimitData),
    noMoreCurrentStopBonusData: $$(noMoreCurrentStopData),
    noMoreHistoryStopBonusData: $$(noMoreHistoryStopData),
    noMoreCurrentStopLossBonusData: $$(noMoreCurrentStopLossData),
    noMoreHistoryStopLossBonusData: $$(noMoreHistoryStopLossData),
    noMoreBonusPositionData: $$(noMorePositionData),
    noMoreBonusHistoryTradeData: $$(noMoreHistoryTradeData),

    filterCurrentBonusOrders: filterCurrentOrders,
    filterHistoryBonusOrders: filterHistoryOrders,
    filterCurrentBonusPositions: filterCurrentPositions,
    filterHistoryTradeBonusOrders: filterHistoryTradeOrders,
    filterCurrentStopBonusOrders: filterCurrentStopOrders,
    filterHistoryStopBonusOrders: filterHistoryStopOrders,

    bonusOrderDataHandler: orderDataHandler,
    getBonusHistoryTradeData: getHistoryTradeData,
    getBonusHistoryEntrustData: getHistoryEntrustData,
    bonusOrderHttpAction: orderHttpAction,
    bonusPositionDataHandler: positionDataHandler,
    bonusPositionHttpAction: positionHttpAction,
    resetBonusNoticeCount: resetNoticeCount
  }
}
