import type { Nullable } from './utils'
import type { Bar } from './datafeed'
import type { FuturesSymbol } from '.'
import type { Compare } from '~/types/enums'

export interface DepthDataItem {
  price: string
  total: string
  volume: string
  compareToPre?: Compare
}

export interface DepthData {
  bids: DepthDataItem[]
  asks: DepthDataItem[]
}

export interface TickerInfo {
  close: string
  high: string
  low: string
  open: string
  time: number
  /**
   * 涨跌幅
   */
  grow: string
  /**
   * 交易量
   */
  volume: string
  /**
   * 交易额
   */
  totalVolume: string
  amount?: string
  /**
   * 指数价
   */
  indexPrice: string
  /**
   * 指数价最后一根k线的数据
   */
  indexLastBar: Nullable<Bar>
  /**
   * 标记价格
   */
  markPrice: string
  /**
   * 资金费率结算时间
   */
  feeChangeTime: string
  /**
   * 资金费率
   */
  feeRate: string
  /**
   * 预测资金费率
   */
  settleRate: string
}

export type FuturesSymbolWithTickerInfo = FuturesSymbol & {
  tickerInfo: TickerInfo
}

export namespace Futures {
  export enum ExchangeMode {
    /** U本位合约 */
    USDT,
    /** 赠金交易 */
    BONUS
  }

  export enum BonusStatus {
    /** 未激活 */
    INACTIVE = 1,
    /** 已激活 */
    ACTIVATED,
    /** 已使用 */
    USED,
    /** 已过期 */
    EXPIRED,
    /** 已回收 */
    RECYCLED
  }
  // 激活kyc条件 0:无限制 10:KYC1认证及以上，20:KYC2认证
  export enum BonusKycStatus {
    NO_LIMIT = 0,
    KYC1 = 10,
    KYC2 = 20
  }

  export interface BonusItem {
    accountId: string
    /**
     * 达标交易额，如果为0不展示
     */
    activationFuturesAmount: string
    /**
     * 券需要kyc等级，
     */
    activationKycType: BonusKycStatus
    brokerId: string
    /**
     * 券id
     */
    couponId: string
    /**
     * 券有效期 秒
     */
    couponTimeLimit: string
    /**
     * 累计交易额
     */
    currentFuturesAmount: string
    /**
     * 券过期时间 时间戳
     */
    expirationTime: string
    /**
     * 是否是全部合约,如果是全部合约symbolList为空
     */
    isAll: boolean
    /**
     * 当前kyc
     */
    kycActiveStatus: number
    /**
     * 最大杠杆
     */
    maxLeverage: string
    /**
     * 仓位有效期 秒
     */
    positionTimeLimit: string
    /**
     * 状态
     */
    status: BonusStatus
    /**
     * 币对列表，多个逗号分隔
     */
    symbolIds: string
    /**
     * 币对列表
     */
    symbolList: { symbolId: string, symbolName: string }[]
    /**
     * USDT
     */
    tokenId: string
    /**
     * 金额
     */
    total: string
  }

  export interface AvailableBonus {
    /**
     * 可用券数量
     */
    couponSize: string
    /**
     * 可用券金额和
     */
    couponSumAmount: string
    list: GrantGoldCouponItem[]
  }

  export interface GrantGoldCouponItem {
    /**
     * 券id
     */
    couponId: string
    /**
     * 券有效期
     */
    couponTimeLimit: string
    /**
     * 券过期时间
     */
    expirationTime: string
    /**
     * 是否是全部合约,如果是全部合约symbolList为空
     */
    isAll: boolean
    /**
     * 最大杠杆
     */
    maxLeverage: string
    /**
     * 仓位有效期
     */
    positionTimeLimit: string
    /**
     * 币对列表，多个逗号分隔
     */
    symbolIds: string
    /**
     * 币对列表
     */
    symbolList: { symbolId: string, symbolName: string }[]
    /**
     * USDT
     */
    tokenId: string
    /**
     * 金额
     */
    total: string
  }
}
