import type { IResponse } from '~/api'
import type { Nullable } from '~/types/utils'
import type { IAxios, ServerResponse } from '~/utils/axios'

type RequestErrorType = Error | IAxios<ServerResponse<any>> | null

export function useRequest<T extends (...arg: any) => Promise<IResponse<ServerResponse<any>>>>(
  request: T,
  params: Parameters<T>[0] = {},
  {
    immediate = true
  } = {}
) {
  type ResponseType = Awaited<ReturnType<T>>
  type InnerResponseType = ResponseType extends IResponse<ServerResponse<infer T>> ? T : never

  const [isLoading, toggleLoading] = useToggle(false)
  const data = ref<Awaited<ReturnType<T>>['res']>(null)
  const error = ref<RequestErrorType>(null)
  const list = ref<Nullable<InnerResponseType> >(null)

  async function send(addtionalParams?: Partial<Parameters<T>[0]>) {
    toggleLoading(true)
    const { res, err } = await request({ ...(params ?? {}), ...(addtionalParams ?? {}) })
    toggleLoading(false)

    data.value = res as any
    list.value = res?.data ?? null
    error.value = err

    if (err) {
      return {
        err,
        res: null
      } as const
    }
    else {
      return {
        err: null,
        res: res as Awaited<ReturnType<T>>['res']
      } as const
    }
  }

  immediate && send()

  return makeDestructurable(
    {
      data,
      error,
      list,
      isLoading,
      send
    },
    [send, list, isLoading, data, error] as const
  )
}
