/**
 * 获取头部导航数据
 */
export const GET_BASIC_HEAD_CONFIG = '/api/basic/head_config'
/**
 * 获取语言列表
 */
export const GET_BASIC_LANG = '/api/basic/support_languages'
export const GET_INDEX_CONFIG = '/s_api/basic/index_config' // 获取配置文件
export const GET_SHOW_AGENT_STATUS = '/api/invitation/agent/check' // 是否显示代理商按钮
export const LOGOUT = '/api/user/authorize_cancel' // 退出登录
export const GET_BASE_INFO = '/api/user/get_base_info' // 获取用户基本信息
export const GET_RATE = '/api/quote/v1/rates' // 汇率查询
export const GET_USER_SETTING = '/api/user/get_settings' // 获取用户设置
export const SET_USER_SETTING = '/api/user/set_settings' // 设置用户设置
export const GET_SUB_ACCOUNTS = '/api/account/sub_account/query' // 查询子账户列表
export const GET_CUSTOM_KV = '/s_api/basic/custom_kv' // 获取平台自定义数据
export const COMMON_EMAIL_VALID_DOMAIN = '/api/common/valid_email_domain' // 邮箱校验是否合规(避免临时邮箱刷接口)
/* 盈亏分析模块 */
export const GET_PROFIT_INFO = '/api/profit/loss/info' // 获取盈亏分析信息
export const GET_PROFIT_INFO_NO_LOGIN = '/api/v1/profit/loss/no/login/info' // 未登录获取盈亏分析信息
export const GET_ASSET_DISTRIBUTION = '/api/profit/loss/assert_distribution' // 资产分布
export const GET_START_TIME = '/api/v1/profit/loss/start/time' // 获取开始时间
/* 排行榜 */
export const GET_TOP_RANK = '/api/v1/top/rank' // 查询排行榜以及榜首用户信息
export const GET_FOLLOW_RANK = '/api/top/focus_list' // 关注列表
export const GET_FANS_RANK = '/api/top/fans_list' // 粉丝列表
export const FOCUS_TOP = '/api/top/focus' // 关注
export const CANCEL_FOCUS_TOP = '/api/top/cancel_focus' // 关注
export const GET_USER_ACCOUNT_INFO = '/api/top/info' // 查看用户账户概览
export const GET_USER_OPEN_INFO = '/api/top/user_open' // 用户公开信息
export const SET_USER_OPEN_INFO = '/api/top/set/open' // 设置用户公开信息
export const GET_CURRENT_CONTRACT_ORDERS = '/api/top/current_orders' // 查询用户期货当前持仓
export const EDIT_NICK_PHOTO = '/api/top/user/info' // 修改用户昵称、头像
export const UPLOAD_IMG = '/api/v1/user/verify/public/upload_image' // 上传

// 代理中心
export const GET_INVITATION_LOCK = '/api/invitation/agent/lock/info' // 判断代理商是否是锁定奖励
export const GET_INVITATION_INFO = '/api/invitation/info/get' // 邀请信息
export const GET_INVITATION_AGENT_INFO = '/api/invitation/agent/info/get' // 代理总览信息
export const GET_INVITE_TREND_INFO = '/api/invitation/agent/invite/trend/list' // 邀请趋势信息
export const GET_TRADE_TREND_INFO
  = '/api/invitation/agent/invite/trade/trend/list' // 交易趋势信息
export const GET_AGENT_TEAM_DATA = '/api/invitation/agent/team/data' // 团队数据
export const GET_BASIC_TOKENS = '/api/basic/tokens' // 基础币种列表
export const GET_AGENT_ASSETS_RECORD = '/api/invitation/agent/assets/record' // 充提明细每日(团队)
export const GET_AGENT_ASSETS_SNAPSHOT = '/api/invitation/agent/assets/snapshot' // 资产快照(团队)
export const GET_AGENT_SINGLE_ASSETS_RECORD
  = '/api/invitation/agent/single/assets/record' // 充提明细每日(个人)
export const GET_AGENT_SINGLE_ASSETS_SNAPSHOT
  = '/api/invitation/agent/single/assets/snapshot' // 资产快照(个人)
export const GET_AGENT_ASK_ADDRESS = '/api/invitation/agent/ask/address' // 充值地址查询
export const SET_AGENT_RATE_INFO = '/api/invitation/agent/set/rate/info' // 设置返佣比例--合约
export const SET_AGENT_RATE_INFO_COIN
  = '/api/invitation/agent/set/rate/info/coin' // 设置返佣比例-币币
export const SET_AGETN_SELF_REBATE = '/api/invitation/agent/set/self/rebate' // 修改自返佣开关
export const SET_AGENT_REMARK_INFO = '/api/invitation/agent/set/remark' // 设置备注
export const GET_BLACK_LIST
  = '/api/invitation/agent/invite/agent/blacklist/list' // 黑名单列表
export const DEL_BLACK = '/api/invitation/agent/invite/agent/blacklist/delete' // 删除黑名单
export const ADD_BLACK = '/api/invitation/agent/invite/agent/blacklist/add' // 新增黑名单
export const UPDATE_BLACK
  = '/api/invitation/agent/invite/agent/blacklist/update' // 编辑黑名单
export const GET_INVITE_USER_TRADE_LIST
  = '/api/invitation/agent/invite/user/trade/data/list' // 用户明细
export const EXPORT_INVITE_ORDER
  = '/api/invitation/agent/invite/user/trade/order/export/v2' // 订单明细下载
export const GET_INVITE_TRADE_ORDER_LIST
  = '/api/invitation/agent/invite/user/trade/order/list/v2' // 订单明细列表
export const EXPORT_INVITE_ORDER_V_1
  = '/api/invitation/agent/invite/user/trade/order/export' // 分佣明细下载
export const GET_INVITE_TRADE_ORDER_LIST_V_1
  = '/api/invitation/agent/invite/user/trade/order/list' // 分佣明细列表
export const GET_INVITE_AGENT_COMMISSION_LIST
  = '/api/invitation/agent/invite/agent/record/list' // 佣金记录
export const GET_INVITE_AGENT_COMMISSION_MONTHS = '/api/invitation/agent/invite/agent/record/months' // 佣金记录-日历
export const GET_USERS_POSITION = '/api/invitation/agent/users/position' // 当前持仓列表
export const UPDATE_INVITE_CODE = '/api/invitation/share/updateInviteCode' // 修改邀请码
export const GET_AGENT_PROFIT_LOSS
  = '/api/invitation/agent/invite/user/profit/loss/record' // 代理商盈亏列表
export const GET_AGENT_WITHDRAW_CHECK
  = '/api/asset/withdrawal/check/getAgentStatus' // 获取是否有提币审核权限
export const GET_FUN_POOL = '/api/asset/queryFundPool' // 获取资金池资产
export const GET_APP_TOTAL_ASSET = '/api/asset/queryTotalAssets' // 获取伞下总资产
export const GET_WITHDRAW_CHECK_LIST = '/api/asset/withdrawal/check/list' // 提币待审核列表
export const GET_WITHDARW_CHECK_HISTORY = '/api/asset/withdrawal/check/history' // 审核历史列表
export const EXAMINE_WITHDRAW = '/api/asset/withdrawal/check/withdrawalOrder' // 审核提币
export const GET_INVITE_PROFIT_LOSS_INFO
  = '/api/invitation/agent/invite/user/profit/loss/info' // 代理商盈亏时间信息
export const GET_PROFIT_LOSS_TREND
  = '/api/invitation/agent/invite/user/profit/loss/trend' // 代理商盈亏趋势图
export const GET_AGENT_TEAM_DATA_EXPORT
  = '/api/invitation/agent/team/data/export' // 团队数据导出url
export const GET_AGETN_FEE_LIST = '/api/invitation/agent/fee/list' // 查询代理商手续费贡献列表
export const GET_INVITATION_BOUNUS_RECORD = '/api/v1/invitation/invite_bonus_record' // 查询代理商时间段内总返佣

// 合约争霸
export const GET_SIGN_STATUS = '/api/user/enroll' // 获取报名状态
export const GET_SIGN_NUMBERS = '/api/user/enroll/quantity' // 获取报名数量
export const USER_ENROLL = '/api/user/enroll' // 新增用户报名
export const GET_ACTIVITY_RANK = '/api/user/activity/rank' // 排行榜

// 跟单
export const FOLLOW_CONFIG = '/api/futures/order/follow/config' // 创建/修改跟单配置
export const TAKE_CONFIG = '/api/futures/order/take/config' // 创建/修改带单配置
export const GET_TAKE_CONFIG = '/api/futures/order/take/config' // 查询带单配置
export const GET_FOLLOW_CONFIG = '/api/futures/order/follow/config' // 查询跟单配置
export const OPEN_CLOSE_TAKE = '/api/futures/order/open/take' // 开启/关闭带单
export const OPEN_CLOSE_FOLLOW = '/api/futures/order/open/follow' // 开启/关闭跟单
export const GET_FOLLOW_ORDER_LIST = '/api/futures/order/follow/list' // 跟单列表、跟单人列表
export const GET_TAKE_ORDER_LIST = '/api/futures/order/take/list' // 查询带单人列表

export const GET_TAKE_APPLY_STATUS = '/api/futures/order/verification/take/apply' // 获取是否可申请跟单状态
export const SEND_TAKE_APPLY_REQUEST = '/api/futures/order/take/apply' // 查询带单申请
export const SEND_TAKE_APPLY = '/api/futures/order/initiate/take/apply' // 提交带单申请
export const GET_TAKE_SETTING = '/api/futures/order/take/config' // 获取带单配置
export const SET_USER_TAKE_STATUS = '/api/futures/order/open/take' // 设置用户带单状态
export const SET_USER_TAKE_CONFIG = '/api/futures/order/take/config' // 用户带单设置修改
export const GET_FOLLOW_USER_POSITION
  = '/api/contract/order/follow/order/position' // 跟单当前持仓
export const GET_TAKE_PROFIT_LIST = '/api/futures/order/take/profit/list' // 获取用户带单收益
export const GET_FUTURE_MY_TRADES = '/api/contract/order/follow/order/my_trades' // 查询合约历史成交
export const GET_CHECK_FIRST_FOLLOW = '/api/futures/order/check/first/follow' // 查询是否首次跟单
export const GET_FOLLOW_PIC_CONFIG = '/api/futures/order/follow/icon/config' // 获取跟单图配置
export const GET_FOLLOW_COMMON_CONFIG
  = '/api/v1/futures/order/get/follow/common/config' // 获取跟单通用配置
export const GET_FOLLOW_STOP_PROFIT_LIST
  = '/api/contract/order/follow/order/stop_profit_loss/open_orders' // 跟单人止盈止损列表
export const GET_FOLLOW_ACCOUNT = '/api/account/follow/order/get' // 跟单账户资产
export const GET_FOLLOW_ACCOUNT_V2 = '/api/account/follow/order/get/v2' // 跟单账户资产v2
export const GET_FOLLOW_ORDER_SETTING
  = '/api/contract/order/follow/order/get_order_setting' // 查询跟单合约配置信息
export const SET_FOLLOW_ORDER_SETTING
  = '/api/contract/order/follow/order/set_order_setting' // 修改跟单合约自动追加保证金
export const ADD_FOLLOW_AMOUNT = '/api/futures/order/follow/add/amount' // 增加跟单投入

// 用户资产
export const GET_USER_ASSETS = '/api/asset/get' // 获取用户现货资产
export const GET_USER_CONTRACT_ASSETS = '/api/contract/asset/available' // 合约资产
export const GET_USER_TRANSFER_ASSETS
  = '/api/account/sub_account/transfer/available' // 可划转资产
export const TRANSFER_USER_ASSETS = '/api/account/sub_account/transfer' // 资产划转
export const GET_USER_FUTURE_TRADEABLE = '/api/contract/asset/tradeable' // 合约可交易资产
// 兑换分叉币
export const SWAP_ETH = '/api/account/sub_account/tokenExchange' // 兑换分叉币
export const GET_SWAP_RECORD
  = '/api/account/sub_account/get_token_exchange_record' // 兑换记录
export const GET_ASSET_INFO = '/api/asset/info' // 资产总览信息
export const GET_STAKING_ASSET = '/api/v1/staking/product/assets/list' // 理财信息

// 行情相关http轮询接口
export const GET_QUOTE_BROKER = '/api/quote/v1/broker/tickers' // 行情信息
export const GET_QUOTE_TRADES = '/api/quote/v1/trades' // 历史成交
export const GET_QUOTE_DEPTH = '/api/quote/v1/depth' // 深度图数据
export const GET_QUOTE_KLINE = '/api/quote/v1/klines' // k线数据
export const GET_SERVER_TIME = '/api/quote/v1/time' // 服务器时间
export const GET_QUOTE_INDEX_KLINE = '/api/quote/v1/index/klines' // 指数k线数据
export const GET_EXPERIENCE_DATA = '/api/v1/asset/coupon/experience_coupon_list' // 获取体验金列表
export const GET_DEDUCTION_DATA = '/api/v1/asset/coupon/deduction_coupon_list' // 获取抵扣金列表
export const RECEIVE_EXPERIENCE = '/api/v1/asset/coupon/receive_experience' // 领取体验金
export const GET_FUNDING_RATES = '/api/contract/funding_rates' // 获取资金费率

export const GET_USER_FUTURE_SETTING = '/api/contract/order/get_order_setting' // 用户合约设置查询
export const GET_WELFARE_UNLOGIN_DATA = '/api/v1/reward/common/list' // 福利中心列表未登录查询
export const GET_LEVERAGE = '/api/contract/order/query_leverage_merge' // 获取杠杆倍数

// 合约计算器
export const GET_CALCULATOR_PROFIT_INFO = '/api/contract/calculator/profit_info' // 合约计算器 收益率
export const GET_CALCULATOR_LIQUIDATION_PRICE
  = '/api/contract/calculator/liquidation_price' // 合约计算器 强平价

export const GET_FUTURE_CLOSE_PROMPT_POSITION
  = '/api/futures/order/close_promptly_position' // 一键平仓
export const MODIFY_MARGIN = '/api/contract/asset/modify_margin' // 增加/减少保证金
export const MODIFY_FOLLOW_MARGIN = '/api/contract/asset/follow/modify_margin' // 增加减少跟单保证金

export const GET_ORDER_SETTING = '/api/contract/order/get_order_setting' // 读取 永续合约下单配置项，费率，风险限额，杠杠
export const SET_ORDER_SETTING = '/api/contract/order/set_order_setting' // 设置合约下单配置项
export const UPDATE_LEVER = '/api/contract/order/update_leverage_merge' // 修改杠杆
export const CANCEL_STOP_PROFIT_LOSS
  = '/api/contract/order/position/stop_profit_loss/cancel' // 取消止盈止损
export const SET_STOP_PROFIT_LOSS
  = '/api/contract/order/position/stop_profit_loss/set' // 设置止盈止损
export const ORDER_CREATE = '/api/contract/order/createV2' // 下单
export const FOLLOW_ORDER_CREATE = '/api/futures/order/follow/order/createV2' // 跟单-平仓下单

/**
 * 用户订单相关
 */
export const HISTORY_STOP_LOSS_ENTRUST
  = '/api/contract/order/stop_profit_loss/trade_orders' // 历史止盈止损委托
export const CURRENT_STOP_LOSS_ENTRUST
  = '/api/contract/order/stop_profit_loss/open_orders' // 当前止盈止损委托
export const CURRENT_POSITION_LIST = '/api/contract/order/position' // 当前持仓列表
export const CURRENT_ENTRUST_LIST = '/api/contract/order/open_orders' // 当前委托列表
export const HISTORY_ENTRUST_LIST = '/api/contract/order/trade_orders' // 历史委托列表
export const HISTORY_TRADE_LIST = '/api/contract/order/my_trades' // 历史成交列表
export const CANCEL_ENTRUST = '/api/contract/order/cancel' // 撤销委托
export const ENTRUST_MATCH_INFO = '/api/contract/order/match_info' // 委托成交详情
export const GET_STOP_LOSS_INFO
  = '/api/contract/order/position/stop_profit_loss/get' // 获取止盈止损信息
export const GET_ORDER_INFO = '/api/contract/order/get' // 获取订单信息

// -- 登录注册
export const GET_EMAIL_AUTO_SUFFIX = '/api/user/mailbox_suffix' // 邮箱自动补全列表
export const GET_BASIC_COUNTRIES = '/s_api/basic/countries' // 国家列表
export const REGIST_GEETEST = '/api/v1/basic/geev3/register' // 注册极验
export const EMAIL_AUTHORIZE = '/api/user/email_authorize' // 邮箱登录1
export const USER_AUTHORIZE = '/api/user/authorize' // 手机或邮箱登录，step1
export const QUICK_AUTHORIZE = '/api/user/quick_authorize' // 快捷登录
export const SEND_COMMON_SMS_VERIFY_CODE = '/api/common/send_sms_verify_code' // 未登录状态 手机验证码
export const SEND_COMMON_EMAIL_VERIFY_CODE
  = '/api/common/send_email_verify_code' // 未登录状态 邮箱验证码
export const SEND_EMAIL_AUTH_VERIFY_CODE
  = '/api/user/send_email_verify_code/authorize_advance' // 登录时，二次验证邮箱验证码
export const SEND_MOBILE_AUTH_VERIFY_CODE
  = '/api/user/send_sms_verify_code/authorize_advance' // 登录时，二次验证手机验证码
export const LOGIN_STEP2 = '/api/user/authorize_advance' // 账号登录第二步
export const QUICK_AUTHORIZE_ADVANCE = '/api/user/quick_authorize_advance' // 快捷登录二次验证
export const SEND_EMAIL_VERIFY_CODE_LOGINED = '/api/user/send_email_verify_code' // 登录后发送邮箱验证码
export const SEND_MOBILE_VERIFY_CODE_LOGINED = '/api/user/send_sms_verify_code' // 登录后发送手机验证码

// 获取扫码登录二维码
export const GET_LOGIN_QRCODE = '/api/user/qr/code'
// 获取二维码状态
export const GET_LOGIN_QRCODE_STATUS = '/api/user/get/scan/login/qr/code/result'

// 邮箱注册
export const EMAIL_REGISTER = '/api/user/email_sign_up'
// 手机注册
export const MOBILE_REGISTER = '/api/user/mobile_sign_up'
// 邮箱找回密码
export const EMAIL_FIND_PWD_CHECK = '/api/user/email_find_pwd_check1'
// 手机找回密码
export const MOBILE_FIND_PWD_CHECK = '/api/user/mobile_find_pwd_check1'
// 找回密码二次验证码(邮箱或手机)
export const FIND_PWD = '/api/user/send_verify_code/find_pwd'
// 找回密码step2-2FA验证
export const FIND_PWD_CHECK2 = '/api/user/find_pwd_check2'
// 重置密码
export const RESET_PASSWORD = '/api/user/find_pwd'

// 设置密码的验证码
export const SET_PASS_SEND_VERIFY_CODE
  = '/api/user/send_verify_code/set_password'

/**
 * 一键反手
 */
export const QUICK_BACKHAND = '/api/contract/order/backhand'
/**
 * 消息列表
 */
export const GET_NOTICE_LIST = '/api/common/getPushMessages'

/**
 * 消息详情
 */
export const GET_NOTICE_DETAIL = '/api/common/getPushMessageDetail'

/**
 * 消息列表
 */
export const READ_ALL = '/api/common/readMsgAll'

/**
 * 消息分类已读状态查询
 */
export const GET_NOTICE_TYPE_READ_STATUS = '/api/common/getPushMessageTitleType'

// -----------------------充币提币 资产记录
export const GET_DEPOSIT_ADDRESS = '/api/asset/deposit/address' // 获取充币地址
export const GET_DEPOSIT_ORDER_LIST = '/api/asset/deposit/order_list' // 充币记录
export const GET_WITHDRAW_ORDER_LIST = '/api/asset/withdrawal/order_list' // 提币记录
export const CANCEL_WITHDRAW_ORDER = '/api/asset/withdrawal/order/cancel' // 取消提币
export const GET_CONTRACT_BALANCE_FLOW = '/api/contract/asset/balance_flow' // 合约资产记录
export const GET_COUPON_LIST = '/api/v1/asset/coupon/balance_flow' // 体验金/抵扣金记录
export const GET_FINANCE_RECORD_LIST = 'api/financing/record/list' // 理财交易记录
export const GET_OTC_BALANCE_FLOW = '/api/otc/balance_flow' // 法币交易记录
export const GET_OTHER_ORDER_LIST = '/api/asset/balance_flow' // 其他资产历史记录
export const ADD_ADDRESS = '/api/asset/withdrawal/address/add' // 添加地址
export const DEL_ADDRESS = '/api/asset/withdrawal/address/delete' // 删除地址
export const GET_WITHDRAW_ADDRESS_LIST = '/api/asset/withdrawal/address_list' // 获取地址列表
export const GET_WITHDRAW_QUOTA_INFO = '/api/asset/withdrawal/quota_info' // 获取提币相关得币种信息
export const ADDRESS_CHECK = '/api/asset/withdrawal/address/check/v3' // 提币地址检查结果
export const WITHDRAW_CREATE = '/api/asset/withdrawal/create' // 提币
export const SEND_WITHDRAW_VERIFY_CODE = '/api/asset/withdrawal/verify_code' // 提币 brefore step2 重新发送验证码
export const WITHDRAW_VERIFY = '/api/asset/withdrawal/verify' // 提币审核step2
export const GET_ENS_ADDRESS = '/api/v1/analysis/get/address' // 解析ens地址
export const GET_INNER_DEPOSIT_ADDRESS = '/api/v1/asset/inner/deposit/address' // 内部转账获取地址
/**
 * 查询OTC T+1 限额数量
 */
export const GET_OTC_LIMIT_TOTAL = '/api/v1/otc/order/limit/total'

/**
 * 一键撤单
 */
export const CANCEL_ALL_DELEGATE = '/api/contract/order/cancel_all'

/**
 * 添加自选
 */
export const ADD_SPOT_FAVORITE = '/api/user/favorite/create'
/**
 * 删除自选
 */
export const CANCEL_SPOT_FAVORITE = '/api/user/favorite/cancel'

/**
 * 现货普通订单下单
 */
export const CREATE_SPOT_ORDER = '/api/order/create'
/**
 * 现货计划委托
 */
export const CREATE_SPOT_PLAN_ORDER = '/api/order/plan_spot/create'

/**
 * 现货计划委托历史委托列表
 */
export const GET_SPOT_PLAN_HISTORY_LIST = '/api/order/plan_spot/trade_orders'

/**
 *  现货计划委托当前委托列表
 */
export const GET_SPOT_PLAN_ORDER_LIST = '/api/order/plan_spot/open_orders'

/**
 * 计划委托订单明细
 */
export const GET_SPOT_PLAN_ORDER_DETAIL = '/api/order/plan_spot/get'

/**
 * 撤单
 */
export const CANCEL_SPOT_ORDER = '/api/order/cancel'

/**
 * 撤销计划委托
 */
export const CANCEL_SPOT_PLAN_ORDER = '/api/order/plan_spot/cancel'
/**
 *  现货当前委托列表
 */
export const GET_SPOT_ORDER_LIST = '/api/order/open_orders'

/**
 * 现货历史委托列表
 */
export const GET_SPOT_HISTORY_ORDER_LIST = '/api/order/trade_orders'

/**
 * 现货订单明细
 */
export const GET_SPOT_ORDER_DETAIL = '/api/order/match_info'

/**
 * 现货历史成交
 */
export const GET_SPOT_HISTORY_TRADE_LIST = '/api/order/my_trades'

// -------------- 世界杯
export const GET_WORLD_CUP_BG = '/s_api/basic/custom_kv' // 世界杯头部背景图
export const GET_WORLD_CUP_PLAY_DATE
  = '/operational/activity/api/world/cup/playDate' // 所有比赛日期
export const GET_WORLD_CUP_USER_INFO
  = '/operational/activity/api/world/cup/userInfo' // 世界杯用户信息
export const GET_WORLD_CUP_USER_PLAY
  = '/operational/activity/api/world/cup/userPlay' // 查询某日世界杯比赛信息
export const WORLD_CUP_GUESS = '/operational/activity/api/world/cup/guess' // 参与世界杯竞猜
export const GET_WORLD_CUP_GUESS_HISTORY
  = '/operational/activity/api/world/cup/userGuessHistory' // 用户竞猜记录
export const WORLD_CUP_LUCK_DRAW
  = '/operational/activity/api/world/cup/luckDraw' // 抽奖
export const WORLD_CUP_BUY_GUESS
  = '/operational/activity/api/world/cup/buyGuess' // 购买竞猜
export const GET_WORLD_CUP_PRIZE_LIST
  = '/operational/activity/api/world/cup/prizeList' // 查看转盘奖品
export const GET_WORLD_CUP_USER_PRIZE
  = '/operational/activity/api/world/cup/userPrize' // 我的奖品
export const GET_WORLD_CUP_PRIZE_SCROLL
  = '/operational/activity/api/world/cup/userPrizeList' // 滚动中将信息
export const GET_WORLD_CUP_CONFIG
  = '/operational/activity/api/world/cup/activityConfig' // 活动配置

/**
 * 多币对k线
 */
export const GET_MULTIPLE_KLINE = '/api/quote/v1/multi/kline'
/**
 * 自选列表
 */
export const GET_FAVORITE_LIST = '/api/user/favorite/list'
/**
 * 取消所有现货委托
 */
export const CANCEL_ALL_SPOT_ORDER = '/api/order/batch_cancel'
/**
 * 取消所有现货计划委托
 */
export const CANCEL_ALL_SPOT_PLAN_ORDER = '/api/order/plan_spot/batch_cancel'
/**
 * 查询福利中心奖励
 */
export const GET_ALL_AWARD = '/api/v1/reward/income/total'

/**
 * 查询弹窗广告
 */
export const GET_POPUP_AD = '/api/basic/popupBanners_v1'

// ------------------------ 用户中心 ------------------------
/**
 * 查询kyc级别信息
 */
export const GET_USER_KYC_LEVEL_INFO = '/api/user/kyclevel'
/**
 * 登录记录
 */
export const GET_LOGIN_HISTORY = '/api/user/authorize_log'
/**
 * 操作记录
 */
export const GET_ACTION_HISTORY = '/api/user/action_log'

/**
 * 查询持仓模式
 */
export const GET_POSITION_MODE = '/api/contract/order/get_position_mode'
/**
 * 设置持仓模式
 */
export const SET_POSITION_MODE = '/api/contract/order/set_position_mode'

/**
 * 绑定邮箱
 */
export const BIND_EMAIL = '/api/user/bind_email'
/**
 * 此接口用于用户**在登陆状态**并且系统已知用户的手机号或者邮箱的情况下请求发送验证码
 */
export const SEND_VERIFY_CODE_NO_ACCOUNT = '/api/user/send_verify_code'
/**
 * 发送验证码
 */
export const SEND_COMMON_VERIFY_CODE = '/api/common/send_verify_code'
/**
 * 更换邮箱
 */
export const CHANGE_BIND_EMAIL = '/api/user/alter_email'
/**
 * 绑定手机
 */
export const BIND_MOBILE = '/api/user/bind_mobile'
/**
 * 换绑手机
 */
export const CHANGE_BIND_MOBILE = '/api/user/alter_mobile'
/**
 * 获取GA数据
 */
export const GET_GA_INFO = '/api/user/before_bind_ga'
/**
 * 绑定GA
 */
export const BIND_GA = '/api/user/bind_ga'
/**
 * 获取换绑GA信息
 */
export const GET_ALTER_GA_INFO = '/api/user/before_alter_ga'
/**
 * 换绑GA
 */
export const CHANGE_BIND_GA = '/api/user/alter_ga'
/**
 * 登录后重置登录密码
 */
export const RESET_LOGIN_PASSWORD = '/api/user/update_pwd'
/**
 * 设置资金密码
 */
export const SET_FUND_PASSWORD = '/api/user/trade_pwd/set'
/**
 * 设置防钓鱼码
 */
export const SET_FISH_CODE = '/api/user/edit_anti_phishing_code'
/**
 * 获取实名认证信息
 */
export const GET_VERIFY_INFO = '/api/user/verify_info'
/**
 * kyc1认证
 */
// export const KYC_1_VERIFY = '/api/user/kyc/basicverify'
// export const KYC_1_VERIFY = '/api/v1/user/kyc/automatic/verify'
/**
 * kyc2 认证
 */
export const KYC_2_VERIFY = '/api/user/kyc/photoverify'
/**
 * kyc-新基础认证接口
 */
export const KYC_1_VERIFY = '/api/user/kyc/verify/v2'
/**
 * 消息中心-获取站内信消息开关设置
 */
export const GET_USER_PUSH_SETTING = '/api/common/get/user/push/switches'
/**
 * 消息中心-设置站内信消息开关设置
 */
export const SET_USER_PUSH_SETTING = '/api/common/edit/pc/user/push/switch'

/**
 * 币种信息
 */
export const GET_TOKEN_INFO = '/api/basic/token'

/**
 * 分批止盈止损
 */
export const SET_STOP_PROFIT_LOSS_WITH_QUANTITY
  = '/api/contract/order/position/stop_profit_loss/quantity/set'
/**
 * 修改简介
 */
export const EDIT_INTRODUCE = '/api/top/user/describe'
/**
 * 查询带单人基础信息
 */
export const GET_TAKE_BASE_INFO = '/api/contract/order/take/common/config'

/**
 * 查询带单人交易数据
 */
export const GET_TAKE_TRADE_INFO = '/api/contract/order/take/trade/data'

/**
 * 查询带单人主页设置
 */
export const GET_TAKE_HOME_SETTING = '/api/v1/futures/order/take/setting'

/**
 * 设置带单人主页设置
 */
export const SET_TAKE_HOME_SETTING = '/api/v1/futures/order/take/set/setting'

/**
 * 查询历史带单
 */
export const GET_TAKE_HISTORY = '/api/contract/order/take/trade/orders'
/**
 * 查询当前带单
 */
export const GET_TAKE_CURRENT = '/api/contract/order/take/current/orders'
/**
 * 文案配置接口-邀请页面
 */
export const GET_COMMON_LANG_CONFIG = '/api/common/language/list'
/**
 * 邀请分享信息
 */
export const GET_INVITE_SHARE_TEMPLATE_INFO
  = '/api/invitation/share/template_info'
/**
 * 返佣记录
 */
export const GET_INVITATION_BONUS_LIST = '/api/invitation/bonus/list'
/**
 * 邀请明细
 */
export const GET_INVITATION_RELATION_LIST = '/api/invitation/relation/list'
/**
 * 获取分享海报页面数据
 */
export const GET_SHARE_POSTER_DATA = '/api/futures/order/recommend/share/info'
/**
 * 访问带单人页面时，记录访问信息
 */
export const VISIT_USER_TAKE = '/api/futures/order/recommend/follow/add'
/**
 * 查询系统维护通知
 */
export const GET_GLOBAL_NOTICE = '/api/basic/barNotification_v1'
// ---------------- 跟单分享 跟单推荐 ----------------
/**
 * 总览数据
 */
export const GET_FOLLOW_RECOMMEND_OVERVIEW
  = '/api/futures/order/recommend/total/info'
/**
 * 奖励明细
 */
export const GET_FOLLOW_RECOMMEND_REWARD_LIST
  = '/api/futures/order/recommend/reward/list'
/**
 * 推荐明细
 */
export const GET_FOLLOW_RECOMMEND_RECORD_LIST
  = '/api/futures/order/recommend/record/list'

/**
 * 查询是否是官方渠道
 */
export const GET_OFFICIAL_CHANNEL = '/api/channel_config/query'
/**
 * 官方渠道logo
 */
export const GET_OFFICIAL_CHANNEL_LOGO = '/api/channel_config/logo'

/**
 * 转盘活动--------------------------------------
 */
/**
 * 查询转盘奖品列表
 */
export const GET_TURNTABLE_PRIZE_LIST = '/api/v1/futures/order/turntable/prize/list'
/**
 * 查询转盘轮播中奖记录
 */
export const GET_TURNTABLE_REWORD_LIST = '/api/v1/futures/order/turntable/reword/list'
/**
 * 查询转盘抽奖基础信息
 */
export const GET_TURNTABLE_ACTIVITY_INFO = '/api/v1/futures/order/turntable/activity/info'
/**
 * 查询我的中奖记录
 */
export const GET_TURNTABLE_USER_REWORD_LIST = '/api/v1/futures/order/turntable/user/reword/list'
/**
 * 查询转盘活动用户邀请记录
 */
export const GET_TURNTABLE_INVITE_LIST = '/api/v1/futures/order/turntable/invite/list'
/**
 * 点击抽奖
 */
export const TURNTABLE_DRAW = '/api/v1/futures/order/turntable/luck/draw'

/**
 * 开通合约
 */
export const OPEN_FUTURE = '/api/user/open/futures'

/**
 * 价格提醒列表
 */
export const GET_PRICE_ALARM_LIST = '/api/price/reminder/list'
/**
 * 添加价格提醒
 */
export const ADD_PRICE_ALARM = '/api/price/reminder/add'
/**
 * 删除价格提醒
 */
export const DELETE_PRICE_ALARM = '/api/price/reminder/remove'
/**
 * 查询合仓分仓
 */
export const GET_POSITION_MERGE_MODE = '/api/contract/order/get_position_merge_mode'
/**
 * 设置合仓分仓
 */
export const SET_POSITION_MERGE_MODE = '/api/contract/order/set_position_merge_mode'
/**
 * 查询ip是否是大陆
 */
export const CHECK_IP = '/api/v1/ip/area2'
/**
 * 查询跟单账户可交易资产
 */
export const GET_FOLLOW_TRADEABLE = '/api/futures/asset/follow/tradeable'

/**
 * 查询跟单账户下单设置
 */
export const GET_FOLLOW_ORDER_CREATE_SETTING = '/api/contract/order/follow/order/get_order_setting'

/**
 * 查询策略下单最大杠杆
 */
export const GET_STRATEGY_MAX_LEVERAGE = '/api/tactics/max_leverage'

/**
 * 获取ai填充参数
 */
export const GET_STRATEGY_AI_PARAMS = '/api/tactics/ai'

/**
 * 获取策略订单列表
 */
export const GET_STRATEGY_ORDER_LIST = '/api/tactics/list'

/**
 * 创建策略
 */
export const CREATE_STRATEGY_ORDER = '/api/tactics/create'

/**
 * 修改策略订单状态
 */
export const CHANGE_STRATEGY_ORDER_STATUS = '/api/tactics/update_status'

/**
 * 查询用户价格基准
 */
export const GET_USER_PRICE_BASE = '/api/contract/order/unrealised_pnl/config'

/**
 * 设置用户价格基准
 */
export const EDIT_USER_PRICE_BASE = '/api/contract/order/unrealised_pnl/config/update'

/**
 * 标记价格k线
 */
export const GET_QUOTE_MARK_KLINE = '/api/quote/v1/mark/klines'
/**
 * 获取策略账户资产信息
 */
export const GET_STRATEGY_ASSET_LIST = '/api/contract/asset/tactics/available'

// ---- IEO ----
/**
 * IEO活动列表
 */
export const GET_IEO_PROJECT_LIST = '/api/v1/activity/lock/interest/list'
/**
 * 项目详情
 */
export const GET_IEO_ITEM_DETAIL = '/api/v1/activity/lock/interest/ieo/basic_info'
/**
 * 下单
 */
export const CREATE_IEO_NEW_ORDER = '/api/v1/activity/lock/interest/new_order'
/**
 * 锁仓下单记录
 */
export const GET_IEO_LOCK_ORDER_LIST = '/api/v1/activity/lock/interest/order_list'
/**
 * IEO下单列表
 */
export const GET_IEO_ORDER_LIST = '/api/v1/activity/lock/interest/query_order'
/**
 * 指数配置
 */
export const GET_INDEX_FORMULA = '/api/quote/v1/index/config'
/**
 * 资金费率查询
 */
export const GET_RATES_HISTORY = '/api/contract/history_funding_rates'

/**
 * 支持汇率查询
 */
export const GET_BASIC_UNIT = '/api/basic/support_currency'

/** 合约流水类型 */
export const GET_TURNOVER_TYPE = '/api/contract/asset/balance_flow_type/list'

/**
 * 常规邀请基础信息
 */
export const CUSTOM_INVITE_INFO = '/api/new/invitation/info/get'
/**
 * 常规邀请轮播信息
 */
export const CUSTOM_INVITE_CAROUSEL = '/api/new/invitation/award/carousel'
/**
 * 常规邀请-邀请明细
 */
export const CUSTOM_INVITE_LIST = '/api/new/invitation/invite/list'
/**
 * 常规邀请-收益明细
 */
export const CUSTOM_INVITE_AWARD_LIST = '/api/new/invitation/award/list'
/**
 * 学习中心文章列表
 */
export const GET_GUIDE_ARTICLE_LIST = '/api/v1/learning/center/article/list'
/**
 * 学习中心文章详情
 */
export const GET_GUIDE_ARTICLE_DETAIL = '/api/v1/learning/center/article/detail'
/**
 * 学习中心文章类别
 */
export const GET_GUIDE_ARTICLE_CATEGORY = '/api/v1/learning/center/category/list'
/**
 * 学习中心主页配置
 */
export const GET_GUIDE_HOME_CONFIG = '/api/v1/learning/center/detail'

/**
 * 限时邀请-进行中限时邀请配置
 */
export const GET_TOP_INVITE_ACTIVITY_INFO = '/api/v1/top/invite/activity/info'
/**
 * 限时邀请-邀请用户加入团队
 */
export const JOIN_TOP_INVITE_TEAM = '/api/v1/top/join/invite/team'
/**
 * 限时邀请-查询显示邀请团队信息
 */
export const GET_TOP_INVITE_ACTIVITY_TEAM_INFO = '/api/v1/top/invite/activity/user/info'
/**
 * 限时邀请-查询轮播数据
 */
export const GET_TOP_INVITE_AWARD_CAROUSEL = '/api/v1/top/invite/award/carousel'
/**
 * 学习中心文章预览接口
 */
export const GET_GUIDE_ARTICLE_PREVIEW = '/api/v1/learning/center/article/snapshot/detail'
/**
 * 用户登录后获取福利中心数据
 */
export const GET_WELFARE_DATA = '/api/v1/reward/list'
/**
 * 剩蛋活动邀请列表
 */
export const GET_CHRISTMAS_INVITE_LIST = '/api/activity/christmas/invite/user/list'
/**
 * 剩蛋活动抽奖
 */
export const CHRISTMAS_LOTTERY = '/api/activity/christmas/lottery'
/**
 * 圣诞活动用户信息
 */
export const GET_CHRISTMAS_USER_INFO = '/api/activity/christmas/user/info'
/**
 * 获取热门币种配置信息
 */
export const GET_HOT_SYMBOL = '/api/basic/hot_tokens'
/**
 * 上币申请
 */
export const APPLY_TOKEN = '/api/v1/top/token/apply'

/**
 * 查询仓位历史
 */
export const GET_POSITION_HISTORY = '/api/contract/order/track/list'
/**
 * 赠金活动信息查询
 */
export const GET_RECHARGE_BONUS_ACTIVITY_INFO = '/api/activity/incentive'
/**
 * 赠金盈亏记录查询
 */
export const GET_RECHARGE_BONUS_TRADING_PROFIT_LOSS = '/api/activity/incentive/trading/list'
/**
 * 赠金领取
 */
export const GET_RECHARGE_BONUS = '/api/activity/incentive/get'

/**
 * 查询币币盈亏汇总数据-盈亏分析
 */
export const GET_COIN_PROFIT_LOSS_SUMMARY = '/api/profit/loss/coin/summary'
/**
 * 查询币币盈亏汇总数据-盈亏分析
 */
export const GET_COIN_PROFIT_LOSS_DAYS_INFO = '/api/profit/loss/coin/list'
/**
 * 查询币币盈亏分析开始时间-盈亏分析
 */
export const GET_COIN_PROFIT_LOSS_START_TIME = '/api/profit/loss/coin/start/time'
/** 获取现货持仓成本信息 */
export const GET_EXCHANGE_PROFIT_INFO = '/api/profit/loss/coin/asset/get'
/** 赠金交易杠杆查询 */
export const GET_BONUS_EXCHANGE_LEVERAGE = '/api/contract/order/grant/gold/coupon/query_leverage_merge'
/** 新赠金-修改杠杆 */
export const EDIT_BONUS_EXCHANGE_LEVERAGE = '/api/contract/order/grant/gold/coupon/update_leverage_merge'
/** 新赠金-可用券列表(交易页面用) */
export const GET_AVAILABLE_BONUS = '/api/contract/order/grant/gold/coupon/available/list'
/** 新增金-下单 */
export const CREATE_BONUS_ORDER = '/api/contract/order/grant/gold/coupon/order/createV2'
/** 新赠金-可交易资产 */
export const GET_BONUS_TRADEABLE = '/api/futures/asset/grant/gold/coupon/tradeable'
/** 新赠金-仓位列表 */
export const GET_BONUS_POSITION = '/api/contract/order/grant/gold/coupon/position'
/** 新赠金-历史委托列表 */
export const GET_BONUS_HISTORY_ORDER = '/api/contract/order/grant/gold/coupon/trade_orders'
/** 新赠金-历史成交 */
export const GET_BONUS_HISTORY_TRADE = '/api/contract/order/grant/gold/coupon/my_trades'
/** 新赠金-仓位历史记录 */
export const GET_BONUS_POSITION_HISTORY = '/api/contract/order/grant/gold/coupon/track/list'
/** 新赠金-当前委托 */
export const GET_BONUS_CURRENT_ORDER = '/api/contract/order/grant/gold/coupon/open_orders'
/** 新赠金-止盈止损列表 */
export const GET_BONUS_STOP_PROFIT_LOSS = '/api/contract/order/grant/gold/coupon/stop_profit_loss/open_orders'
/** 新赠金-设置止盈止损 */
export const SET_BONUS_STOP_PROFIT_LOSS = '/api/contract/order/grant/gold/coupon/position/stop_profit_loss/set'
/** 新赠金-撤销止盈止损 */
export const CANCEL_BONUS_STOP_PROFIT_LOSS = '/api/contract/order/grant/gold/coupon/position/stop_profit_loss/cancel'
/** 新赠金-撤单 */
export const CANCEL_BONUS_ORDER = '/api/contract/order/grant/gold/coupon/cancel'
/** 新赠金-券列表(分页) */
export const GET_BONUS_COUPON_LIST = '/api/contract/order/grant/gold/coupon/list'
/** 新赠金-成交详情 */
export const GET_BONUS_ENTRUST_MATCH_INFO = '/api/contract/order/grant/gold/coupon/match_info'
/** 新赠金-止盈止损历史委托 */
export const GET_BONUS_HISTORY_STOP_LOSS_ENTRUST = '/api/contract/order/grant/gold/coupon/stop_profit_loss/trade_orders'
/** 新赠金-查询止盈止损订单详情 */
export const GET_BONUS_ORDER_INFO = '/api/contract/order/grant/gold/coupon/get'
