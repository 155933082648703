import type { FuturesSymbol } from '~/types'
import type { FuturesSymbolWithTickerInfo, TickerInfo } from '~/types/futures'
import { DEPTH } from '~/config/enums'
import { FutureCrncyType, PositionMergeMode, PositionMode } from '~/types/enums'
import { getPositionMergeMode, getPositionMode, setPositionMergeMode, setPositionMode } from '~/api'
import { useConfigStore } from '~/store/config'

let symbolList = $ref<FuturesSymbolWithTickerInfo[]>([])
let symbolId = $ref('ETH-SWAP-USDT')
let positionMode = $ref(PositionMode.CROSS)
let canSwitchPositionMode = $ref(false)

async function getFuturePositionMode() {
  const { res, err } = await getPositionMode({ symbol_id: symbolId })

  if (err)
    return

  positionMode = res.data.isCross ? PositionMode.CROSS : PositionMode.ISOLATED
  canSwitchPositionMode = res.data.switchFlag
}

async function setFuturePositionMode(positionMode: PositionMode) {
  const { err } = await setPositionMode({
    symbol_id: symbolId,
    is_cross: positionMode
  })

  if (err)
    return false

  getFuturePositionMode()
  return true
}

let positionMergeMode = $ref(PositionMergeMode.MERGED)
async function getFuturePositionMergeMode() {
  const { res, err } = await getPositionMergeMode({ symbol_id: symbolId })

  if (err)
    return

  if (res.data.symbolId === symbolId)
    positionMergeMode = res.data.mergeMode
}

async function setFuturePositionMergeMode(mergeMode: PositionMergeMode) {
  const { err } = await setPositionMergeMode({
    symbol_id: symbolId,
    merge_mode: mergeMode
  })

  if (err)
    return false

  getFuturePositionMergeMode()
  return true
}

function setSymbolId(id: string) {
  symbolId = id
}
const symbolMap = $computed(() => {
  return Object.fromEntries(symbolList.map(item => [item.symbolId, item]))
})
const visibleSymbolList = $computed(() => {
  return symbolList.filter(item => item.showStatus)
})
const usdtSymbolList = $computed(() => {
  return visibleSymbolList.filter(item => item.firstLevelUnderlyingId === 'LIVE_USDT')
})
const visibleSymbolMap = $computed(() => {
  return Object.fromEntries(
    visibleSymbolList.map(item => [item.symbolId, item])
  )
})
const visibleSymbolMapByIndexToken = $computed(() => {
  return Object.fromEntries(
    visibleSymbolList.map(item => [item.baseTokenFutures.indexToken, item])
  )
})

const visibleSymbolListByLabels = computed<Record<string, FuturesSymbol[]>>(() => {
  const configStore = useConfigStore()
  const { customLabelsGetter } = configStore

  return Object.fromEntries(
    [['ALL', usdtSymbolList]]
      .concat(
        customLabelsGetter
          .map((label) => {
            return [
              label.labelId,
              usdtSymbolList
                .filter(symbol => symbol.labelIds
                  .includes(label.labelId)
                )
            ]
          })
          .filter(([_, symbols]) => symbols.length > 0)
      )
  )
})

const currentSymbolInfo = $computed(() => {
  return symbolMap[symbolId] ?? {}
})

const currentQuoteToken = computed(() => {
  return currentSymbolInfo.quoteTokenId
})

const getQuoteTokenBySymbolId = computed(() =>
  (symbolId: string) => {
    return symbolMap[symbolId]?.quoteTokenId
  }
)

const getIndexTokenBySymbolId = computed(() =>
  (symbolId: string) => {
    return symbolMap[symbolId]?.baseTokenFutures.indexToken
  }
)

const getSymbolInfoBySymbolId = computed(() => {
  return (symbolId: string) => symbolMap[symbolId] ?? {}
})
const getSymbolInfoByIndexToken = computed(() => {
  return (indexToken: string) => {
    return symbolList.find(
      item => item.baseTokenFutures.indexToken === indexToken
    )
  }
})
const exchangeId = computed(() => {
  return currentSymbolInfo?.exchangeId ?? ''
})
// 主币精度
const currentBasePrecision = computed(() => {
  if (!currentSymbolInfo.basePrecision)
    return 4

  return DEPTH[currentSymbolInfo.basePrecision]
})
// 计价币精度
const currentQuotePrecision = computed(() => {
  if (!currentSymbolInfo.quotePrecision)
    return 4

  return DEPTH[currentSymbolInfo.quotePrecision]
})

const getQuotePrecisionBySymbolId = computed(() =>
  (symbolId: string) => {
    if (!symbolMap[symbolId]?.quotePrecision)
      return 4

    return DEPTH[symbolMap[symbolId].quotePrecision]
  }
)

// 保证金精度
const currentMarginPrecision = computed(() => {
  if (!currentSymbolInfo.baseTokenFutures)
    return 8

  return DEPTH[currentSymbolInfo.baseTokenFutures.marginPrecision]
})
// 价格精度
const currentPriceDigit = computed(() => {
  if (!currentSymbolInfo.minPricePrecision)
    return 4

  return DEPTH[currentSymbolInfo.minPricePrecision]
})
// 通过币种获取精度
const getPriceDigitBySymbol = computed(() => {
  return (symbolId: string) => {
    const minPricePrecision = symbolMap[symbolId]?.minPricePrecision

    if (!minPricePrecision)
      return 4
    return DEPTH[minPricePrecision]
  }
})
// 通过币种获取保证金精度
const getMarginDigitBySymbol = computed(() => {
  return (symbolId: string) => {
    if (!currentSymbolInfo.baseTokenFutures)
      return 8

    return DEPTH[symbolMap[symbolId]?.baseTokenFutures.marginPrecision]
  }
})
function setSymbolList(list: FuturesSymbol[]) {
  if (!list || !list.length)
    return

  symbolList = list.map((item) => {
    return {
      tickerInfo: {
        high: '--',
        low: '--',
        close: '--',
        volume: '--',
        grow: '--',
        totalVolume: '--',
        open: '--',
        amount: '--',
        indexPrice: '--',
        feeChangeTime: '--',
        settleRate: '--',
        feeRate: '--',
        markPrice: '--',
        time: 0,
        indexLastBar: null
      },
      ...item
    }
  })
}

function setTickerInfo(tickers: Record<string, Partial<TickerInfo>>) {
  Object.entries(tickers).forEach(([symbolId, tickerInfo]) => {
    if (!symbolMap[symbolId])
      return

    Object.assign(symbolMap[symbolId].tickerInfo, tickerInfo)
  })
}
let crncyType = $ref(FutureCrncyType.TOKEN)
function setCrncyType(type: FutureCrncyType) {
  crncyType = type
}

const currentQuantityPrecision = computed(() => {
  if (!currentSymbolInfo.baseTokenFutures)
    return 4

  const { contractMultiplier } = currentSymbolInfo.baseTokenFutures

  if (crncyType === FutureCrncyType.COUNT)
    return currentBasePrecision.value
  else if (crncyType === FutureCrncyType.TOKEN)
    return +contractMultiplier >= 1 ? 0 : DEPTH[contractMultiplier]
  else if (crncyType === FutureCrncyType.USDT)
    return currentQuotePrecision.value

  return currentQuotePrecision.value
})
const getQuantityPrecisionBySymbol = computed(() => {
  return (symbol?: string, type: FutureCrncyType = crncyType) => {
    if (!symbol)
      symbol = symbolId

    const symbolInfo = symbolMap[symbol]

    if (!symbolInfo.baseTokenFutures)
      return 4

    const { contractMultiplier } = symbolInfo.baseTokenFutures

    if (type === FutureCrncyType.COUNT)
      return DEPTH[symbolInfo.basePrecision]
    else if (type === FutureCrncyType.TOKEN)
      return +contractMultiplier >= 1 ? 1 : DEPTH[contractMultiplier]
    else if (type === FutureCrncyType.USDT)
      return DEPTH[symbolInfo.quotePrecision]

    return DEPTH[symbolInfo.quotePrecision]
  }
})
let dumpScale = $ref(0)
function setDumpScale(digit: number) {
  dumpScale = digit
}

const currentDigitMerge = $computed(() => {
  return currentSymbolInfo?.digitMerge.split(',')
})

const currentDigitLabel = $computed(() => {
  return currentDigitMerge.find((item) => {
    return DEPTH[item] === dumpScale
  })
})

export function useFutureSymbol() {
  const { t } = useI18n()

  const currentSymbolDisplayName = $computed(() => {
    return `${currentSymbolInfo.secondLevelUnderlyingId}/${
      currentSymbolInfo.quoteTokenId
    } ${t('永续')}`
  })

  const getSymbolDisplayName = computed(() => {
    return (symbolId: string) => {
      const symbolInfo = symbolMap[symbolId]
      return `${symbolInfo.secondLevelUnderlyingId}${
        symbolInfo.quoteTokenId
      } ${t('永续')}`
    }
  })

  const getSymbolIdByDisplayName = computed(() => (displayName: string) => {
    if (displayName.includes('-'))
      return ''

    const symbolId = `${displayName?.split('/')[0]}-SWAP-USDT`
    return symbolId
  })

  return {
    symbolList: $$(symbolList),
    usdtSymbolList: $$(usdtSymbolList),
    symbolMap: $$(symbolMap),
    visibleSymbolList: $$(visibleSymbolList),
    visibleSymbolMap: $$(visibleSymbolMap),
    visibleSymbolMapByIndexToken: $$(visibleSymbolMapByIndexToken),
    visibleSymbolListByLabels,
    symbolId: $$(symbolId),
    exchangeId,
    crncyType: $$(crncyType),
    dumpScale: $$(dumpScale),
    positionMode: $$(positionMode),
    canSwitchPositionMode: $$(canSwitchPositionMode),
    positionMergeMode: $$(positionMergeMode),

    currentSymbolInfo: $$(currentSymbolInfo),
    currentBasePrecision,
    currentMarginPrecision,
    currentPriceDigit,
    currentQuotePrecision,
    currentQuantityPrecision,
    currentQuoteToken,
    getQuoteTokenBySymbolId,
    currentDigitMerge: $$(currentDigitMerge),
    currentDigitLabel: $$(currentDigitLabel),
    currentSymbolDisplayName: $$(currentSymbolDisplayName),

    getQuantityPrecisionBySymbol,
    getSymbolInfoBySymbolId,
    getMarginDigitBySymbol,
    getPriceDigitBySymbol,
    getQuotePrecisionBySymbolId,
    getIndexTokenBySymbolId,
    getSymbolInfoByIndexToken,
    getSymbolDisplayName,
    getSymbolIdByDisplayName,

    setSymbolId,
    getFuturePositionMode,
    setFuturePositionMode,
    getFuturePositionMergeMode,
    setFuturePositionMergeMode,
    setDumpScale,
    setSymbolList,
    setCrncyType,
    setTickerInfo
  }
}
