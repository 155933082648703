import { defineStore } from 'pinia'
import type BigNumber from 'bignumber.js'
import type * as TradingView from 'charting_library.d.ts'
import { useFutureTrading } from '../composables/futures/useFutureTrading'
import { useFutureOrderData } from '../composables/futures/useFutureOrder'
import { FUTURE_LAYOUT_KEY, FUTURE_RESOLUTION_KEY } from '../config/const'
import { useFutureBrokerData } from '~/composables/futures/useFutureBrokerData'
import { useFutureDepthData } from '~/composables/futures/useFutureDepthData'
import { useFutureIndexData } from '~/composables/futures/useFutureIndexData'
import { useFutureSymbol } from '~/composables/futures/useFutureSymbol'
import { useFutureTradeData } from '~/composables/futures/useFutureTradeData'
import { useFutureOrderSetting } from '~/composables/futures/useFutureOrderSetting'
import { useFutureFundingData } from '~/composables/futures/useFundingRates'
import { RESOLUTION_MAP } from '~/config/enums'
import type { KlineResolution } from '~/types/enums'
import { FutureCrncyType, FuturePriceBase } from '~/types/enums'
import { KlineTypes } from '~/types/kline'
import { coinToCount, getValueFromExchangeUnit } from '~/utils/format'
import type { IGridItem } from '~/types'
import { INITIAL_FUTURE_LAYOUT } from '~/config/const'
import { deepClone } from '~/utils/utils'
import { useFutureFollowOrderSetting } from '~/composables/futures/useFutureFollowOrderSetting'
import { editUserPriceBase, getUserPriceBase } from '~/api'
import { useFutureBonus } from '~/composables/futures/useFutureBonus'
import { useFutureBonusOrderData } from '~/composables/futures/useFutureBonusOrder'
import { Futures } from '~/types/futures'

export const useFutureStore = defineStore(
  'future',
  () => {
    const { t } = useI18n()

    const [isFullscreen, setIsFullscreen] = useToggle(false)
    let chartLayoutMode: TradingView.LayoutType = $ref('s')
    const setChartLayoutMode = (mode: TradingView.LayoutType) => {
      if (mode === chartLayoutMode)
        return

      chartLayoutMode = mode
    }

    const resolution = $(useStorage<Record<string, KlineResolution>>(FUTURE_RESOLUTION_KEY, {}))
    const setResolution = (res: KlineResolution, symbolId: string) => {
      resolution[symbolId] = res
    }
    const getResolutionBySymbolId = $computed(
      () => (symbolId: string) => {
        return resolution[symbolId] ?? '15'
      }
    )
    const resolutionValue = $computed(
      () => (symbolId: string) => {
        return RESOLUTION_MAP[getResolutionBySymbolId(symbolId)]
      }
    )

    const currentResolution = computed(() => {
      return getResolutionBySymbolId(symbolId)
    })
    const currentChartStyle = computed(() => {
      return getChartStyleBySymbolId(symbolId)
    })

    const chartStyle = $ref<Record<string, TradingView.SeriesStyle>>({})
    const getChartStyleBySymbolId = $computed(
      () => (symbolId: string) => {
        return chartStyle[symbolId] ?? KlineTypes.SeriesStyle.Candles
      }
    )
    const setChartStyle = (type: TradingView.SeriesStyle, symbolId: string) => {
      chartStyle[symbolId] = type
    }

    const exchangeMode = ref(Futures.ExchangeMode.USDT)

    function setExchangeMode(mode: Futures.ExchangeMode) {
      if (mode !== exchangeMode.value)
        exchangeMode.value = mode
    }

    const futureSymbol = useFutureSymbol()
    const futureBroker = useFutureBrokerData()
    const futureTrade = useFutureTradeData()
    const futureDepth = useFutureDepthData()
    const futureIndex = useFutureIndexData()
    const futureFunding = useFutureFundingData()
    const futureOrderSetting = useFutureOrderSetting()
    const futureOrderCreate = useFutureTrading()
    const futureOrder = useFutureOrderData(futureSymbol.symbolId)
    const futureFollow = useFutureFollowOrderSetting()
    const futureBonus = useFutureBonus()
    const futureBonusOrder = useFutureBonusOrderData(futureSymbol.symbolId)
    const { crncyType, symbolId } = $(futureSymbol)

    const getUnitLabelByUnitType = computed(
      () =>
        (symbol?: string, type = crncyType) => {
          if (!symbol)
            symbol = symbolId

          if (type === FutureCrncyType.COUNT)
            return t('张')
          if (type === FutureCrncyType.TOKEN)
            return futureSymbol.symbolMap.value[symbol]?.secondLevelUnderlyingId
          if (type === FutureCrncyType.USDT)
            return futureSymbol.symbolMap.value[symbol]?.quoteTokenId
        }
    )

    const getDisplayValue = computed(
      () =>
        (
          value: string | number,
          symbol?: string,
          price?: string,
          roundMode?: BigNumber.RoundingMode,
          type: FutureCrncyType = crncyType
        ) => {
          if (!symbol)
            symbol = symbolId

          const symbolInfo = futureSymbol.symbolMap.value[symbol]

          if (!symbolInfo)
            return '--'

          const multiplier = symbolInfo.baseTokenFutures.contractMultiplier

          if (type === FutureCrncyType.USDT) {
            return getValueFromExchangeUnit(
              value,
              multiplier,
              +symbolInfo.baseTokenFutures.marginPrecision,
              type,
              price || symbolInfo.tickerInfo.close,
              roundMode
            )
          }

          return getValueFromExchangeUnit(
            value,
            multiplier,
            +multiplier,
            type,
            price || symbolInfo.tickerInfo.close,
            roundMode
          )
        }
    )

    const getDisplayValueWithUnitType = computed(
      () => (value: string | number, symbol?: string, price?: string, type: FutureCrncyType = crncyType) => {
        return `${getDisplayValue.value(
          value,
          symbol,
          price,
          undefined,
          type
        )} ${getUnitLabelByUnitType.value(symbol, type)}`
      }
    )

    const getDisplayCountValue = computed(
      () =>
        (
          value: string | number,
          symbol?: string,
          unitType?: FutureCrncyType,
          price?: string | number
        ) => {
          if (!symbol)
            symbol = symbolId

          if (unitType === undefined)
            unitType = crncyType

          const symbolInfo = futureSymbol.symbolMap.value[symbol]

          if (!symbolInfo)
            return '--'

          if (!price)
            price = symbolInfo.tickerInfo.close

          const multiplier = symbolInfo.baseTokenFutures.contractMultiplier

          if (unitType === FutureCrncyType.USDT) {
            return coinToCount(
              value,
              multiplier,
              +symbolInfo.basePrecision,
              unitType,
              price
            )
          }

          return coinToCount(value, multiplier, +multiplier, unitType)
        }
    )

    let layout = $(
      useStorage<IGridItem[]>(FUTURE_LAYOUT_KEY, INITIAL_FUTURE_LAYOUT)
    )
    /**
     * 持仓线
     */
    const positionLineOpenStatus = useStorage<boolean>(
      'future-position-line',
      false
    )
    /**
     * 闪电下单
     */
    const quickCreateOrderOpenStatus = useStorage<boolean>(
      'future-quick-create-order',
      false
    )

    /**
     * 委托线
     */
    const delegateLineOpenStatus = useStorage<boolean>(
      'future-delegate-line',
      false
    )

    const resetLayout = () => {
      layout = deepClone(INITIAL_FUTURE_LAYOUT)
    }

    const [showPriceAlertModal, toggleShowPriceAlertModal] = $(useToggle(false))

    let priceBase = $ref(FuturePriceBase.MARK)

    async function getPriceBase() {
      const { res, err } = await getUserPriceBase()
      if (err)
        return

      priceBase = res.data.result
    }

    async function editPriceBase(priceBase: FuturePriceBase) {
      const { err } = await editUserPriceBase({ type: priceBase })
      if (err)
        return

      getPriceBase()
    }

    return {
      isFullscreen,
      chartLayoutMode: $$(chartLayoutMode as TradingView.LayoutType),
      resolution: $$(resolution),
      resolutionValue: $$(resolutionValue),
      currentResolution,
      currentChartStyle,
      getResolutionBySymbolId: $$(getResolutionBySymbolId),
      chartStyle: $$(chartStyle),
      getChartStyleBySymbolId: $$(getChartStyleBySymbolId),
      positionLineOpenStatus,
      delegateLineOpenStatus,
      quickCreateOrderOpenStatus,
      layout: $$(layout),
      showPriceAlertModal: $$(showPriceAlertModal),
      getDisplayValue,
      getDisplayValueWithUnitType,
      getDisplayCountValue,
      getUnitLabelByUnitType,
      priceBase: $$(priceBase),
      exchangeMode,

      getPriceBase,
      editPriceBase,
      setChartStyle,
      setChartLayoutMode,
      toggleShowPriceAlertModal,
      setResolution,
      setIsFullscreen,
      resetLayout,
      setExchangeMode,

      ...futureOrderSetting,
      ...futureSymbol,
      ...futureTrade,
      ...futureDepth,
      ...futureBroker,
      ...futureIndex,
      ...futureFunding,
      ...futureOrderCreate,
      ...futureOrder,
      ...futureFollow,
      ...futureBonus,
      ...futureBonusOrder
    }
  },
  {
    persist: {
      paths: ['symbolId', 'chartStyle', 'crncyType', 'chartLayoutMode', 'exchangeMode'],
      key: 'FutureStore'
    },
    debounce: {}
  }
)

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useFutureStore, import.meta.hot))
