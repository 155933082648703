export enum AssetType {
  TOTAL_ASSET = 1,
  WALLET_ASSET,
  FUTURE_ASSET,
  SUB_ACCOUNT_ASSET
}

export enum DurationType {
  /**
   * 近7日
   */
  LAST_WEEK = 1,
  /**
   * 近30天
   */
  LAST_MONTH,
  /**
   * 自定义时间
   */
  CUSTOM
}

export enum SubAccountType {
  /**
   * 现货
   */
  WALLET = 1,
  /**
   * 合约
   */
  FUTURE = 3,
  /**
   * 杠杆
   */
  LEVERAGE = 27,
  /**
   * 奖励
   */
  AWARD = 42
}

export enum TakeOrderCheckStatus {
  /**
   * 审核中
   */
  PROCESSING,
  /**
   * 已通过
   */
  PASS,
  /**
   * 已拒绝
   */
  REJECTED,
  /**
   * 未提交
   */
  UNSEND
}

export enum TakeApplyPositionStatus {
  /**
   * 可以申请
   */
  CAN_APPLY,
  /**
   * 有持仓
   */
  HAS_POSITION = 2,
  /**
   * 有委托
   */
  HAS_ENTRUST,
  /**
   * 有计划委托
   */
  HAS_PLAN
}

export enum TakeApplyKYCStatus {
  /**
   * 通过
   */
  PASS,
  /**
   * 未通过
   */
  NOT_PASS
}
export enum TakeApplyFutureStatus {
  /**
   * 通过
   */
  PASS,
  /**
   * 未通过
   */
  NOT_PASS
}

export enum TakeOpenStatus {
  OPEN,
  CLOSE
}

export enum SwapDir {
  MAIN_TO_TARGET,
  TARGET_TO_MAIN
}

export enum CustomKVKeys {
  PLATFORM_DATA = 'cust.platformData',
  EXPERIENCE_SETTINGS = 'cust.experienceSettings',
  WELFARE_SETTINGS = 'cust.welfareCenterSettings',
  IEO_SETTINGS = 'cust.ieoSettings',
  AWARD_SETTINGS = 'cust.rewardCenterActivitySettings',
  KYC_SETTINGS = 'cust.kycSettings'
}

export enum ExperienceStatus {
  /**
   * 未领取
   */
  UN_RECEIVE = 1,
  /**
   * 已领取
   */
  RECEIVED,
  /**
   * 已过期
   */
  EXPIRED,
  /**
   * 已回收
   */
  RECYCLE
}

export enum DeductionStatus {
  /**
   * 可用
   */
  AVAILABLE = 1,
  /**
   * 已用尽
   */
  EXHAUST,
  /**
   * 已过期
   */
  EXPIRED,
  /**
   * 已回收
   */
  RECYCLE
}
/**
 * 活动类型，10.新手任务-首次充值，11.新手任务-现货交易，12.新手任务-合约交易，13.交易任务-合约交易，14.交易任务-跟单交易，15.交易任务-策略交易 16. 限时任务
 */
export enum WelfareActivityType {
  /**
   * 新手任务-首次充值
   */
  FIRST_CHARGE = 10,
  /**
   * 新手任务-现货交易
   */
  TRADING_SPOT = 11,
  /**
   * 新手任务-合约交易
   */
  TRADING_SWAP = 12,
  /**
   * 交易任务-合约交易
   */
  TRADING_SWAP_NORMAL = 13,
  /**
   * 交易任务-跟单交易
   */
  TRADING_SWAP_FOLLOW = 14,
  /**
   * 交易任务-策略交易
   */
  TRADING_SWAP_STRATEGY = 15,
  /**
   * 限时任务
   */
  TRADING_SWAP_TIME = 16
}

/**
 * 奖励类型，1.体验金，2.抵扣金
 */
export enum WelfareRewardType {
  /**
   * 体验金
   */
  EXPERIENCE = 1,
  /**
   * 抵扣金
   */
  DEDUCTION
}

/**
 * 福利中心任务状态1.未完成，2.已完成，3.已失效
 */
export enum WelfareStatus {
  /**
   * 未完成
   */
  UNFINISHED = 1,
  /**
   * 已完成
   */
  FINISHED,
  /**
   * 已失效
   */
  INVALID
}

export type LangMap =
  | 'zh-hk'
  | 'zh-cn'
  | 'en-us'
  | 'ko-kr'
  | 'ja-jp'
  | 'th-th'
  | 'tr-tr'
  | 'ru-ru'
  | 'vi-vn'

export type KlineResolution =
  | 'D'
  | '1'
  | '3'
  | '5'
  | '15'
  | '30'
  | '60'
  | '120'
  | '240'
  | '360'
  | '720'
  | '1D'
  | '1W'
  | '1M'

export type ResolutionValues =
  | '1m'
  | '3m'
  | '5m'
  | '15m'
  | '30m'
  | '1h'
  | '2h'
  | '4h'
  | '6h'
  | '12h'
  | '1d'
  | '1w'
  | '1M'

export enum FutureCrncyType {
  COUNT, // 张
  USDT, // U
  TOKEN // 币
}

export enum PageColorType {
  GREEN_UP = '0', // 绿涨红跌
  RED_UP = '1' // 红涨绿跌
}

export enum FutureOrderSide {
  /**
   * 开仓
   */
  OPEN,
  /**
   * 平仓
   */
  CLOSE
}

export enum FutureOrderPriceType {
  /**
   * 限价
   */
  LIMIT = 'INPUT',
  /**
   * 市价
   */
  MARKET = 'MARKET_PRICE'
}

export enum FutureOrderDelegateType {
  /**
   * 普通委托
   */
  NORMAL,
  /**
   * 计划委托
   */
  PLAN,
  /**
   * 跟踪委托
   */
  TRACK
}

export enum FutureOrderType {
  /**
   * 买入开多
   */
  BUY_OPEN = 'BUY_OPEN',
  /**
   *  卖出开空
   */
  SELL_OPEN = 'SELL_OPEN',
  /**
   * 卖出平多
   */
  SELL_CLOSE = 'SELL_CLOSE',
  /**
   * 买入平空
   */
  BUY_CLOSE = 'BUY_CLOSE'
}

/**
 * 代理商合约订单方向
 */
export enum AgentFutureOrderType {
  /**
   * 买入开多
   */
  BUY_OPEN = 1,
  /**
   *  卖出开空
   */
  SELL_OPEN,
  /**
   * 买入平空
   */
  BUY_CLOSE,
  /**
   * 卖出平多
   */
  SELL_CLOSE
}

export enum FuturePositionType {
  /**
   * 逐仓
   */
  ISOLATED,
  /**
   * 全仓
   */
  CROSS
}

export enum FuturePositionSide {
  /**
   * 空
   */
  SHORT = '0',
  /**
   * 多
   */
  LONG = '1'
}

export enum FutureTimeInForce {
  IOC = 'IOC',
  GTC = 'GTC',
  FOK = 'FOK',
  MAKER = 'MAKER'
}

// ----- 合约
// 开仓 平仓
export enum OrderChoose {
  OPEN = 0,
  CLOSE
}
// 买入 卖出
export enum OrderSide {
  BUY = 'BUY',
  SELL = 'SELL'
}
/**
 * 代理商订单 买入 卖出
 */
export enum AgentOrderSide {
  BUY,
  SELL
}

// 价格类型
export enum PriceTypes {
  INPUT,
  MARKET_PRICE,
  OPPONENT,
  QUEUE,
  OVER
}

export enum FutureStopConditionType {
  /**
   * 市场价
   */
  MARKET,
  /**
   * 指数价
   */
  INDEX,
  /**
   * 标记价格
   */
  MARK
}

export enum FutureStopStatus {
  CLOSE,
  OPEN
}

export enum FutureOrderStatus {
  /**
   * 新订单
   */
  NEW = 'NEW',
  ORDER_NEW = 'ORDER_NEW',
  ORDER_NOT_ACTIVATION = 'ORDER_NOT_ACTIVATION',
  /**
   * 部分成交
   */
  PARTIALLY_FILLED = 'PARTIALLY_FILLED',
  ORDER_PARTIALLY_FILLED = 'ORDER_PARTIALLY_FILLED',
  /**
   * 完全成交
   */
  FILLED = 'FILLED',
  ORDER_FILLED = 'ORDER_FILLED',
  /**
   * 已撤销
   */
  CANCELED = 'CANCELED',
  ORDER_CANCELED = 'ORDER_CANCELED',
  /**
   * 订单被拒绝
   */
  ORDER_REJECTED = 'ORDER_REJECTED',
  REJECTED = 'REJECTED',
  /**
   * 订单未成交 (跟踪委托)
   */
  ORDER_ACTIVATION = 'ORDER_ACTIVATION'

}

export enum FuturePlanOrderType {
  /**
   * 计划委托
   */
  STOP_COMMON = 'STOP_COMMON',
  /**
   * 空仓止损
   */
  STOP_SHORT_LOSS = 'STOP_SHORT_LOSS',
  /**
   * 多仓止盈
   */
  STOP_LONG_PROFIT = 'STOP_LONG_PROFIT',

  /**
   * 多仓止损
   */
  STOP_LONG_LOSS = 'STOP_LONG_LOSS',
  /**
   * 空仓止盈
   */
  STOP_SHORT_PROFIT = 'STOP_SHORT_PROFIT'
}

export enum FutureOrderListType {
  /**
   * 普通委托
   */
  LIMIT = 'LIMIT',
  /**
   * 计划委托
   */
  STOP = 'STOP',
  /**
   * 止盈止损
   */
  STOP_LOSS = 'STOP_LOSS',
  /**
   * 跟踪委托
   */
  STOP_FOLLOW = 'STOP_FOLLOW'
}

export enum FutureOrderTabsType {
  /**
   * 当前持仓
   */
  POSITION,
  /**
   * 当前委托
   */
  CURRENT_ENTRUST,
  /**
   * 历史委托
   */
  HISTORY_ENTRUST,
  /**
   * 历史成交
   */
  HISTORY_TRADE,
  /**
   * 资金流水
   */
  FUNDING_HISTORY,
  /**
   * 资产
   */
  ASSETS,
  /**
   * 仓位历史
   */
  POSITION_HISTORY
}

export enum FutureLiquidationType {
  /**
   * 正常订单
   */
  NO_LIQ = 'NO_LIQ',
  /**
   * 强平订单
   */
  IOC = 'IOC',
  /**
   * 减仓订单
   */
  ADL = 'ADL'
}

export enum QrCodeStatus {
  /**
   * 授权成功
   */
  AUTH_SUCCESS,
  /**
   * 等待扫码
   */
  WAIT_SCAN,
  /**
   * 已扫码
   */
  SCANNED,
  /**
   * 已过期
   */
  EXPIRED
}

export enum NoticeType {
  /**
   * 全部
   */
  ALL = 1,
  /**
   * 系统通知
   */
  SYSTEM,
  /**
   * 个人通知
   */
  PERSONAL,
  /**
   * OTC
   */
  OTC,
  /**
   * 跟单
   */
  FOLLOW
}

export enum NoticeOpenStatus {
  /** 开启 */
  OPEN,
  /** 关闭 */
  CLOSE
}

export enum NoticeReadStatus {
  /**
   * 未读
   */
  UNREAD,
  /**
   * 已读
   */
  ALREADY_READ
}

export enum SpotOrderType {
  /**
   * 限价
   */
  LIMIT = 'limit',
  /**
   * 市价
   */
  MARKET = 'market',
  /**
   * 计划委托
   */
  PLAN = 'plan'
}

export enum SpotOrderStatus {
  /**
   * 新订单
   */
  NEW = 'NEW',
  ORDER_NEW = 'ORDER_NEW',
  /**
   * 部分成交
   */
  PARTIALLY_FILLED = 'PARTIALLY_FILLED',
  ORDER_PARTIALLY_FILLED = 'ORDER_PARTIALLY_FILLED',
  /**
   * 完全成交
   */
  FILLED = 'FILLED',
  ORDER_FILLED = 'ORDER_FILLED',
  /**
   * 已撤销
   */
  CANCELED = 'CANCELED',
  ORDER_CANCELED = 'ORDER_CANCELED',
  /**
   * 订单被拒绝
   */
  ORDER_REJECTED = 'ORDER_REJECTED',
  REJECTED = 'REJECTED'
}

export enum SpotOrderTabs {
  /**
   * 当前委托
   */
  CURRENT_ENTRUST,
  /**
   * 历史委托
   */
  HISTORY_ENTRUST,
  /**
   * 历史成交
   */
  HISTORY_TRADE,
  /**
   * 资产
   */
  ASSETS
}
export enum SpotOrderTypeTabs {
  /**
   * 普通委托
   */
  NORMAL,
  /**
   * 计划委托
   */
  PLAN
}

export enum GuessResult {
  /**
   * 未出结果
   */
  WAITING = 1,
  /**
   * A胜
   */
  A,
  /**
   * B胜
   */
  B,
  /**
   * 平局
   */
  DRAW
}

export enum MarketTabType {
  /**
   * 自选
   */
  FAVORITE,
  /**
   * 现货
   */
  SPOT,
  /**
   * 合约
   */
  FUTURE
}

export enum CalculatorFeeType {
  /**
   * 有手续费
   */
  FEE = 1,
  /**
   * 无手续费
   */
  NO_FEE
}

export enum PopupFrequency {
  /**
   * 每日首次进入
   */
  DAILY = 1,
  /**
   * 每次进入
   */
  EVERY_TIME,
  /**
   * 仅一次
   */
  ONCE
}

export enum PositionMode {
  /**
   * 逐仓
   */
  ISOLATED = 0,
  /**
   * 全仓
   */
  CROSS = 1
}

/**
 * 已登录收取验证码账户类型
 */
export enum ReceiverType {
  /**
   * 手机
   */
  MOBILE = 1,
  /**
   * 邮箱
   */
  EMAIL,
  /**
   * GA
   */
  GA
}
/**
 * 已登录收取验证码类型
 */
export enum CodeType {
  COMMON = 0,
  REGISTER = 1,
  LOGIN = 2,
  FIND_PASSWORD = 3,
  RESET_PASSWORD = 4,
  BIND_MOBILE = 5,
  BIND_EMAIL = 6,
  BIND_GA = 7,
  ADD_WITHDRAW_ADDRESS = 8,
  DELETE_WITHDRAW_ADDRESS = 9,
  KYC = 10,
  CREATE_API_KEY = 11,
  MODIFY_API_KEY = 12,
  DELETE_API_KEY = 13,
  WITHDRAW = 14,
  CREATE_FUND_PWD = 15,
  MODIFY_FUND_PWD = 16,
  UNBIND_EMAIL = 17,
  UNBIND_MOBILE = 18,
  UNBIND_GA = 19,
  ORDER_PAY = 20,
  QUICK_REGISTER = 21,
  QUICK_LOGIN = 22,
  SET_PASSWORD = 23,
  EDIT_ANTI_PHISH_CODE = 24,
  CHANGE_BIND_EMAIL = 25,
  CHANGE_BIND_MOBILE = 26,
  CHANGE_BIND_GA = 27,
  /**
   * 换绑邮箱的新邮箱
   */
  CHANGE_BIND_EMAIL_NEW = 28,
  /**
   * 换绑手机的新手机
   */
  CHANGE_BIND_MOBILE_NEW = 29
}
/**
 * 止盈止损价格类型
 */
export enum StopLossProfitType {
  /**
   * 价格
   */
  PRICE,
  /**
   * 百分比
   */
  PERCENTAGE
}

/**
 * 止盈止损平仓类型
 */
export enum StopLossProfitCloseType {
  /**
   * 仓位止盈止损
   */
  POSITION,
  /**
   * 按数量止盈止损
   */
  QUANTITY
}

/**
 * 带单人首页设置状态
 */
export enum TakeUserSettingStatus {
  /**
   * 公开
   */
  PUBLIC,
  /**
   * 自己可见
   */
  PRIVATE
}

/**
 * 全局公告展示状态
 */
export enum GlobalNoticeStatus {
  /**
   * 不展示
   */
  HIDE,
  /**
   * 展示
   */
  SHOW
}

/**
 * 价格预警交易类型
 */
export enum PriceAlarmExchangeType {
  EXCHANGE = 1,
  FUTURE
}
/**
 *  价格预警类型
 */
export enum PriceAlarmType {
  /**
   * 价格涨到
   */
  PRICE_INCREASE = 1,
  /**
   * 价格跌到
   */
  PRICE_DECREASE,
  /**
   * 涨幅达到
   */
  RATE_INCREASE,
  /**
   * 跌幅达到
   */
  RATE_DECREASE,
  /**
   * 24小时涨幅
   */
  DAILY_RATE_INCREASE,
  /**
   * 24小时跌幅
   */
  DAILY_RATE_DECREASE

}
/**
 * 提醒频率
 */
export enum PriceAlarmFrequency {
  /**
   * 一次
   */
  ONCE = 1,
  /**
   * 每日
   */
  DAILY,
  /**
   * 每次
   */
  EVERY
}

/**
 * 合仓分仓模式
 */
export enum PositionMergeMode {
  /**
   * 合仓
   */
  MERGED,
  /**
   * 分仓
   */
  SINGLE
}

/**
 * 添加保证金类型
 */
export enum AddMarginType {
  /**
   * 主账户
   */
  MAIN,
  /**
   * 跟单账户
   */
  FOLLOW
}

/**
 * 策略交易类型
 */
export enum StrategyType {
  /**
   * 网格交易
   */
  GRID = 1,
  /**
   * 马丁
   */
  MARTIN
}

/**
 * 策略交易方向
 */
export enum StrategySide {
  /**
   * 买入开多
   */
  LONG = 1,
  /**
   * 卖出开空
   */
  SHORT
}
/**
 * 策略交易仓位模式类型
 */
export enum StrategyPositionMode {
  /**
   * 全部
   */
  ALL = 1,
  /**
   * 逐仓
   */
  ISOLATED,
  /**
   * 全仓
   */
  CROSS
}

/**
 * 网格类型
 */
export enum StrategyGridType {
  /**
   * 等差
   */
  EQUAL_DIFF = 1,
  /**
   * 等比
   */
  EQUAL_RATIO
}

/**
 * 网格订单tab栏类型
 */
export enum StrategyOrderTabType {
  /**
   * 运行中
   */
  PROCESSING = 1,
  /**
   * 历史
   */
  HISTORY
}

/**
 * 网格订单状态
 */
export enum StrategyOrderStatus {
  /**
   * 待执行
   */
  PENDING = 1,
  /**
   * 待触发
   */
  WATING,
  /**
   * 进行中
   */
  PROCESSING,
  /**
   * 关闭中
   */
  CLOSEING,
  /**
   * 已关闭
   */
  CLOSED,
  /**
   * 已取消
   */
  CANCELED
}

// 策略订单修改状态枚举
export enum StrategyOrderProcessStatus {
  /**
   * 运行
   */
  PROCESS = 1,
  /**
   * 终止
   */
  CLOSE
}
/**
 * 体验金/抵扣金类型
 */
export enum CouponType {
  /**
   * 抵扣金
   */
  DEDUCTION = 'DEDUCTION_COUPON',
  /**
   * 体验金
   */
  EXPERIENCE = 'EXPERIENCE_COUPON'
}

/**
 * 登录页面的tab
 */
export enum LoginTab {
  /**
   * 账户登录
   */
  ACCOUNT,
  /**
   * 手机登录
   */
  QUICK
}
/**
 * 注册页面的tab
 */
export enum RegisterTab {
  /**
   * 邮箱注册
   */
  EMAIL,
  /**
   * 手机注册
   */
  MOBILE
}
/**
 * 忘记密码的tab
 */
export enum ForgetPassTab {
  /**
   * 邮箱
   */
  EMAIL,
  /**
   * 手机
   */
  MOBILE
}

// 登陆后分辨注册类型
export enum RegisterType {
  /**
   * 手机注册
   */
  MOBILE = 1,
  /**
   * 邮箱注册
   */
  EMAIL
}

// 代理中心总览的图表大类型
export enum AgentOverviewChartType {
  /**
   * 邀请趋势
   */
  INVITE_TREND = 1,
  /**
   * 交易趋势
   */
  TRADE_TREND
}

// 代理中心总览的邀请趋势tab
export enum AgentOverviewInviteTrendTab {
  /**
   * 邀请人数
   */
  INVITE_PEOPLE = 1,
  /**
   * 佣金收入
   */
  INVITE_INCOME
}

// 代理中心总览的交易趋势tab
export enum AgentOverviewTradeTrendTab {
  /**
   * 币币
   */
  COIN = 1,
  /**
   * 合约
   */
  FUTURE
}

// 代理中心 个人 团队 tab
export enum SingleTeamTab {
  /**
   * 个人
   */
  SINGLE = 1,
  /**
   * 团队
   */
  TEAM
}

export enum LoginShowStatus {
  /**
   * 登录前后都显示
   */
  BOTH,
  /**
   * 登录前展示
   */
  UN_LOGIN,
  /**
   * 登录后展示
   */
  LOGIN
}

/**
 * 为了兼容东东后端修改，代理商的订单明细和分佣明细区分了一下几个类型
 */
export enum AgentOrderType {
  /**
   * 币币订单
   */
  COIN = 1,
  /**
   * 合约所有订单(包含3，4)
   */
  FUTURE_ALL,
  /**
   * 跟单账户订单
   */
  FOLLOW_ACCOUNT,
  /**
   * 合约主账户
   */
  FUTURE_MAIN_ACCOUNT
}

export enum PositionAccountType {
  /**
   * 全部
   */
  ALL,
  /**
   * 合约主账户
   */
  FUTURE_MAIN_ACCOUNT,
  /**
   * 跟单账户
   */
  FOLLOW_ACCOUNT
}

/**
 * 代理商团队管理页面修改的类型
 */
export enum AgentTeamEditType {
  /**
   * 备注
   */
  REMARK = 1,
  /**
   * 合约分佣比例
   */
  FUTURE_RATE,
  /**
   * 币币分佣比例
   */
  COIN_RATE
}

/**
 * 代理商团队管理页面列表tab
 */
export enum AgentTeamTabs {
  /**
   * 团队数据
   */
  TEAM,
  /**
   * 直属数据
   */
  DIRECT
}

/**
 * 代理商团队管理页面列表排序类型
 */
export enum AgentTeamSortType {
  /**
   * 正常
   */
  NORMAL,
  /**
   * 高到低
   */
  TO_BOTTOM,
  /**
   * 低到高
   */
  TO_UP
}

/**
 * 内部提币类型
 */
export enum InnerWithdrawType {
  /**
   * uid
   */
  UID = 1,
  /**
   * 手机
   */
  MOBILE,
  /**
   * 邮箱
   */
  EMAIL
}
/**
 * 提币类型-链上还是内部
 */
export enum WithdrawDirectionType {
  /**
   * 链上
   */
  CHAIN,
  /**
   * 内部转账
   */
  INNER
}
/**
 * kyc层级
 */
export enum KycLevelType {
  /**
   * 未认证
   */
  NO_LEVEL,
  /**
   * 初级认证
   */
  ONE,
  /**
   * 高级认证
   */
  TWO,
  /**
   * 视频认证
   */
  THREE,
  /**
   * 暂时无效的值，占位用
   */
  TEMPORARY_INVALID = 99
}
/**
 * kyc认证状态类型
 */
export enum KycAuthStatusType {
  /**
   * 初始状态-未认证
   */
  INIT,
  /**
   * 认证中
   */
  AUTHING,
  /**
   * 成功
   */
  SUCCESS,
  /**
   * 失败
   */
  FAIL,
  /**
   * 暂时无效的值，占位用
   */
  TEMPORARY_INVALID = 99
}

/**
 * 资产记录类型
 */
export enum AssetRecordTypes {
  /**
   * 充币
   */
  DEPOSIT,
  /**
   * 提币
   */
  WITHDRAW,
  /**
   * 永续合约交易
   */
  FUTURE,
  /**
   * 体验金
   */
  EXPERIENCE,
  /**
   * 抵扣金
   */
  DEDUCTION,
  /**
   * 理财
   */
  FINANCE,
  /**
   * 法币交易
   */
  OTC,
  /**
   * 其他
   */
  OTHER
}

/**
 * 资产记录其他类型的类型值
 */
export enum AssetOtherTypes {
  /**
   * 全部
   */
  ALL,
  /**
   * 转账
   */
  TRANSFER_ACCOUNTS,

  /**
   * 子账户
   */
  CHILD_ACCOUNT,
  /**
   * 划转(内部转账，自己账户间的转账)
   */
  INNER_TRANSFER,
  /**
   * 活动奖励
   */
  EVENT_REWARDS = 5,
  /**
   * 空投
   */
  AIR_DROP,
  /**
   * 邀请返佣
   */
  INVITE_RETURN_COMMISSION,
  /**
   * 其他
   */
  OTHER,
  /**
   * 带单奖励
   */
  LEAD_REWARD,
  /**
   * 推荐跟单奖励
   */
  RECOMMEND_FOLLOW_REWARD,
  /**
   * 跟单奖励
   */
  FOLLOW_REWARD,
  /**
   * 申购
   */
  IEO_SUBSCRIBE,
  /**
   * 分发
   */
  IEO_DISTRIBUTION
}

export enum RankTopTypes {
  /**
   * 盈亏
   */
  PROFIT_LOSS_TOTAL = 1,
  /**
   * 收益率
   */
  PROFIT_LOSS_RATE,
  /**
   * 总资产
   */
  TOTAL_ASSETS
}

/**
 * 代理商左侧菜单栏的key
 */
export enum AgentLeftMenuKeys {
  /**
   * 总览
   */
  OVERVIEW = 1,
  /**
   * 团队管理
   */
  TEAM_MANAGE,
  /**
   * 用户查询
   */
  USER_QUERY,
  /**
   * 用户明细
   */
  USER_DETAIL,
  /**
   * 订单明细
   */
  ORDER_DETAIL,
  /**
   * 佣金明细
   */
  COMMISSION_RECORD,
  /**
   * 充提查询
   */
  DEPOSIT_WITHDRAW_LIST,
  /**
   * 分佣明细
   */
  DISTRIBUTION_DETAIL,
  /**
   * 成员管理
   */
  MEMBER_MANANGE,
  /**
   * 黑名单
   */
  BLACK_LIST,
  /**
   * 当前持仓
   */
  CURRENT_POSITION,
  /**
   * 资产查询
   */
  ASSET_QUERY,
  /**
   * 提币审核
   */
  WITHDRAW_CHECK,
  /**
   * 盈亏记录
   */
  PROFIT_LOSS
}

/**
 * 跟单推荐页面tabs
 */
export enum FollowRecommendTabs {
  /**
   * 总览
   */
  OVERVIEW,
  /**
   * 奖励明细
   */
  REWARD_LIST,
  /**
   * 推荐明细
   */
  RECOMMEND_LIST
}

/**
 * 邀请数据页面tabs
 */
export enum InviteTabs {
  /**
   * 返佣记录
   */
  BONUS,
  /**
   * 邀请记录
   */
  MEMBER
}

/**
 * 世界杯tabs
 */
export enum WorldCupTabs {
  /**
   * 竞猜世界杯
   */
  GUESS,
  /**
   * 合约世界杯
   */
  CONTRACT
}

/**
 * 代理商-提币审核页面tabs
 */
export enum WithdrawCheckTabs {
  /**
   * 待审核
   */
  PENDING_APPROVAL,
  /**
   * 审核历史
   */
  HISTORY
}

/**
 * 代理商-提币审核状态
 */
export enum WithdrawCheckStatus {
  /**
   * 通过
   */
  PASSED = 1,
  /**
   * 被拒绝
   */
  REFUSE
}

/**
 * 允许登录/注册类型
 */
export enum RegisterOptions {
  /**
   * 所有类型
   */
  ALL = 1,
  /**
   * 只允许手机
   */
  ONLY_PHONE,
  /**
   * 只允许邮箱
   */
  ONLY_EMAIL,
  /**
   * 邮箱和中国手机
   */
  EMAIL_AND_CHINA_PHONE
}

/**
 * 跟单跟随比例类型
 */
export enum FollowScaleTypes {
  /**
   * 固定比例
   */
  FIXED_RATIO = 1,
  /**
   * 固定额度
   */
  FIXED_LIMIT,
  /**
   * 跟随比例
   */
  FOLLOW_RATIO
}

/**
 * 账户类型
 */
export enum AccountTypes {
  /**
   * 钱包账户
   */
  WALLET = 1,
  /**
   * 合约账户
   */
  CONTRACT = 3,
  /**
   * 杠杆账户
   */
  LEVER = 27,
  /**
   * 奖励账户
   */
  REWARD = 42
}

/**
 * 推荐跟单，推荐明细中的状态类型
 */
export enum FollowRecommendStatus {
  /**
   * 待生效
   */
  TO_BE_EFFECTIVE = 1,
  /**
   * 有效推荐
   */
  EFFECTIVE_RECOMMEND,
  /**
   * 已失效
   */
  EXPIRED
}
/**
 * 排行榜排序tabs
 */
export enum RankListTabs {
  /**
   * 排行榜
   */
  LEADER_BOARD = 1,
  /**
   * 关注
   */
  ATTENTION
}

/**
 * 理财订单类型
 */
export enum FinanceOrderTypes {
  /**
   * 申购
   */
  SUBSCRIBE,
  /**
   * 赎回
   */
  REDEMPTION,
  /**
   * 收益
   */
  INCOME
}
/**
 * 理财订单申购状态
 */
export enum SubscribeOrderStatus {
  /**
   * 申购中
   */
  PROGRESS,
  /**
   * 申购成功
   */
  SUCCESS
}
/**
 * 理财订单赎回状态
 */
export enum RedemptionOrderStatus {
  /**
   * 赎回中
   */
  PROGRESS,
  /**
   * 赎回成功
   */
  SUCCESS
}
/**
 * 理财订单收益状态
 */
export enum IncomeOrderStatus {
  /**
   * 待发放
   */
  PROGRESS,
  /**
   * 发放成功
   */
  SUCCESS
}

/**
 * 跟单广场布局类型
 */
export enum FollowSquareLayoutTypes {
  /**
   * 一行三人
   */
  ONE_ROW_THREE_USER,
  /**
   * 一行一人
   */
  ONE_ROW_ONE_USER
}

/**
 * 我的跟单页面tabs
 */
export enum MyFollowListTabs {
  /**
   * 跟单资产
   */
  FOLLOW_ASSET,
  /**
   * 持仓中
   */
  CURRENT_POSITION,
  /**
   * 止盈止损
   */
  STOP_PROFIT_LOSS,
  /**
   * 历史成交
   */
  HISTORY_TRADE
}

/**
 * 邀请类型
 */
export enum InviteTypes {
  /**
   * 直接邀请
   */
  DIRECT = 1,
  /**
   * 间接邀请
   */
  INDIRECT
}

/**
 * 登录历史状态
 */
export enum LoginHistoryStatus {
  /**
   * 成功
   */
  SUCCESS = 1,
  /**
   * 失败
   */
  FAIL,
  /**
   * 未完成
   */
  INCOMPLETE,
  /**
   * 扫码成功
   */
  SCAN_SUCCESS,
  /**
   * 扫码登录取消
   */
  SCAN_CANCEL,
  /**
   * 扫码登录确认
   */
  SCAN_CONFIRM
}

/**
 * 登录页面扫码登录二维码状态
 */
export enum LoginQrcodeStatus {
  /**
   * 授权成功
   */
  AUTH_SUCCESS,
  /**
   * 生成二维码等待扫码
   */
  WATI_SCAN,
  /**
   * 已扫码状态
   */
  SCANNED,
  /**
   * 二维码过期
   */
  QR_EXPIRED
}

/**
 * 排行榜个人主页无数据展示的类型
 */
export enum RankUserHomePageEmptyTypes {
  /**
   * 暂无数据
   */
  NO_DATA = 1,
  /**
   * 仓位仅粉丝可见
   */
  ONLY_FANS_VIEW,
  /**
   * 该用户未公开仓位
   */
  UNPUBLISH_POSITION
}
/**
 * 修改昵称头像的类型
 */
export enum EditNickPhotoTypes {
  /**
   * 头像
   */
  PHOTO = 1,
  /**
   * 昵称
   */
  NICK
}

/**
 * 排行榜-关注状态
 */
export enum RankFollowStatus {
  /**
   * 未关注
   */
  NOT_FOLLOW,
  /**
   * 已关注
   */
  FOLLOWED,
  /**
   * 互相关注
   */
  FOLLOWED_MUTUAL
}

/**
 * 排行榜-主页tabs
 */
export enum RankHomePageTabs {
  /**
   * 仓位一览
   */
  WARE_HOUSE_OVERVIEW = '1',
  /**
   * 收益走势
   */
  EARNINGS_TREND = '2',
  /**
   * 设置
   */
  SETTING = '3'
}

/**
 * 排行榜-个人主页设置持仓/资产的可见范围
 */
export enum RankPositionVisibility {
  /**
   * 公开
   */
  PUBLIC,
  /**
   * 仅粉丝可见
   */
  ONLY_FANS,
  /**
   * 仅自己可见
   */
  ONLY_SELF
}

/**
 * 排行榜-个人主页设置公开范围
 */
export enum RankPublicVisibility {
  /**
   * 公开
   */
  PUBLIC,
  /**
   * 隐藏
   */
  HIDDEN
}

/**
 * 排行榜-个人主页收益走势tab
 */
export enum RankUserEarnTrendTabs {
  /**
   * 收益率
   */
  EARN_RATE,
  /**
   * 累计收益
   */
  ACCUMULATED_INCOM,
  /**
   * 资产走势
   */
  ASSET_TRENDS
}
/**
 * 止盈止损平仓类型
 */
export enum StopLossClosePositionType {
  /**
   * 可平仓位
   */
  AVAILABLE,
  /**
   * 全部仓位
   */
  ALL
}
/**
 * 价格基准
 */
export enum FuturePriceBase {
  /**
   * 市场价格
   */
  MARKET,
  /**
   * 标记价格
   */
  MARK
}

/**
 * 修改资金密码的类型-第一次设置还是修改
 */
export enum EditFundPassType {
  /**
   * 初次设置
   */
  SET_FIRST = 1,
  /**
   * 修改资金密码
   */
  EDIT
}

/**
 * IEO项目状态
 */
export enum IeoProjectStatus {
  /**
   * 未开始
   */
  NOT_START = 1,
  /**
   * 进行中
   */
  PROGRESS,
  /**
   * 结果计算中
   */
  SETTLEMENT,
  /**
   * 公布结果
   */
  PUBLISH_RESULT,
  /**
   * 结束
   */
  CLOSED
}
/**
 * IEO status
 */
export enum IeoOpenStatus {
  DELETED = 0,
  OPEN,
  CLOSED,
  FINISH,
  IEO_FINISH
}
/**
 * IEO TYPE
 */
export enum IeoType {
  /**
   * 自由模式
   */
  FREE_MODE = 1,
  /**
   * 阳光普照
   */
  SUNSHINE = 2,
  /**
   * 抢购模式
   */
  PANIC_BUY = 3
}

/**
 * 登录的方式
 */
export enum LoginType {
  /**
   * 账户登录
   */
  ACCOUNT = 1,
  /**
   * 二维码登录
   */
  QRCODE
}

/**
 * 最近登录 操作记录 tabs
 */
export enum UserLoginActionTabs {
  /**
   * 最近登录
   */
  LOGIN_HISTORY = 1,
  /**
   * 操作记录
   */
  ACTION_HISTORY = 2
}

/**
 * 操作记录
 */
export enum UserActionTypes {
  /**
   * 修改密码
   */
  UPDATE_PASSWORD = 'updatePassword',
  /**
   * 绑定邮箱
   */
  BIND_EMAIL = 'bindEmail',
  /**
   * 绑定手机
   */
  BIND_MOBILE = 'bindMobile',
  /**
   * 绑定GA
   */
  BIND_GA = 'bindGA',
  /**
   * 找回密码
   */
  FIND_PWD = 'findPwd',
  /**
   * 创建API
   */
  CREATE_API_KEY = 'createApiKey',
  /**
   * 删除API
   */
  DELETE_API_KEY = 'deleteApiKey',
  /**
   * 设置交易密码
   */
  SET_TRADE_PASSWORD = 'setTradePassword',
  /**
   * 设置OTC昵称
   */
  SET_OTC_NICKNAME = 'setOtcNickName',
  /**
   * 新增OTC支付方式
   */
  ADD_OTC_PAYMENT = 'addOtcPayment',
  /**
   * 删除OTC支持方式
   */
  DELETE_OTC_PAYMENT = 'deleteOtcPayment',
  /**
   * 创建子账户
   */
  CREATE_SUB_ACCOUNT = 'createSubAccount',
  /**
   * 购买理财
   */
  PURCHASE_FINANCE = 'purchaseFinance'
}

/**
 * 跟单广场排序类型
 */
export enum FollowSquareSortType {
  /**
   * 综合排序
   */
  TOTAL,

  /**
   * 跟随人数
   */
  PERSON_NUMBER,
  /**
   * 总收益率
   */
  PROFIT_RATE,
  /**
   * 总收益额
   */
  PROFIT_AMOUNT
}

/**
 * 带单人列表数据时长类型
 */
export enum FollowSquareDateType {
  /**
   * 30天
   */
  MONTH,
  /**
   * 7天
   */
  WEEK
}

export enum Compare {
  LT,
  EQ,
  GT
}

/**
 * 普通邀请kyc类型
 */
export enum CustomInviteKycLevel {
  /**
   * 无需kyc
   */
  KYC0,
  /**
   * KYC1
   */
  KYC1,
  /**
   * KYC2
   */
  KYC2
}

/**
 * 普通邀请生效状态
 */
export enum CustomInviteStatus {
  /**
   * 待生效
   */
  WAITTING = 1,
  /**
   * 已生效
   */
  WORKED = 2
}

/**
 * 福利中心任务完成状态
 */
export enum WelfareCenterTaskStatus {
  /**
   * 未完成
   */
  UNFINISHED,
  /**
   * 已完成
   */
  FINISHED
}

/**
 * 活动类型 1.新手任务，2.用户任务 3:限时任务
 */
export enum WelfareType {
  /**
   * 新手任务
   */
  NEW_USER_TASK = 1,
  /**
   * 用户任务
   */
  USER_TASK = 2,
  /**
   * 限时任务
   */
  TIME_LIMIT_TASK = 3
}

/**
 * 组团邀请活动的状态
 */
export enum TeamInviteActivityStatus {
  /**
   * 未开始
   */
  NOT_START,
  /**
   * 进行中
   */
  WORKING,
  /**
   * 已结束
   */
  END
}

/**
 * 组团邀请-团队任务的状态
 */
export enum TeamInviteTaskStatus {
  /**
   * 待成团
   */
  WATI,
  /**
   * 进行中
   */
  WORKING,
  /**
   * 活动未完成
   */
  NOT_COMPLETE,
  /**
   * 活动完成
   */
  COMPLETE
}

/**
 * 组团邀请-团队邀请是否有效
 */
export enum TeamInviteTaskIsDone {
  /**
   * 未结束
   */
  EFFECTIVE,
  /**
   * 已结束
   */
  OVER
}

/**
 * 学习中心配置枚举
 */
export enum GuideConfigType {
  HEAD = 1,
  VIDEO,
  CATEGORY1,
  CATEGORY2,
  CATEGORY3,
  CATEGORY4,
  RECOMMEND
}
/**
 * 代理商-佣金发放状态
 */
export enum AgentCommissionStatus {
  /**
   * 空
   */
  EMPTY,
  /**
   * 未发放
   */
  NO_GRANT,
  /**
   * 部分发放
   */
  PART_GRANT,
  /**
   * 已发放
   */
  GRANTED
}

/**
 * 团队邀请是否是团长
 */
export enum TeamInviteUserIsLeader {
  /**
   * 否
   */
  NO,
  /**
   * 是
   */
  YES
}

/**
 * 剩蛋活动奖励类型
 */

export enum ChristmasRecordType {
  /**
   * 体验金
   */
  EXPERIENCE = 1,
  /**
   * 抵扣金
   */
  DEDUCTION,
  /**
   * 真U
   */
  USDT,
  /**
   * 白名单
   */
  WHITE_LIST,
  /**
   * 随机真U
   */
  RANDOM_USDT
}
/**
 * 抽奖类型
 */
export enum ChristmasLotteryType {
  SILVER = 1,
  GOLD
}
/**
 * K线价格类型
 */
export enum KlinePriceType {
  /**
   * 指数价
   */
  INDEX,
  /**
   * 标记价
   */
  MARK,
  /**
   * 最新价
   */
  MARKET
}

/**
 * 跟踪委托回调类型
 */
export enum TrackCallbackType {
  /**
   * 按比例
   */
  PERCENTAGE = 1,
  /**
   * 按价格差距
   */
  DIFFERENCE
}

/**
 * 新kyc认证类型
 */
export enum KycVerifyType {
  /**
   * 人工
   */
  USER_VERIFY = 1,
  /**
   * 人脸
   */
  FACE_VERIFY
}

/**
 * 仓位历史时间类型
 */
export enum PositionHistoryTimeType {
  /**
   * 最近一周
   */
  LAST_WEEK = 1,
  /**
   * 最近一月
   */
  LAST_MONTH,
  /**
   * 自定义
   */
  CUSTOM
}
/**
 * 带单申请联系方式类型
 */
export enum TakeApplyContactType {
  EMAIL,
  MOBILE
}

/**
 * 带单申请社交媒体类型 0：telegram 1：twitter 2: facebook 3: discord
 */
export enum TakeApplySocialType {
  TELEGRAM,
  TWITTER,
  FACEBOOK,
  DISCORD
}

/**
 * 后台可设置的function类型，以控制前台模块的展示
 */
export enum FunctionType {
  MARGIN = 'margin',
  USER_LEVEL = 'userLevel',
  BONUS = 'bonus',
  EXCHANGE = 'exchange',
  INVITE = 'invite',
  RED_PACKET = 'red_packet',
  SHOW_SUB_ACCO = 'showSubAcco',
  FUTURES = 'futures',
  OTC = 'otc',
  PROFIT_LOSS = 'profitLoss',
  FOLLOW = 'follow',
  STRATEGY = 'strategy',
  IEO = 'ieo'
}

/**
 * 充值赠金活动状态
 */
export enum RechargeBonusStatus {
  /**
   * 未开始
   */
  NOT_START = 1,
  /**
   * 已开始
   */
  STARTED,
  /**
   * 结束
   */
  END
}
/**
 * 充值赠金-用户任务列表状态
 */
export enum RechargeBonusUserTaskStatus {
  /**
   * 进行中
   */
  IN_PROGRESS = 1,
  /**
   * 已失效
   */
  DEACTIVATED,
  /**
   * 已完成
   */
  COMPLETED
}

/**
 * 充值赠金-盈利规则的开关
 */
export enum RechargeBonusPnlRuleType {
  /**
   * 开
   */
  OPEN = 1,
  /**
   * 关
   */
  CLOSE
}

export enum IEOStatus {
  /** 预热 */
  PREHEAT = 1,
  /** 开始购买 */
  BUY_START,
  /** 购买结束 */
  BUY_END,
  /** 公布结果 在阳光普照类型下是结束 */
  PUBLISH_RESULT,
  /** 结束 */
  END
}

export enum IEOActivityType {
  /** 阳光普照 */
  SUNSHINE = 2,
  /** 抢购活动 */
  PANIC_BUY
}

export enum IEOBasicInfoStatus {
  /** 删除 */
  DELETE = 0,
  /** 开启 */
  OPEN,
  /** 关闭 */
  CLOSE,
  /** 提前结束 */
  END

}

export enum IEOProjectType {
  /** 无固定锁仓周期 */
  NO_FIXED_LOCK = 0,
  /** 固定周期锁仓 */
  FIXED_LOCK
}

export enum IEOOrderStatus {
  /** 进行中 */
  IN_PROGRESS = 0,
  /** 已完成 */
  COMPLETED
}

/**
 * 合约资产分析 数据展示的类型
 */
export enum ContractAnalyzeDataShowType {
  /**
   * 全部币种和单个币种展示
   */
  ALL_AND_SINGLE = 1,
  /**
   * 全部币种展示
   */
  ALL_FUTURE,
  /**
   * 单个币种展示
   */
  SINGLE_FUTURE
}
