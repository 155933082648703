import type { AxiosRequestConfig } from 'axios'
import type { FollowSquareDateType, FollowSquareSortType, PositionHistoryTimeType, RegisterTab, TakeApplyContactType, TakeApplySocialType } from '../types/enums'

import type * as URLKEYS from './URL_CONSTANT'
import type { ResponseType } from './apiResponseType'
import {
  ADDRESS_CHECK,
  ADD_ADDRESS,
  ADD_BLACK,
  ADD_FOLLOW_AMOUNT,
  ADD_PRICE_ALARM,
  ADD_SPOT_FAVORITE,
  APPLY_TOKEN,
  BIND_EMAIL,
  BIND_GA,
  BIND_MOBILE,
  CANCEL_ALL_DELEGATE,
  CANCEL_ALL_SPOT_ORDER,
  CANCEL_ALL_SPOT_PLAN_ORDER,
  CANCEL_BONUS_ORDER,
  CANCEL_BONUS_STOP_PROFIT_LOSS,
  CANCEL_ENTRUST,
  CANCEL_FOCUS_TOP,
  CANCEL_SPOT_FAVORITE,
  CANCEL_SPOT_ORDER,
  CANCEL_SPOT_PLAN_ORDER,
  CANCEL_STOP_PROFIT_LOSS,
  CANCEL_WITHDRAW_ORDER,
  CHANGE_BIND_EMAIL,
  CHANGE_BIND_GA,
  CHANGE_BIND_MOBILE,
  CHANGE_STRATEGY_ORDER_STATUS,
  CHECK_IP,
  CHRISTMAS_LOTTERY,
  COMMON_EMAIL_VALID_DOMAIN,
  CREATE_BONUS_ORDER,
  CREATE_IEO_NEW_ORDER,
  CREATE_SPOT_ORDER,
  CREATE_SPOT_PLAN_ORDER,
  CREATE_STRATEGY_ORDER,
  CURRENT_ENTRUST_LIST,
  CURRENT_POSITION_LIST,
  CURRENT_STOP_LOSS_ENTRUST,
  CUSTOM_INVITE_AWARD_LIST,
  CUSTOM_INVITE_CAROUSEL,
  CUSTOM_INVITE_INFO,
  CUSTOM_INVITE_LIST,
  DELETE_PRICE_ALARM,
  DEL_ADDRESS,
  DEL_BLACK,
  EDIT_BONUS_EXCHANGE_LEVERAGE,
  EDIT_INTRODUCE,
  EDIT_NICK_PHOTO,
  EDIT_USER_PRICE_BASE,
  EMAIL_FIND_PWD_CHECK,
  EMAIL_REGISTER,
  ENTRUST_MATCH_INFO,
  EXAMINE_WITHDRAW,
  EXPORT_INVITE_ORDER,
  EXPORT_INVITE_ORDER_V_1,
  FIND_PWD,
  FIND_PWD_CHECK2,
  FOCUS_TOP,
  FOLLOW_CONFIG,
  FOLLOW_ORDER_CREATE,
  GET_ACTION_HISTORY,
  GET_ACTIVITY_RANK,
  GET_AGENT_ASK_ADDRESS,
  GET_AGENT_ASSETS_RECORD,
  GET_AGENT_ASSETS_SNAPSHOT,
  GET_AGENT_PROFIT_LOSS,
  GET_AGENT_SINGLE_ASSETS_RECORD,
  GET_AGENT_SINGLE_ASSETS_SNAPSHOT,
  GET_AGENT_TEAM_DATA,
  GET_AGENT_TEAM_DATA_EXPORT,
  GET_AGENT_WITHDRAW_CHECK,
  GET_AGETN_FEE_LIST,
  GET_ALL_AWARD,
  GET_ALTER_GA_INFO,
  GET_APP_TOTAL_ASSET,
  GET_ASSET_DISTRIBUTION,
  GET_ASSET_INFO,
  GET_AVAILABLE_BONUS,
  GET_BASE_INFO,
  GET_BASIC_COUNTRIES,
  GET_BASIC_HEAD_CONFIG,
  GET_BASIC_LANG,
  GET_BASIC_TOKENS,
  GET_BASIC_UNIT,
  GET_BLACK_LIST,
  GET_BONUS_CURRENT_ORDER,
  GET_BONUS_ENTRUST_MATCH_INFO,
  GET_BONUS_EXCHANGE_LEVERAGE,
  GET_BONUS_HISTORY_ORDER,
  GET_BONUS_HISTORY_STOP_LOSS_ENTRUST,
  GET_BONUS_HISTORY_TRADE,
  GET_BONUS_ORDER_INFO,
  GET_BONUS_POSITION,
  GET_BONUS_POSITION_HISTORY,
  GET_BONUS_STOP_PROFIT_LOSS,
  GET_BONUS_TRADEABLE,
  GET_CALCULATOR_LIQUIDATION_PRICE,
  GET_CALCULATOR_PROFIT_INFO,
  GET_CHECK_FIRST_FOLLOW,
  GET_CHRISTMAS_USER_INFO,
  GET_COIN_PROFIT_LOSS_DAYS_INFO,
  GET_COIN_PROFIT_LOSS_START_TIME,
  GET_COIN_PROFIT_LOSS_SUMMARY,
  GET_COMMON_LANG_CONFIG,
  GET_CONTRACT_BALANCE_FLOW,
  GET_COUPON_LIST,
  GET_CURRENT_CONTRACT_ORDERS,
  GET_CUSTOM_KV,
  GET_DEPOSIT_ADDRESS,
  GET_DEPOSIT_ORDER_LIST,
  GET_EMAIL_AUTO_SUFFIX,
  GET_ENS_ADDRESS,
  GET_EXCHANGE_PROFIT_INFO,
  GET_FANS_RANK,
  GET_FAVORITE_LIST,
  GET_FINANCE_RECORD_LIST,
  GET_FOLLOW_ACCOUNT,
  GET_FOLLOW_ACCOUNT_V2,
  GET_FOLLOW_COMMON_CONFIG,
  GET_FOLLOW_CONFIG,
  GET_FOLLOW_ORDER_CREATE_SETTING,
  GET_FOLLOW_ORDER_LIST,
  GET_FOLLOW_ORDER_SETTING,
  GET_FOLLOW_PIC_CONFIG,
  GET_FOLLOW_RANK,
  GET_FOLLOW_RECOMMEND_OVERVIEW,
  GET_FOLLOW_RECOMMEND_RECORD_LIST,
  GET_FOLLOW_RECOMMEND_REWARD_LIST,
  GET_FOLLOW_STOP_PROFIT_LIST,
  GET_FOLLOW_TRADEABLE,
  GET_FOLLOW_USER_POSITION,
  GET_FUNDING_RATES,
  GET_FUN_POOL,
  GET_FUTURE_CLOSE_PROMPT_POSITION,
  GET_FUTURE_MY_TRADES,
  GET_GA_INFO,
  GET_GLOBAL_NOTICE,
  GET_GUIDE_ARTICLE_DETAIL,
  GET_GUIDE_ARTICLE_PREVIEW,
  GET_GUIDE_HOME_CONFIG,
  GET_HOT_SYMBOL,
  GET_IEO_ITEM_DETAIL,
  GET_IEO_ORDER_LIST,
  GET_IEO_PROJECT_LIST,
  GET_INDEX_CONFIG,
  GET_INDEX_FORMULA,
  GET_INNER_DEPOSIT_ADDRESS,
  GET_INVITATION_AGENT_INFO,
  GET_INVITATION_BONUS_LIST,
  GET_INVITATION_BOUNUS_RECORD,
  GET_INVITATION_INFO,
  GET_INVITATION_LOCK,
  GET_INVITATION_RELATION_LIST,
  GET_INVITE_AGENT_COMMISSION_LIST,
  GET_INVITE_AGENT_COMMISSION_MONTHS,
  GET_INVITE_PROFIT_LOSS_INFO,
  GET_INVITE_SHARE_TEMPLATE_INFO,
  GET_INVITE_TRADE_ORDER_LIST,
  GET_INVITE_TRADE_ORDER_LIST_V_1,
  GET_INVITE_TREND_INFO,
  GET_INVITE_USER_TRADE_LIST,
  GET_LEVERAGE,
  GET_LOGIN_HISTORY,
  GET_LOGIN_QRCODE,
  GET_LOGIN_QRCODE_STATUS,
  GET_MULTIPLE_KLINE,
  GET_NOTICE_DETAIL,
  GET_NOTICE_TYPE_READ_STATUS,
  GET_OFFICIAL_CHANNEL,
  GET_OFFICIAL_CHANNEL_LOGO,
  GET_ORDER_INFO,
  GET_ORDER_SETTING,
  GET_OTC_BALANCE_FLOW,
  GET_OTC_LIMIT_TOTAL,
  GET_OTHER_ORDER_LIST,
  GET_POPUP_AD,
  GET_POSITION_HISTORY,
  GET_POSITION_MERGE_MODE,
  GET_POSITION_MODE,
  GET_PRICE_ALARM_LIST,
  GET_PROFIT_INFO,
  GET_PROFIT_INFO_NO_LOGIN,
  GET_PROFIT_LOSS_TREND,
  GET_QUOTE_BROKER,
  GET_QUOTE_DEPTH,
  GET_QUOTE_INDEX_KLINE,
  GET_QUOTE_KLINE,
  GET_QUOTE_MARK_KLINE,
  GET_QUOTE_TRADES,
  GET_RATE,
  GET_RATES_HISTORY,
  GET_RECHARGE_BONUS,
  GET_RECHARGE_BONUS_ACTIVITY_INFO,
  GET_RECHARGE_BONUS_TRADING_PROFIT_LOSS,
  GET_SERVER_TIME,
  GET_SHARE_POSTER_DATA,
  GET_SHOW_AGENT_STATUS,
  GET_SIGN_NUMBERS,
  GET_SIGN_STATUS,
  GET_SPOT_HISTORY_ORDER_LIST,
  GET_SPOT_HISTORY_TRADE_LIST,
  GET_SPOT_ORDER_DETAIL,
  GET_SPOT_ORDER_LIST,
  GET_SPOT_PLAN_HISTORY_LIST,
  GET_SPOT_PLAN_ORDER_DETAIL,
  GET_SPOT_PLAN_ORDER_LIST,
  GET_STAKING_ASSET,
  GET_START_TIME,
  GET_STOP_LOSS_INFO,
  GET_STRATEGY_AI_PARAMS,
  GET_STRATEGY_ASSET_LIST,
  GET_STRATEGY_MAX_LEVERAGE,
  GET_STRATEGY_ORDER_LIST,
  GET_SUB_ACCOUNTS,
  GET_SWAP_RECORD,
  GET_TAKE_APPLY_STATUS,
  GET_TAKE_BASE_INFO,
  GET_TAKE_CONFIG,
  GET_TAKE_CURRENT,
  GET_TAKE_HISTORY,
  GET_TAKE_HOME_SETTING,
  GET_TAKE_ORDER_LIST,
  GET_TAKE_PROFIT_LIST,
  GET_TAKE_TRADE_INFO,
  GET_TOKEN_INFO,
  GET_TOP_INVITE_ACTIVITY_INFO,
  GET_TOP_INVITE_ACTIVITY_TEAM_INFO,
  GET_TOP_INVITE_AWARD_CAROUSEL,
  GET_TOP_RANK,
  GET_TRADE_TREND_INFO,
  GET_TURNOVER_TYPE,
  GET_TURNTABLE_ACTIVITY_INFO,
  GET_TURNTABLE_INVITE_LIST,
  GET_TURNTABLE_PRIZE_LIST,
  GET_TURNTABLE_REWORD_LIST,
  GET_TURNTABLE_USER_REWORD_LIST,
  GET_USERS_POSITION,
  GET_USER_ACCOUNT_INFO,
  GET_USER_ASSETS,
  GET_USER_CONTRACT_ASSETS,
  GET_USER_FUTURE_SETTING,
  GET_USER_FUTURE_TRADEABLE,
  GET_USER_KYC_LEVEL_INFO,
  GET_USER_OPEN_INFO,
  GET_USER_PRICE_BASE,
  GET_USER_PUSH_SETTING,
  GET_USER_SETTING,
  GET_USER_TRANSFER_ASSETS,
  GET_VERIFY_INFO,
  GET_WELFARE_DATA,
  GET_WELFARE_UNLOGIN_DATA,
  GET_WITHDARW_CHECK_HISTORY,
  GET_WITHDRAW_ADDRESS_LIST,
  GET_WITHDRAW_CHECK_LIST,
  GET_WITHDRAW_ORDER_LIST,
  GET_WITHDRAW_QUOTA_INFO,
  GET_WORLD_CUP_BG,
  GET_WORLD_CUP_CONFIG,
  GET_WORLD_CUP_GUESS_HISTORY,
  GET_WORLD_CUP_PLAY_DATE,
  GET_WORLD_CUP_PRIZE_LIST,
  GET_WORLD_CUP_PRIZE_SCROLL,
  GET_WORLD_CUP_USER_INFO,
  GET_WORLD_CUP_USER_PLAY,
  GET_WORLD_CUP_USER_PRIZE,
  HISTORY_ENTRUST_LIST,
  HISTORY_STOP_LOSS_ENTRUST,
  HISTORY_TRADE_LIST,
  JOIN_TOP_INVITE_TEAM,
  KYC_1_VERIFY,
  KYC_2_VERIFY,
  LOGIN_STEP2,
  LOGOUT,
  MOBILE_FIND_PWD_CHECK,
  MOBILE_REGISTER,
  MODIFY_FOLLOW_MARGIN,
  MODIFY_MARGIN,
  OPEN_CLOSE_FOLLOW,
  OPEN_FUTURE,
  ORDER_CREATE,
  QUICK_AUTHORIZE,
  QUICK_AUTHORIZE_ADVANCE,
  QUICK_BACKHAND,
  READ_ALL,
  RECEIVE_EXPERIENCE,
  REGIST_GEETEST,
  RESET_LOGIN_PASSWORD,
  RESET_PASSWORD,
  SEND_COMMON_EMAIL_VERIFY_CODE,
  SEND_COMMON_SMS_VERIFY_CODE,
  SEND_COMMON_VERIFY_CODE,
  SEND_EMAIL_AUTH_VERIFY_CODE,
  SEND_EMAIL_VERIFY_CODE_LOGINED,
  SEND_MOBILE_AUTH_VERIFY_CODE,
  SEND_MOBILE_VERIFY_CODE_LOGINED,
  SEND_TAKE_APPLY,
  SEND_TAKE_APPLY_REQUEST,
  SEND_VERIFY_CODE_NO_ACCOUNT,
  SEND_WITHDRAW_VERIFY_CODE,
  SET_AGENT_RATE_INFO,
  SET_AGENT_RATE_INFO_COIN,
  SET_AGENT_REMARK_INFO,
  SET_AGETN_SELF_REBATE,
  SET_BONUS_STOP_PROFIT_LOSS,
  SET_FISH_CODE,
  SET_FOLLOW_ORDER_SETTING,
  SET_FUND_PASSWORD,
  SET_ORDER_SETTING,
  SET_POSITION_MERGE_MODE,
  SET_POSITION_MODE,
  SET_STOP_PROFIT_LOSS,
  SET_STOP_PROFIT_LOSS_WITH_QUANTITY,
  SET_TAKE_HOME_SETTING,
  SET_USER_OPEN_INFO,
  SET_USER_PUSH_SETTING,
  SET_USER_SETTING,
  SET_USER_TAKE_CONFIG,
  SET_USER_TAKE_STATUS,
  SWAP_ETH,
  TRANSFER_USER_ASSETS,
  TURNTABLE_DRAW,
  UPDATE_BLACK,
  UPDATE_INVITE_CODE,
  UPDATE_LEVER
  , UPLOAD_IMG
  , USER_AUTHORIZE
  , USER_ENROLL
  , VISIT_USER_TAKE,
  WITHDRAW_CREATE,
  WITHDRAW_VERIFY,
  WORLD_CUP_BUY_GUESS,
  WORLD_CUP_GUESS,
  WORLD_CUP_LUCK_DRAW
} from './URL_CONSTANT'
import type {
  AgentOrderType,
  AssetType,
  CalculatorFeeType,
  ChristmasLotteryType,
  CouponType,
  CustomKVKeys,
  DurationType,
  EditFundPassType,
  EditNickPhotoTypes,
  FutureOrderListType,
  FutureOrderPriceType,
  FutureOrderType,
  FuturePositionSide,
  FuturePriceBase,
  FutureStopConditionType,
  FutureStopStatus,
  FutureTimeInForce,
  InnerWithdrawType,
  OrderSide,
  PositionAccountType,
  PositionMergeMode,
  PositionMode,
  ResolutionValues,
  SpotOrderType,
  StrategyOrderProcessStatus,
  StrategyOrderTabType,
  StrategySide,
  StrategyType,
  SubAccountType,
  SwapDir,
  TakeOpenStatus,
  TakeUserSettingStatus,
  TrackCallbackType
} from '~/types/enums'

import type { IAxios, ServerResponse } from '~/utils/axios'
import axios from '~/utils/axios'
import type { DeepExpand, JSONString, ObjectValues } from '~/types/utils'
import type { CreateGridParams, CreateMartinParams, CreateStrategyParams, LoginedSendVeifyParams, PriceAlarm, SendCommonSmsVerifyCode, SendSecondWithdrawVerify, UserCommonConfig } from '~/types'

export type IResponse<T> =
  | {
    err: Error | IAxios<T>
    res: null
  }
  | {
    err: null
    res: DeepExpand<T>
  }

export type RequestKeyType = ObjectValues<typeof URLKEYS>
type AxiosRequestConfigWithURL<T extends RequestKeyType> =
  AxiosRequestConfig & { url: T }

function request<T extends RequestKeyType>(
  config: AxiosRequestConfigWithURL<T>
) {
  return new Promise<ServerResponse<ResponseType[T]>>((resolve, reject) => {
    /*
    传递泛型给http中的拦截器
    */
    axios
      .request<ResponseType[T]>({
        url: config.url,
        data: config.data || '',
        params: config.params || '',
        method: config.method || 'get',
        headers: config.headers || {}
      })
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
/**
 * @description 错误捕获函数
 * @author 张聪
 * @param {AxiosRequestConfigWithURL} config axios配置
 */
async function errCaptured<T extends RequestKeyType>(
  config: AxiosRequestConfigWithURL<T>
): Promise<IResponse<ServerResponse<ResponseType[T]>>> {
  try {
    const res = await request(config)

    return {
      res,
      err: null
    }
  }
  catch (err: any) {
    return { res: null, err }
  }
}

/**
 * get请求错误捕获函数包装
 * @param url 请求的地址
 * @param params 请求参数
 * @returns Promise
 */
async function getCaptured<T extends RequestKeyType>(url: T, params: Object = {}, config: AxiosRequestConfig = {}) {
  return errCaptured<T>({
    ...config,
    method: 'get',
    url,
    params
  })
}

/**
 * post请求错误捕获函数包装
 * @param url 请求的地址
 * @param data 请求参数
 * @param config
 * @returns Promise
 */

async function postCaptured<T extends RequestKeyType>(url: T, data: Object = {}, config: AxiosRequestConfig = {}) {
  return errCaptured<T>({
    ...config,
    method: 'post',
    url,
    data
  })
}

// // 分页获取数据
// export function initData<T>(
//   url: RequestKeyType,
//   params: any
// ): Promise<IResponse<ServerResponse<PaginationData<T>>>> {
//   return getCaptured(url, params) as unknown as Promise<
//   IResponse<ServerResponse<PaginationData<T>>>
//   >
// }
/* 获取头部导航配置 */
export function getBasicHeadConfig() {
  return getCaptured(GET_BASIC_HEAD_CONFIG)
}
/* 获取语言列表 */
export function getBasicLangList() {
  return getCaptured(GET_BASIC_LANG)
}
export function getIndexConfig(preview = false) {
  return getCaptured(GET_INDEX_CONFIG, { preview })
}
export function getShowAgentStatus() {
  return postCaptured(GET_SHOW_AGENT_STATUS)
}
export function getBaseInfo() {
  return getCaptured(GET_BASE_INFO)
}
export function logout() {
  return getCaptured(LOGOUT)
}
interface GetRateParams {
  tokens: string
  legalCoins: string
}
export function getRate(data: GetRateParams) {
  return postCaptured(GET_RATE, data)
}

export function getUserSetting() {
  return postCaptured(GET_USER_SETTING)
}
interface SetUserSettingParams {
  common_config: JSONString<UserCommonConfig>
}

export function setUserSetting(data: SetUserSettingParams) {
  return postCaptured(SET_USER_SETTING, data)
}

export interface GetProfitInfoParams {
  account_type: AssetType
  date_type: DurationType
  start_time?: number
  end_time?: number
  account_id?: string
  user_id?: string
}

export function getProfitInfo(params: GetProfitInfoParams) {
  return getCaptured(GET_PROFIT_INFO, params)
}

export function getProfitInfoNoLogin(params: GetProfitInfoParams) {
  return getCaptured(GET_PROFIT_INFO_NO_LOGIN, params)
}

export function getSubAccounts() {
  return getCaptured(GET_SUB_ACCOUNTS)
}

export interface GetAssetDistributionParams {
  account_type: AssetType
  date_type: DurationType
  symbol_id?: string
  son_query_type?: SubAccountType
  account_index?: number
  start_time?: string
  end_time?: string
  ts: number
}

export function getAssetDistribution(params: GetAssetDistributionParams) {
  return getCaptured(GET_ASSET_DISTRIBUTION, params)
}

// ---------------- 排行榜
// 查询排行榜以及榜首用户信息
interface GetTopRankParams {
  is_open_position?: number // 是否勾选公开仓位 1:公开 2:不公开
  rank_type?: number // 排行榜类型 1:排行榜 2:关注
  sort_type?: number // 排序类型 1:盈亏 2:收益率 3:资产
  name?: string // 搜索昵称
  user_id?: string
}
export function getTopRank(params: GetTopRankParams) {
  return getCaptured(GET_TOP_RANK, params)
}

// 关注
interface FocusTopParams {
  focus_user_id: number // 关注人id
}
export function focusTop(params: FocusTopParams) {
  return getCaptured(FOCUS_TOP, params)
}

// 取消关注
interface CancelFocusTopParams {
  focus_user_id: number // 关注人id
}
export function cancelFocusTop(params: CancelFocusTopParams) {
  return getCaptured(CANCEL_FOCUS_TOP, params)
}

// 查看用户账户概览
interface GetUserAccountInfoParams {
  user_id?: number // 关注人id
}
export function getUserAccountInfo(params: GetUserAccountInfoParams) {
  return getCaptured(GET_USER_ACCOUNT_INFO, params)
}

// 用户公开信息
interface GetUserOpenInfoParams {
  other_user_id?: number // 关注人id
  own_user_id?: string
}
export function getUserOpenInfo(params: GetUserOpenInfoParams) {
  return getCaptured(GET_USER_OPEN_INFO, params)
}
// 查询用户期货当前持仓
interface GetContractOrdersListParams {
  user_id?: number // id
  from_position_id?: number
  end_position_id: number
  limit?: number
}
export function getContractOrdersList(params: GetContractOrdersListParams) {
  return getCaptured(GET_CURRENT_CONTRACT_ORDERS, params)
}
// 修改用户昵称/头像信息
interface EditNickPhotoParams {
  photo_url?: string
  nick_name?: string
  update_type: EditNickPhotoTypes
}
export function editNickPhoto(params: EditNickPhotoParams) {
  return getCaptured(EDIT_NICK_PHOTO, params)
}

// 上传
export function uploadImg(data: any) {
  return postCaptured(UPLOAD_IMG, data)
}

// 关注列表
interface GetFollowRankParams {
  limit?: number
  from_id?: number
  end_id?: number
  user_id?: number
}
export function getFollowRankList(params: GetFollowRankParams) {
  return getCaptured(GET_FOLLOW_RANK, params)
}

// 粉丝列表
interface GetFansRankParams {
  limit?: number
  from_id?: number
  end_id?: number
  user_id?: number
}
export function getFansRankList(params: GetFansRankParams) {
  return getCaptured(GET_FANS_RANK, params)
}

// 设置用户公开
interface SetUserOpenParams {
  position_open: number // 仓位公开 0：公开 1：粉丝可见 2：仅自己可见
  assert_open: number // (资产公开 0：公开 1：粉丝可见 2：仅自己可见)
  hidden: number // (隐藏 0：未启用 1：隐藏)
}
export function setUserOpen(params: SetUserOpenParams) {
  return getCaptured(SET_USER_OPEN_INFO, params)
}

// =-===============代理中心========================
/**
 * 代理商信息是否锁定
 */
export function getInvitationLock() {
  return getCaptured(GET_INVITATION_LOCK)
}
/**
 * 邀请信息
 */
export function getInvitationInfo() {
  return getCaptured(GET_INVITATION_INFO)
}
/**
 * 总览信息
 */
export function getInvitationAgentInfo() {
  return getCaptured(GET_INVITATION_AGENT_INFO)
}
/**
 * 邀请趋势
 */
export function getInviteTrendInfo(params: {
  startTime: string
  endTime: string
}) {
  return getCaptured(GET_INVITE_TREND_INFO, params)
}
/**
 * 交易趋势
 */
export function getTradeTrendInfo(params: {
  startTime: string
  endTime: string
}) {
  return getCaptured(GET_TRADE_TREND_INFO, params)
}
/**
 * 团队数据
 */
export function getAgentTeamData(params: {
  tradeAmountTeam?: number
  inviteNumTeam?: number
  tokenAmount?: number
  isDirectly?: number
}) {
  return getCaptured(GET_AGENT_TEAM_DATA, params)
}
/**
 * 团队数据导出
 */
export function getAgentTeamDataExport(params: {
  isDirectly?: number
  tradeAmountSort?: number
  inviteCountSort?: number
  tokenAmountSort?: number
  uid?: string
  puid?: string
  startTime?: string | number
  endTime?: string | number
}) {
  return getCaptured(GET_AGENT_TEAM_DATA_EXPORT, params)
}
/**
 * 基础币种列表
 */
export function getBasicTokens() {
  return getCaptured(GET_BASIC_TOKENS)
}
/**
 * 充提明细每日-团队
 */
export function getAgentAssetsRecord(params: {
  timeZone?: string
  searchUserId: string
  page?: number
  pages?: number
  size?: number
  statisticsTime?: string
  endTime?: string
}) {
  return getCaptured(GET_AGENT_ASSETS_RECORD, params)
}
/**
 * 资产快照-团队
 */
export function getAgentAssetsSnapshot(params: {
  searchUserId: string
}) {
  return getCaptured(GET_AGENT_ASSETS_SNAPSHOT, params)
}
/**
 * 充提明细每日-个人
 */
export function getAgentSingleAssetsRecord(params: {
  timeZone?: string
  searchUserId: string
  page?: number
  pages?: number
  size?: number
  statisticsTime?: string
  endTime?: string
}) {
  return getCaptured(GET_AGENT_SINGLE_ASSETS_RECORD, params)
}
/**
 * 资产快照-个人
 */
export function getAgentSingleAssetsSnapshot(params: {
  searchUserId: string
}) {
  return getCaptured(GET_AGENT_SINGLE_ASSETS_SNAPSHOT, params)
}
// 代理商团队充值地址查询
export function getAgentAskAddress(params: {
  tokenId: string
  address: string
  memo?: string
}) {
  return getCaptured(GET_AGENT_ASK_ADDRESS, params)
}
/**
 * 设置返佣-比例--合约
 */
export function setAgentRateInfo(data: {
  setUserId: string
  rate: string | number
}) {
  return postCaptured(SET_AGENT_RATE_INFO, data)
}
/**
 * 设置返佣-比例--币币
 */
export function setAgentRateInfoCoin(data: {
  setUserId: string
  rateCoin: string | number
}) {
  return postCaptured(SET_AGENT_RATE_INFO_COIN, data)
}
/**
 * 设置自返佣
 */
export function setAgentSelfRebate(data: any) {
  return postCaptured(SET_AGETN_SELF_REBATE, data)
}
/**
 * 设置返佣-备注
 */
export function setAgentRemarkInfo(data: {
  setUserId: string
  remark: string
}) {
  return postCaptured(SET_AGENT_REMARK_INFO, data)
}
/**
 * 黑名单列表
 */
export function getBlackList(params: {
  searchUserId: string
  searchEmail: string
  page: number
  pages?: number
  size: number
}) {
  return getCaptured(GET_BLACK_LIST, params)
}
/**
 * 删除黑名单
 */
export function delBlack(data: {
  id: string | number
}) {
  return postCaptured(DEL_BLACK, data)
}
/**
 * 新增黑名单
 */
export function addBlack(data: {
  orgId: number
  id?: string | number
  userId: string
  receiveUserId: string
  remark: string
}) {
  return postCaptured(ADD_BLACK, data)
}
/**
 * 编辑黑名单
 */
export function updateBlack(data: {
  orgId: number
  id: string | number
  userId: string
  receiveUserId: string
  remark: string
}) {
  return postCaptured(UPDATE_BLACK, data)
}
/**
 * 用户明细
 */
export function getInviteUserTradeList() {
  return getCaptured(GET_INVITE_USER_TRADE_LIST)
}
/**
 * 订单明细列表
 */
export function getInviteTradeOrderList(params: {
  orderType: number
  tradeDetailId: string | number
  searchUserId: string
  email: string
  feeCoin: string
  tradeSymbol: string
  startTime?: string | number
  endTime?: string | number
  fromId?: string | number
  endId?: string | number
}) {
  return getCaptured(GET_INVITE_TRADE_ORDER_LIST, params)
}
/**
 * 订单明细下载
 */
export function exportInviteOrder(params: {
  orderType: number
  tradeDetailId: string | number
  searchUserId: string
  email: string
  feeCoin: string
  tradeSymbol: string
  startTime?: string | number
  endTime?: string | number
  fromId?: number
  endId?: number
}) {
  return getCaptured(EXPORT_INVITE_ORDER, params)
}
/**
 * 分佣明细列表
 */
export function getInviteTradeOrderListV1(params: {
  orderType: AgentOrderType
  tradeDetailId: string | number
  searchUserId: string
  email: string
  feeCoin: string
  tradeSymbol: string
  startTime?: string | number
  endTime?: string | number
  fromId?: string | number
  endId?: string | number
}) {
  return getCaptured(GET_INVITE_TRADE_ORDER_LIST_V_1, params)
}
/**
 * 分佣明细下载
 */
export function exportInviteOrderV1(params: {
  orderType: AgentOrderType
  tradeDetailId: string | number
  searchUserId: string
  email: string
  feeCoin: string
  tradeSymbol: string
  startTime?: string | number
  endTime?: string | number
  fromId?: number
  endId?: number
}) {
  return getCaptured(EXPORT_INVITE_ORDER_V_1, params)
}
/**
 * 佣金记录
 */
export function getInviteAgentCommissionList(params: {
  page: number
  size: number
}) {
  return getCaptured(GET_INVITE_AGENT_COMMISSION_LIST, params)
}
/**
 * 佣金记录-日历
 */
export function getInviteAgentCommissionMonths(params: {
  months: string
}) {
  return getCaptured(GET_INVITE_AGENT_COMMISSION_MONTHS, params)
}
/**
 * 获取资产盈亏开始时间
 */
export function getProfitStartTime() {
  return getCaptured(GET_START_TIME)
}
/**
 * 当前持仓
 */
export function getUsersPosition(params: {
  is_long: number | string
  from_balance_id: number | string
  end_balance_id: number | string
  search_user_id: string
  super_user_id: string
  account_index: number
  limit: number
  /**
   * 分仓合仓
   */
  merge_mode: PositionMergeMode
  /**
   * 哪个账户的订单
   */
  position_account_type: PositionAccountType
}) {
  return getCaptured(GET_USERS_POSITION, params)
}
/**
 * 修改邀请码
 */
export function updateInviteCode(data: { inviteCode: string }) {
  return postCaptured(UPDATE_INVITE_CODE, data)
}

/**
 * 盈亏列表
 */
export function getAgentProfitLoss(params: {
  searchUserId?: string
  timeZone?: string
  startTime: string
  endTime: string
  page: number
  pageSize: number
}) {
  return getCaptured(GET_AGENT_PROFIT_LOSS, params)
}

/**
 * 盈亏时间信息
 */
export function getAgentProfitLossInfo() {
  return getCaptured(GET_INVITE_PROFIT_LOSS_INFO)
}

/**
 * 盈亏趋势图数据
 */
export function getAgentProfitLossTrend(params: {
  searchUserId?: string
  startTime: string
  endTime: string
}) {
  return getCaptured(GET_PROFIT_LOSS_TREND, params)
}

/**
 * 获取是否有提币审核权限
 */
export function getAgentWithdrawCheck() {
  return getCaptured(GET_AGENT_WITHDRAW_CHECK)
}

/**
 * 获取资金池资产
 */
export function getFunPool() {
  return getCaptured(GET_FUN_POOL)
}

/**
 * 获取伞下资产
 */
export function getAppTotalAsset() {
  return getCaptured(GET_APP_TOTAL_ASSET)
}

/**
 * 待审核列表
 */
export function getWithdrawCheckList(data: {
  userId?: string
  emai?: string
  phone?: string
  page?: number
  size?: number
}) {
  return postCaptured(GET_WITHDRAW_CHECK_LIST, data)
}

/**
 * 审核历史
 */
export function getWithdrawCheckHistory(data: {
  userId?: string
  emai?: string
  phone?: string
  page?: number
  size?: number
}) {
  return postCaptured(GET_WITHDARW_CHECK_HISTORY, data)
}

/**
 * 审核提币
 */
export function examineWithdraw(data: {
  subUserId: string
  isOk: boolean
  orderId: string
}) {
  return postCaptured(EXAMINE_WITHDRAW, data)
}

/**
 * 获取手续费贡献列表
 */
export function getAgentFeeList(params: {
  searchUserId: string
  statisticsTime?: string
  type: number
}) {
  return getCaptured(GET_AGETN_FEE_LIST, params)
}

/**
 * 查询代理商时间段内总返佣
 */
export function getInvitationBounusRecord(data: {
  token: string
  startTime: number
  endTime: number
}) {
  return postCaptured(GET_INVITATION_BOUNUS_RECORD, data)
}

// ----------------------- 合约争霸
// 获取报名状态
export function getSignStatus(params: any) {
  return getCaptured(GET_SIGN_STATUS, params)
}
// 获取报名人数
export function getSignNumbers(params: any) {
  return getCaptured(GET_SIGN_NUMBERS, params)
}

// 报名参赛
export function userEnroll(data: any) {
  return postCaptured(USER_ENROLL, data)
}

// 获取合约争霸排行榜信息
export function getActivityRank(params: any) {
  return getCaptured(GET_ACTIVITY_RANK, params)
}

// ==================================== 跟单 ====================================

/**
 * 跟单列表
 */
export interface GetFollowOrderListParams {
  user_id?: string
  is_own?: boolean
  take_user_id?: string
  org_id: number
}
export function getFollowOrderList(params: GetFollowOrderListParams) {
  return getCaptured(GET_FOLLOW_ORDER_LIST, params)
}

/**
 * 查询带单人列表
 */
export interface GetTakeOrderListParams {
  user_id?: string
  name?: string
  sort_type?: FollowSquareSortType
  org_id: number
  date_type: FollowSquareDateType
  total: number
  current: number
  page_size: number
}
export function getTakeOrderList(params: GetTakeOrderListParams) {
  return getCaptured(GET_TAKE_ORDER_LIST, params)
}

/**
 * 关闭跟单
 */
interface OpenCloseFollowParams {
  user_id?: string
  take_user_id?: string
  /**
   * 0 否 1 是
   */
  is_close?: number
}
export function openCloseFollow(params: OpenCloseFollowParams) {
  return getCaptured(OPEN_CLOSE_FOLLOW, params)
}

/**
 * 查询跟单设置
 */
export interface GetFollowConfigParams {
  take_user_id?: string
}
export function getFollowConfig(params: GetFollowConfigParams) {
  return getCaptured(GET_FOLLOW_CONFIG, params)
}

/**
 * 跟单设置
 */
export interface FollowConfigParams {
  take_user_id?: string
  symbol_ids?: string
  follow_type?: number
  follow_scale?: string
  follow_lever_type?: number
}
/**
 * 跟单设置
 */
export function followConfig(params: FollowConfigParams) {
  return postCaptured(FOLLOW_CONFIG, params)
}

export interface ApplyTakeParams {
  /**
   * 联系方式
   */
  liaison_content: string
  /**
   * 联系方式类型
   */
  liaison_type: TakeApplyContactType
  /**
   * 资料
   */
  means: string
  /**
   * 社交媒体
   */
  media_content: string
  /**
   * 社交媒体类型
   */
  media_type: TakeApplySocialType
  /**
   * 图片路径
   */
  transaction_url: string
}

/**
 * 申请带单
 */
export function sendTakeApplyRequest(params: ApplyTakeParams) {
  return postCaptured(SEND_TAKE_APPLY, params)
}

/**
 * 获取是否可以申请跟单
 */
export function getTakeApplyStatus() {
  return postCaptured(GET_TAKE_APPLY_STATUS)
}

/**
 * 获取跟单审核状态
 */
export function getTakeApplyCheckStatus() {
  return getCaptured(SEND_TAKE_APPLY_REQUEST)
}

/**
 * 获取带单设置
 */
export function getTakeConfig() {
  return getCaptured(GET_TAKE_CONFIG)
}
/**
 * 开关带单
 */
export function setUserTakeStatus(data: { is_close: TakeOpenStatus }) {
  return getCaptured(SET_USER_TAKE_STATUS, data)
}

export interface SetUserTakeConfigParams {
  symbol_ids: string
  draw_scale: string
}

/**
 * 用户带单设置
 */
export function setUserTakeConfig(data: SetUserTakeConfigParams) {
  return postCaptured(SET_USER_TAKE_CONFIG, data)
}

/**
 * 跟单-当前持仓
 */
export interface GetFollowUserPositionParams {
  symbol_id?: string
  from_position_id?: string
  limit?: number
}

/**
 * 跟单-当前持仓
 */
export function getFollowUserPosition(data: GetFollowUserPositionParams) {
  return getCaptured(GET_FOLLOW_USER_POSITION, data)
}

export interface GetFollowMyTradesParams {
  account_index?: number
  symbol_id?: string
  from_trade_id?: string
  limit?: number
}
/**
 * 跟单-历史成交
 */
export function getFollowMyTrades(data: GetFollowMyTradesParams) {
  return getCaptured(GET_FUTURE_MY_TRADES, data)
}

/**
 * 跟单-止盈止损列表
 */
export interface GetFollowStopProfitListParams {
  end_order_id?: string
  from_order_id: string
  limit: number
}
/**
 * 跟单-止盈止损列表
 */
export function getFollowStopProfitList(data: GetFollowStopProfitListParams) {
  return getCaptured(GET_FOLLOW_STOP_PROFIT_LIST, data)
}

/**
 * 跟单-账户资产
 */
export function getFollowAccount(data: any) {
  return getCaptured(GET_FOLLOW_ACCOUNT, data)
}

/**
 * 跟单-账户资产v2
 */
export function getFollowAccountV2(data: any) {
  return getCaptured(GET_FOLLOW_ACCOUNT_V2, data)
}

/**
 * 用户带单收益
 */
export function getTakeProfitList() {
  return getCaptured(GET_TAKE_PROFIT_LIST)
}

/**
 * 增加跟单投入
 */
export function addFollowAmount(data: { follow_amount?: string }) {
  return postCaptured(ADD_FOLLOW_AMOUNT, data)
}

/**
 * 获取平台自定义键值对
 */
export function getCustomKV(params: { custom_keys: CustomKVKeys }) {
  return getCaptured(GET_CUSTOM_KV, params)
}
export interface PaginationData<T> {
  current: number
  list: T
  pageSize: number
  total: number
}
/**
 * 分页获取数据
 */
export function initData<T extends RequestKeyType>(
  url: T,
  params: any
): Promise<IResponse<ServerResponse<PaginationData<ResponseType[T]>>>> {
  return getCaptured(url, params) as unknown as Promise<
  IResponse<ServerResponse<PaginationData<ResponseType[T]>>>
  >
}
/**
 * 查询是否首次跟单
 */
export function getCheckFirstFollow() {
  return getCaptured(GET_CHECK_FIRST_FOLLOW)
}
interface GetFollowPicConfigParams {
  org_id: number
}
/**
 * 获取跟单图片配置
 */
export function getFollowPicConfig(params: GetFollowPicConfigParams) {
  return getCaptured(GET_FOLLOW_PIC_CONFIG, params)
}
/**
 * 获取跟单通用配置
 */
export function getFollowCommonConfig() {
  return getCaptured(GET_FOLLOW_COMMON_CONFIG)
}
interface GetUserAssetParams {
  account_id: string
}
/**
 * 获取用户资产
 */
export function getUserAsset(params: GetUserAssetParams) {
  return getCaptured(GET_USER_ASSETS, params)
}

interface SwapETHParams {
  account_id: string
  side: SwapDir
  amount: string
  token_id: string
}
/**
 * 兑换分叉币
 */
export function swapETH(params: SwapETHParams) {
  return postCaptured(SWAP_ETH, params)
}

export interface GetSwapRecordParams {
  token_id: string
}
/**
 * 获取兑换分叉币记录
 */
export function getSwapRecord(params: GetSwapRecordParams) {
  return getCaptured(GET_SWAP_RECORD, params)
}

/**
 * 获取用户合约资产
 */
export function getUserContractAsset(params: { token_ids?: string }) {
  return getCaptured(GET_USER_CONTRACT_ASSETS, params)
}

interface ReceiveExperienceParams {
  /**
   * 客户端请求Id，客户端请求唯一id
   */
  clientTransferId: number
  /**
   * 券id
   */
  experienceId: number
  /**
   * 币种id
   */
  tokenId: string
}

export function receiveExperience(params: ReceiveExperienceParams) {
  return postCaptured(RECEIVE_EXPERIENCE, params)
}

export interface GetDepthParams {
  dumpScale: number
  symbol: string
  limit: number
}

/**
 * 获取深度数据
 */
export function getQuoteDepth(params: GetDepthParams) {
  return getCaptured(GET_QUOTE_DEPTH, params)
}

export interface GetKlineParams {
  interval: ResolutionValues
  symbol: string
  from?: string
  to?: string
  limit: number
}
/**
 * 获取k线数据
 */
export function getQuoteKline(params: GetKlineParams) {
  return getCaptured(GET_QUOTE_KLINE, params)
}

export function getQuoteIndexKline(params: GetKlineParams) {
  return getCaptured(GET_QUOTE_INDEX_KLINE, params)
}

export function getQuoteMarkKline(params: GetKlineParams) {
  return getCaptured(GET_QUOTE_MARK_KLINE, params)
}

export interface GetTradeParams {
  symbol: string
  limit: number
}
/**
 * 获取历史成交
 */
export function getQuoteTrades(params: GetTradeParams) {
  return getCaptured(GET_QUOTE_TRADES, params)
}

/**
 * 获取24小时交易信息
 */
export function getQuoteTicker() {
  return getCaptured(GET_QUOTE_BROKER)
}
/**
 * 获取服务器时间
 */
export function getServerTime() {
  return getCaptured(GET_SERVER_TIME)
}

export interface GetUserTransferAssetsParams {
  account_type: number
  account_index: number
  token_id: string
  ts?: number
}

/**
 * 查询用户可划转资产
 */
export function getUserTransferAssets(data: GetUserTransferAssetsParams) {
  return postCaptured(GET_USER_TRANSFER_ASSETS, data)
}

export function getWelfareList() {
  return getCaptured(GET_WELFARE_DATA)
}

/**
 * 获取资金费率
 */
export function getFundingRates() {
  return getCaptured(GET_FUNDING_RATES)
}

/**
 * 获取合约可交易资产
 */
export function getUserFutureTradeable(params: {
  token_ids: string
  exchange_id: number | string
}) {
  return getCaptured(GET_USER_FUTURE_TRADEABLE, params)
}

/**
 * 获取用户合约设置
 */
export function getFutureOrderSettings(params: {
  symbol_ids: string
  exchange_id: number | string
}) {
  return getCaptured(GET_USER_FUTURE_SETTING, params)
}

/**
 * 获取跟单合约自动追加保证金
 */
interface GetFollowOrderSettingParams {
  symbol_ids?: string
  exchange_id: string | number
}
export function getFollowOrderSetting(data: GetFollowOrderSettingParams) {
  return postCaptured(GET_FOLLOW_ORDER_SETTING, data)
}

/**
 * 修改跟单合约自动追加保证金
 */
interface SetFollowOrderSettingParams {
  is_auto_add_margin: boolean
}
export function setFollowOrderSetting(data: SetFollowOrderSettingParams) {
  return postCaptured(SET_FOLLOW_ORDER_SETTING, data)
}

// -------------------------- 合约计算器 ---------------------------
interface GetCalculatorProfitInfoParams {
  symbol_id: string
  is_long: FuturePositionSide
  leverage: string
  open_price: string
  open_quantity: string
  is_cross?: PositionMode
  account_asset?: string
  close_price?: string
  close_quantity?: any
  margin_call?: string
  query_type?: CalculatorFeeType
}
/**
 * 计算收益率
 */
export function getCalculatorProfitInfo(params: GetCalculatorProfitInfoParams) {
  return getCaptured(GET_CALCULATOR_PROFIT_INFO, params)
}
/**
 * 计算收益率
 */
export function getCalculatorLiquidationPrice(params: GetCalculatorProfitInfoParams) {
  return getCaptured(GET_CALCULATOR_LIQUIDATION_PRICE, params)
}

// ----- 一键平仓
export interface GetFutureClosePromptPositionParams {
  exchange_id: number | string
  symbol_id?: string
}
export function getFutureClosePromptPosition(params: GetFutureClosePromptPositionParams) {
  return postCaptured(GET_FUTURE_CLOSE_PROMPT_POSITION, params)
}

/**
 * 增加/减少保证金
 */
interface ModifyMarginParams {
  symbol_id: number | string
  type: string
  amount: number | string
  is_long: FuturePositionSide
  position_index: number
}
/**
 * 合约订单-增加/减少保证金
 */
export function modifyMargin(data: ModifyMarginParams) {
  return postCaptured(MODIFY_MARGIN, data)
}
/**
 * 跟单订单-增加/减少保证金
 */
export function modifyFollowMargin(data: ModifyMarginParams) {
  return postCaptured(MODIFY_FOLLOW_MARGIN, data)
}

/**
 * 读取 永续合约下单配置项，费率，风险限额，杠杠
 */
interface GetOrderSettingParams {
  symbol_ids?: string
  exchange_id: string | number
}
export function getOrderSetting(data: GetOrderSettingParams) {
  return postCaptured(GET_ORDER_SETTING, data)
}

/**
 * 修改杠杆
 */
interface UpdateLeverParams {
  symbol_id?: string
  leverage: string | number
  /**
   * 多仓1 空仓2
   */
  is_long: 1 | 2
}
export function updateLever(data: UpdateLeverParams) {
  return postCaptured(UPDATE_LEVER, data)
}

interface GetUserLeverageParams {
  symbol_id: string
}

export function getUserLeverage(params: GetUserLeverageParams) {
  return getCaptured(GET_LEVERAGE, params)
}

/**
 * 设置止盈止损
 */
export interface SetStopProfitLossParams {
  symbol_id?: string
  exchange_id: string | number
  is_long: FuturePositionSide
  stop_profit_price?: string
  sp_trigger_condition_type?: number
  sp_close_type?: number
  stop_loss_price?: string
  sl_trigger_condition_type?: number
  sl_close_type?: number
  position_index: number
}
export function setStopProfitLoss(data: SetStopProfitLossParams) {
  return postCaptured(SET_STOP_PROFIT_LOSS, data)
}

/**
 * 取消止盈止损
 */
interface CancelStopProfitLossParams {
  symbol_id?: string
  exchange_id: string | number
  is_long: FuturePositionSide
  position_index: number
}
export function cancelStopProfitLoss(data: CancelStopProfitLossParams) {
  return postCaptured(CANCEL_STOP_PROFIT_LOSS, data)
}

export interface SetFutureOrderSettingParams {
  symbol_id?: string
  is_confirm?: boolean
  is_auto_add_margin?: boolean
  time_in_force?: FutureTimeInForce
}

export function setFutureOrderSetting(data: SetFutureOrderSettingParams) {
  return postCaptured(SET_ORDER_SETTING, data)
}

export interface CreateOrderParams {
  side: FutureOrderType
  type: 'LIMIT' | 'STOP' | 'STOP_FOLLOW'
  price: string
  price_type: FutureOrderPriceType
  callback_type?: TrackCallbackType
  callback_value?: string
  trigger_price?: string
  leverage?: string | number
  quantity: string | number
  symbol_id: string
  client_order_id: number
  exchange_id: string | number
  order_side?: OrderSide
  time_in_force: FutureTimeInForce
  is_long?: FuturePositionSide
  stop_profit_price?: string
  stop_loss_price?: string
  sp_trigger_condition_type?: FutureStopConditionType
  sl_trigger_condition_type?: FutureStopConditionType
  stop_status?: FutureStopStatus
  position_index?: number
  trigger_condition_type?: FutureStopConditionType
}

/**
 * 下单
 */
export function createExchangeOrder(params: CreateOrderParams) {
  return postCaptured(ORDER_CREATE, params)
}

/**
 * 跟单平仓-参数和下单一致
 */
export function createFollowOrder(params: CreateOrderParams) {
  return postCaptured(FOLLOW_ORDER_CREATE, params)
}

export interface TransferUserAssetParams {
  token_id: string
  amount: string
  from_account_type: SubAccountType
  from_account_index: number
  from_account_id: string
  to_account_type: SubAccountType
  to_account_index: number
  to_account_id: string
}

export function transferUserAssets(data: TransferUserAssetParams) {
  return postCaptured(TRANSFER_USER_ASSETS, data)
}

export interface GetEntrustParams {
  type?: FutureOrderListType
  limit: number
  from_order_id?: string
  end_order_id?: string
}

export function getHistoryStopLossEntrust(params: GetEntrustParams) {
  return getCaptured(HISTORY_STOP_LOSS_ENTRUST, params)
}

export function getCurrentStopLossEntrust(params: GetEntrustParams) {
  return getCaptured(CURRENT_STOP_LOSS_ENTRUST, params)
}
export interface GetPositionListParams {
  from_position_id?: string | number
  end_position_id?: string | number
  limit: number
  symbol_id?: string
}

export function getCurrentPositionList(params: GetPositionListParams) {
  return getCaptured(CURRENT_POSITION_LIST, params)
}

export function getCurrentEntrustList(params: GetEntrustParams) {
  return getCaptured(CURRENT_ENTRUST_LIST, params)
}

export function getHistoryEntrustList(params: GetEntrustParams) {
  return getCaptured(HISTORY_ENTRUST_LIST, params)
}
export interface GetHistoryTradeParams {
  from_trade_id?: string
  end_trade_id?: string
  limit: number
}

export function getHistoryTradeList(params: GetHistoryTradeParams) {
  return getCaptured(HISTORY_TRADE_LIST, params)
}

export interface CancelEntrustParams {
  order_id: string
  client_order_id: number
  type: FutureOrderListType
}

export function cancelEntrust(params: CancelEntrustParams) {
  return postCaptured(CANCEL_ENTRUST, params)
}

export interface GetEntrustMatchInfoParams {
  order_id: string
  limit: number
}
export function getEntrustMatchInfo(params: GetEntrustMatchInfoParams) {
  return getCaptured(ENTRUST_MATCH_INFO, params)
}

export function getStopLossInfo() {
  return getCaptured(GET_STOP_LOSS_INFO)
}

export function getFutureOrderInfo(params: { order_id: string }) {
  return getCaptured(GET_ORDER_INFO, params)
}

export function getWelfareListUnlogin() {
  return getCaptured(GET_WELFARE_UNLOGIN_DATA)
}

// ---------------------- 登录注册 -----------------------------

/**
 * 获取邮箱前缀列表
 */
export function getEmailAutoSuffix() {
  return getCaptured(GET_EMAIL_AUTO_SUFFIX)
}
/**
 * 国家列表
 */
export function getBasicCountries() {
  return getCaptured(GET_BASIC_COUNTRIES)
}
/**
 * 注册极验--v3版本 弃用
 */
export function registGeetest(data: { captcha_id: string }) {
  return postCaptured(REGIST_GEETEST, data)
}

/**
 * 登录参数
 */
interface LoginParams {
  password?: string
  username?: string
  type?: number
  captcha_id?: string
  /**
   * 快捷登录
   */
  mobile?: string
  login_type?: string
  order_id?: string
  national_code?: string
  verify_code?: number | string
}

/**
 * 账号密码登录1
 */
export function userAuth(data: LoginParams) {
  return postCaptured(USER_AUTHORIZE, data)
}

/**
 * 快捷登录
 */
export function quickAuth(data: LoginParams) {
  return postCaptured(QUICK_AUTHORIZE, data)
}

/**
 * 未登录发送手机验证码
 */
export function sendCommonSmsVerifyCode(data: SendCommonSmsVerifyCode) {
  return postCaptured(SEND_COMMON_SMS_VERIFY_CODE, data)
}
/**
 * 未登录发送邮箱验证码
 */
export function sendCommonEmailVerifyCode(data: any) {
  return postCaptured(SEND_COMMON_EMAIL_VERIFY_CODE, data)
}

/**
 * 登录时，二次验证邮箱验证码
 */
export function sendEmailAuthVerifyCode(data: any) {
  return postCaptured(SEND_EMAIL_AUTH_VERIFY_CODE, data)
}
/**
 * 登录时，二次手机验证码
 */
export function sendMobileAuthVerifyCode(data: any) {
  return postCaptured(SEND_MOBILE_AUTH_VERIFY_CODE, data)
}

/**
 * 账号登录第二步
 */
export function loginStep2Api(data: any) {
  return postCaptured(LOGIN_STEP2, data)
}

/**
 * 快捷登录第二步
 */
export function quickAuthorizeAdvance(data: any) {
  return postCaptured(QUICK_AUTHORIZE_ADVANCE, data)
}

/**
 * 获取登录二维码
 */
export function getLoginQrcode() {
  return getCaptured(GET_LOGIN_QRCODE)
}

/**
 * 获取登录二维码状态
 */
export function getLoginQrcodeStatus(params: { ticket: string }) {
  return getCaptured(GET_LOGIN_QRCODE_STATUS, params)
}

/**
 * 注册参数
 */
interface RegisterParamType {
  verify_code: string
  password1: string
  password2: string
  invite_code: string
  type: RegisterTab
  order_id: string
  email?: string
  national_code?: string
  mobile?: string
}

/**
 * 邮箱注册
 */
export function emailRegister(data: RegisterParamType) {
  return postCaptured(EMAIL_REGISTER, data)
}

/**
 * 手机注册
 */
export function mobileRegister(data: RegisterParamType) {
  return postCaptured(MOBILE_REGISTER, data)
}

/**
 * 手机找回密码
 */
export function mobileFindPassCheck(data: any) {
  return postCaptured(MOBILE_FIND_PWD_CHECK, data)
}

/**
 * 邮箱找回密码
 */
export function emailFindPassCheck(data: any) {
  return postCaptured(EMAIL_FIND_PWD_CHECK, data)
}

/**
 * 找回密码二次验证码(邮箱或手机)
 */
export function findPwd(data: {
  request_id: string
  lot_number: string
  gen_time: string
  captcha_id: string
  captcha_output: string
  pass_token: string
  gee_version: string
}) {
  return postCaptured(FIND_PWD, data)
}

/**
 * 找回密码step2-2FA验证
 */
export function findPwdCheck2(data: {
  request_id: string
  order_id?: string
  verify_code: string
}) {
  return postCaptured(FIND_PWD_CHECK2, data)
}

/**
 * 重置密码
 */
export function resetPassword(data: {
  request_id: string
  password1: string
  password2: string
  email?: string
  mobile?: string
  national_code?: string
}) {
  return postCaptured(RESET_PASSWORD, data)
}

export interface QuickBackhandParams {
  symbol_id: string
  exchange_id: string | number
  position_index: number
  is_long: FuturePositionSide
  quantity: string
}

export function quickBackhand(data: QuickBackhandParams) {
  return postCaptured(QUICK_BACKHAND, data)
}

/**
 * 消息分类已读状态查询
 */
export function getNoticeTypeReadStatus() {
  return getCaptured(GET_NOTICE_TYPE_READ_STATUS)
}

interface GetNoticeDetailParams {
  id: string
  messageId: string
}
/**
 * 获取通知详情
 */
export function getNoticeDetail(params: GetNoticeDetailParams) {
  return getCaptured(GET_NOTICE_DETAIL, params)
}
/**
 * 一键已读
 */
export function readAll() {
  return postCaptured(READ_ALL)
}
// ------------------------ 资产---------------------
/**
 * 资产总览信息
 */
export function getAssetInfo(params: { unit: string }) {
  return getCaptured(GET_ASSET_INFO, params)
}
/**
 * 理财资产列表
 */
export function getStakingAasset() {
  return getCaptured(GET_STAKING_ASSET)
}
/**
 * 策略资产列表
 */
export function getStrategyAssetList() {
  return getCaptured(GET_STRATEGY_ASSET_LIST)
}
/**
 * 获取充币地址信息
 */
export function getDepositAddress(params: {
  token_id: string
  chain_type: string
}) {
  return getCaptured(GET_DEPOSIT_ADDRESS, params)
}
/**
 * 充币记录
 */
export function getDepositOrderList(params: {
  from_order_id: number | string
  account_id: string
  limit: number
  token_id?: string
  start_time?: number
  end_time?: number
}) {
  return getCaptured(GET_DEPOSIT_ORDER_LIST, params)
}
/**
 * 提币记录
 */
export function getWithdrawOrderList(params: {
  from_order_id: number | string
  account_id: string
  limit: number
  token_id?: string
  start_time?: number
  end_time?: number
}) {
  return getCaptured(GET_WITHDRAW_ORDER_LIST, params)
}
/**
 * 取消提币
 */
export function cancelWithdrawOrder(data: { order_id: string }) {
  return postCaptured(CANCEL_WITHDRAW_ORDER, data)
}
/**
 * 合约资产记录
 */
export function getContractBalanceFlow(params: {
  from_order_id: number | string
  account_id: string
  limit: number
  token_id?: string
  start_time?: number
  end_time?: number
  business_subject?: number
  symbolId?: string
}) {
  return getCaptured(GET_CONTRACT_BALANCE_FLOW, params)
}
/**
 * 体验金 抵扣金记录
 */
export function getCouponList(params: {
  from_id: number | string
  account_id: string
  limit: number
  couponType: CouponType
  token_id?: string
  start_time?: number
  end_time?: number
}) {
  return getCaptured(GET_COUPON_LIST, params)
}
/**
 * 理财记录
 */
export function getFinanceRecordList(params: {
  from_order_id: number | string
  account_id: string
  limit: number
  token_id?: string
  start_time?: number
  end_time?: number
}) {
  return getCaptured(GET_FINANCE_RECORD_LIST, params)
}
/**
 * 法币交易记录
 */
export function getOtcBalanceFlow(params: {
  from_order_id: number | string
  account_id: string
  limit: number
  token_id?: string
  start_time?: number
  end_time?: number
}) {
  return getCaptured(GET_OTC_BALANCE_FLOW, params)
}
/**
 * 其他交易记录
 */
export function getOtherOrderList(params: {
  from_order_id: number | string
  account_id: string
  limit: number
  balance_flow_types: number
  token_id?: string
  start_time?: number
  end_time?: number
}) {
  return getCaptured(GET_OTHER_ORDER_LIST, params)
}
/**
 * 登陆后发送邮箱验证码
 */
export function sendEmailVerifyLogined(params: LoginedSendVeifyParams) {
  return postCaptured(SEND_EMAIL_VERIFY_CODE_LOGINED, params)
}
/**
 * 登陆后发送手机验证码
 */
export function sendMobileVerifyLogined(params: LoginedSendVeifyParams) {
  return postCaptured(SEND_MOBILE_VERIFY_CODE_LOGINED, params)
}
/**
 * 增加地址
 */
export function addAddress(params: {
  auth_type: string | number
  verify_code: string | number
  token_id: string
  address: string
  address_ext: string
  remark: string
  order_id: string
  chain_type: string
}) {
  return postCaptured(ADD_ADDRESS, params)
}
/**
 * 删除地址
 */
export function delAddress(params: {
  address_id: string | number
  order_id?: string
}) {
  return postCaptured(DEL_ADDRESS, params)
}
/**
 * 添加的地址列表
 */
export function getWithdrawAddressList(params: {
  token_id: string
  chain_type: string
}) {
  return getCaptured(GET_WITHDRAW_ADDRESS_LIST, params)
}
/**
 * 获取提币相关得币种信息
 */
export function getWithdrawQuotaInfo(params: {
  token_id: string
  chain_type: string
}) {
  return getCaptured(GET_WITHDRAW_QUOTA_INFO, params)
}
/**
 * 提币地址检查
 */
export function withdrawAddressCheck(data: {
  token_id: string
  address_id: string
  address: string
  address_ext: string
  chain_type: string
}) {
  return postCaptured(ADDRESS_CHECK, data)
}

/**
 * 提币WITHDRAW_CREATE
 */
export function withdrawCreate(data: {
  account_id: string
  client_order_id: string
  token_id: string
  address: string
  address_id: string
  /**
   * 用户输入的值
   */
  quantity: string | number
  /**
   * 到账的值
   */
  arrive_quantity: number | string
  /**
   * 用户选择的手续费
   */
  miner_fee: string | number
  verify_code: string
  auth_type: string | number
  trade_password: string | number
  order_id: string
  convert_rate: string | number
  auto_convert: boolean
  address_ext: string
  chain_type: string
  remarks: string
  is_inner: number
  inner_info: string
}) {
  return postCaptured(WITHDRAW_CREATE, data)
}

/**
 * 提币 brefore step2 重新发送验证码
 */
export function sendWithdrawVerifyCode(data: SendSecondWithdrawVerify) {
  return postCaptured(SEND_WITHDRAW_VERIFY_CODE, data)
}

/**
 * 提币审核 step2
 */
export function withdrawVerify(data: any) {
  return postCaptured(WITHDRAW_VERIFY, data)
}

/**
 * ens地址解析
 */
export function getEnsAddress(data: { node: string }) {
  return getCaptured(GET_ENS_ADDRESS, data)
}

/**
 * 内部转账地址获取
 */
export function getInnerDepositAddress(data: {
  token_id: string
  id_type: InnerWithdrawType
  chain_type: string
  id: string
}) {
  return getCaptured(GET_INNER_DEPOSIT_ADDRESS, data)
}

/**
 * 查询OTC T+1 限额数量
 */
export function getOtcLimitTotal() {
  return getCaptured(GET_OTC_LIMIT_TOTAL)
}

interface CancelAllDelegateParams {
  type: FutureOrderListType
}

/**
 * 一键撤单
 */
export function cancelAllDelegate(data: CancelAllDelegateParams) {
  return postCaptured(CANCEL_ALL_DELEGATE, data)
}

/**
 * 添加自选
 */

export function addSpotFavorite(data: {
  symbol_id: string
  exchange_id: string
}) {
  return postCaptured(ADD_SPOT_FAVORITE, data)
}

/**
 * 删除自选
 */
export function deleteSpotFavorite(data: {
  symbol_id: string
  exchange_id: string
}) {
  return postCaptured(CANCEL_SPOT_FAVORITE, data)
}

export interface SpotCreateOrderParams {
  symbol_id: string
  type: SpotOrderType.LIMIT | SpotOrderType.MARKET
  side: OrderSide
  trigger_price?: string
  price?: string
  quantity: string
  client_order_id: number
}

export function createSpotOrder(data: SpotCreateOrderParams) {
  return postCaptured(CREATE_SPOT_ORDER, data)
}

export function createSpotPlanOrder(data: SpotCreateOrderParams) {
  return postCaptured(CREATE_SPOT_PLAN_ORDER, data)
}

export interface SpotOrderParams {
  end_order_id?: string
  from_order_id?: string
  limit: string | number
  symbol_id?: string
}
export function getSpotOrder(params: SpotOrderParams) {
  return getCaptured(GET_SPOT_ORDER_LIST, params)
}

export function getSpotHistoryOrder(params: SpotOrderParams) {
  return getCaptured(GET_SPOT_HISTORY_ORDER_LIST, params)
}
export function getSpotPlanOrder(params: SpotOrderParams) {
  return getCaptured(GET_SPOT_PLAN_ORDER_LIST, params)
}
export function getSpotPlanHistoryOrder(params: SpotOrderParams) {
  return getCaptured(GET_SPOT_PLAN_HISTORY_LIST, params)
}
export function getSpotPlanOrderDetail(params: { order_id: string }) {
  return postCaptured(GET_SPOT_PLAN_ORDER_DETAIL, params)
}
export function getSpotOrderDetail(params: { order_id: string }) {
  return getCaptured(GET_SPOT_ORDER_DETAIL, params)
}
export function cancelSpotOrder(params: {
  order_id: string
  client_order_id: number
}) {
  return postCaptured(CANCEL_SPOT_ORDER, params)
}

export function cancelSpotPlanOrder(params: {
  order_id: string
  client_order_id: number
}) {
  return postCaptured(CANCEL_SPOT_PLAN_ORDER, params)
}
export interface SpotTradeParams {
  end_trade_id?: string
  from_trade_id?: string
  limit: string | number
  symbol_id?: string
}
export function getSpotHistoryTrade(params: SpotTradeParams) {
  return getCaptured(GET_SPOT_HISTORY_TRADE_LIST, params)
}

interface CancelAllDelegateParams {
  type: FutureOrderListType
}

// ------------------------ 世界杯
// 获取世界杯背景
export function getWorldCupBg() {
  return getCaptured(GET_WORLD_CUP_BG, {
    custom_keys: 'cust.worldPlaySettings'
  })
}
// 查看用户信息
export function getWorldCupUserInfo() {
  return getCaptured(GET_WORLD_CUP_USER_INFO)
}
// 查看比赛日期
export function getWorldCupPlayDate() {
  return getCaptured(GET_WORLD_CUP_PLAY_DATE)
}
// 查看某日世界杯比赛信息
export function getWorldCupDatePlays(params: { time: string }) {
  return getCaptured(GET_WORLD_CUP_USER_PLAY, params)
}
// 参与世界杯竞猜
export function worldCupGuess(data: {
  playId: number
  guess: number // 2 3 4
}) {
  return postCaptured(WORLD_CUP_GUESS, data)
}
// 用户竞猜记录
export function getWorldCupGuessHistory() {
  return getCaptured(GET_WORLD_CUP_GUESS_HISTORY)
}
// 抽奖
export function worldCupLuckDraw() {
  return postCaptured(WORLD_CUP_LUCK_DRAW)
}
// 购买竞猜
export function worldCupBuyGuess(data: { quantity: any }) {
  return postCaptured(WORLD_CUP_BUY_GUESS, data)
}
// 查看转盘奖品
export function getWorldCupPrizeList() {
  return getCaptured(GET_WORLD_CUP_PRIZE_LIST)
}
// 查看我的奖品
export function getWorldCupUserPrize() {
  return getCaptured(GET_WORLD_CUP_USER_PRIZE)
}
// 滚动播放的中奖信息
export function getWorldCupPrizeScroll() {
  return getCaptured(GET_WORLD_CUP_PRIZE_SCROLL)
}
// 查询活动配置
export function getWorldCupConfig() {
  return getCaptured(GET_WORLD_CUP_CONFIG)
}

export interface GetMultipleKlineParams {
  exchangeId: string
  symbol: string
  interval: ResolutionValues
  limit: number
  realtimeInterval: string
}
/**
 * 获取多币对k线
 */
export function getMultipleKline(params: GetMultipleKlineParams) {
  return getCaptured(GET_MULTIPLE_KLINE, params)
}
/**
 * 获取自选列表
 */
export function getFavoriteList() {
  return getCaptured(GET_FAVORITE_LIST)
}

/**
 * 取消所有现货订单
 */
export function cancelAllSpotOrder() {
  return postCaptured(CANCEL_ALL_SPOT_ORDER)
}

/**
 * 取消所有现货计划委托
 */
export function cancelAllSpotPlanOrder() {
  return postCaptured(CANCEL_ALL_SPOT_PLAN_ORDER)
}

/**
 * 获取奖励中心用户所有奖励
 */
export function getAllAward() {
  return getCaptured(GET_ALL_AWARD)
}

/**
 * 获取弹窗广告
 */
export function getPopupAd() {
  return getCaptured(GET_POPUP_AD)
}

// --------------------------- 用户中心---------------
/**
 * 用户kyc级别信息
 */
export function getUserKycLevelInfo() {
  return getCaptured(GET_USER_KYC_LEVEL_INFO)
}
/**
 * 登录历史
 */
export function getLoginHistory(data: {
  log_id: string | number
  limit: number
}) {
  return postCaptured(GET_LOGIN_HISTORY, data)
}

/**
 * 操作记录
 */
export function getActionHistory(data: {
  lastId: string | number
  pageSize: number
}) {
  return postCaptured(GET_ACTION_HISTORY, data)
}

/**
 * 获取持仓模式
 */
export function getPositionMode(params: { symbol_id: string }) {
  return getCaptured(GET_POSITION_MODE, params)
}
/**
 * 设置持仓模式
 */
export function setPositionMode(data: {
  symbol_id: string
  is_cross: PositionMode
}) {
  return postCaptured(SET_POSITION_MODE, data)
}
/**
 * 绑定邮箱
 */
export function bindEmail(data: {
  email: string
  email_order_id: string
  email_verify_code: string
  verify_code: string
  order_id?: string
}) {
  return postCaptured(BIND_EMAIL, data)
}
/**
 * 此接口用于用户**在登陆状态**并且系统已知用户的手机号或者邮箱的情况下请求发送验证码
 */
export function sendVerifyCode(data: {
  receiver_type: string | number
  type: string | number
  lot_number: string
  gen_time: string
  captcha_id: string
  captcha_output: string
  pass_token: string
  gee_version: string
}) {
  return postCaptured(SEND_VERIFY_CODE_NO_ACCOUNT, data)
}
/**
 * 发送验证码
 */
export function sendCommonVerifyCode(data: {
  receiver_type: string | number
  type: string | number
  email?: string
  national_code?: string
  mobile?: string
  lot_number: string
  gen_time: string
  captcha_id: string
  captcha_output: string
  pass_token: string
  gee_version: string
}) {
  return postCaptured(SEND_COMMON_VERIFY_CODE, data)
}
/**
 * 更换邮箱
 */
export function changeBindEmail(data: {
  original_email_order_id: string
  original_email_verify_code: string
  mobile_order_id: string
  mobile_verify_code: string
  email: string
  alter_email_order_id: string
  alter_email_verify_code: string
  ga_verify_code: string
}) {
  return postCaptured(CHANGE_BIND_EMAIL, data)
}
/**
 * 绑定手机
 */
export function bindMobile(data: {
  national_code: string
  mobile: string
  mobile_order_id: string
  mobile_verify_code: string
  verify_code: string
  order_id: string
}) {
  return postCaptured(BIND_MOBILE, data)
}
/**
 * 更换手机
 */
export function changeBindMobile(data: {
  original_mobile_order_id: string
  original_mobile_verify_code: string
  email_order_id: string
  email_verify_code: string
  national_code: string
  mobile: string
  alter_mobile_order_id: string
  alter_mobile_verify_code: string
  ga_verify_code: string
}) {
  return postCaptured(CHANGE_BIND_MOBILE, data)
}
/**
 * 获取GA绑定前数据
 */
export function getGaInfo() {
  return postCaptured(GET_GA_INFO)
}
/**
 * 绑定GA
 */
export function bindGA(data: {
  ga_code: string
  order_id: string
  verify_code: string
}) {
  return postCaptured(BIND_GA, data)
}
/**
 * 获取换绑ga数据
 */
export function getAlterGaInfo() {
  return postCaptured(GET_ALTER_GA_INFO)
}
/**
 * 更换GA
 */
export function changeBindGA(data: {
  original_ga_code: string
  order_id: string
  verify_code: string
  alter_ga_code: string
}) {
  return postCaptured(CHANGE_BIND_GA, data)
}
/**
 * 重置登录密码
 */
export function resetLoginPassword(data: {
  old_password: string
  password1: string
  password2: string
}) {
  return postCaptured(RESET_LOGIN_PASSWORD, data)
}
/**
 * 设置资金密码
 */
export function setFundPassword(data: {
  type: EditFundPassType
  order_id: string
  verify_code: string
  trade_pwd: string
  trade_pwd2: string
}) {
  return postCaptured(SET_FUND_PASSWORD, data)
}
/**
 * 设置防钓鱼码
 */
export function setFishCode(data: {
  auth_type: string | number
  order_id: string
  verify_code: string
  anti_phishing_code: string
}) {
  return postCaptured(SET_FISH_CODE, data)
}
/**
 * 获取实名认证信息
 */
export function getVerifyInfo() {
  return postCaptured(GET_VERIFY_INFO)
}
/**
 * KYC1 认证
 */
export function kyc1Verify(data: {
  birthday: string
  country_code: string
  name?: string
  first_name?: string
  second_name?: string
  card_type: string | number
  card_no: string
  verify_type?: number
  card_front_url?: string
  card_hand_url?: string
  card_back_url?: string
}) {
  return postCaptured(KYC_1_VERIFY, data)
}

/**
 * KYC2 认证
 */
export function kyc2Verify(data: {
  card_front_url: string
  card_hand_url: string
  card_back_url: string
}) {
  return postCaptured(KYC_2_VERIFY, data)
}
/**
 * 验证邮箱是否是临时接口，是否合规
 */
export function validCommonEmailDomain(params: { email: string }) {
  return getCaptured(COMMON_EMAIL_VALID_DOMAIN, params)
}
/**
 * 消息中心-获取 站内信消息开关设置
 */
export function getUserPushSetting() {
  return getCaptured(GET_USER_PUSH_SETTING)
}

/**
 * 消息中心-设置 站内信消息开关设置
 */
export function setUserPushSetting(data: { switchMap: string }) {
  return postCaptured(SET_USER_PUSH_SETTING, data)
}

/**
 * 获取币对信息
 */
export function getTokenInfo(params: { token_id: string }) {
  return getCaptured(GET_TOKEN_INFO, params)
}

interface StopProfitLossWithQuantityParams {
  exchange_id: number | string
  symbol_id: string
  is_long: string
  stop_profit_loss_price: string
  spl_trigger_condition_type: FutureStopConditionType
  quantity: number
}

/**
 * 分批止盈止损
 */
export function stopProfitLossWithQuantity(data: StopProfitLossWithQuantityParams) {
  return postCaptured(SET_STOP_PROFIT_LOSS_WITH_QUANTITY, data)
}
/**
 * 编辑简介
 */
export function editIntroduce(data: { describe: string }) {
  return postCaptured(EDIT_INTRODUCE, data)
}
/**
 * 查询带单人基础信息
 */
export function getTakeBaseInfo(params: { user_id: string }) {
  return getCaptured(GET_TAKE_BASE_INFO, params)
}
/**
 * 查询带单人交易数据
 */
export function getTakeTradeData(params: { user_id: string }) {
  return getCaptured(GET_TAKE_TRADE_INFO, params)
}
/**
 * 查询带单人主页设置
 */
export function getTakeHomeSetting(params: {
  other_user_id: string
  user_id: string
}) {
  return getCaptured(GET_TAKE_HOME_SETTING, params)
}

export interface SetTakeHomeSettingParams {
  trade_open?: TakeUserSettingStatus
  position_open?: TakeUserSettingStatus
  history_open?: TakeUserSettingStatus
  follow_user_open?: TakeUserSettingStatus
}
/**
 * 设置带单人主页设置
 */
export function setTakeHomeSetting(data: SetTakeHomeSettingParams) {
  return postCaptured(SET_TAKE_HOME_SETTING, data)
}
/**
 * 查询带单人历史带单
 */
export function getTakeHistoryOrder(params: GetEntrustParams & { user_id: string }) {
  return getCaptured(GET_TAKE_HISTORY, params)
}
/**
 * 查询带单人当前带单
 */
export function getTakeCurrentOrder(params: GetPositionListParams & { user_id: string }) {
  return getCaptured(GET_TAKE_CURRENT, params)
}
/**
 * 文案配置接口-邀请页面
 */
export function getCommonLangConfig() {
  return postCaptured(GET_COMMON_LANG_CONFIG)
}
/**
 * 邀请分享信息
 */
export function getInviteShareTemplateInfo() {
  return postCaptured(GET_INVITE_SHARE_TEMPLATE_INFO)
}
/**
 * 返佣记录
 */
export function getInvitationBonusList(data: {
  beforeId: String
  limit: number
}) {
  return postCaptured(GET_INVITATION_BONUS_LIST, data)
}
/**
 * 邀请明细
 */
export function getInvitationRelationList() {
  return postCaptured(GET_INVITATION_RELATION_LIST)
}

/**
 * 获取用户分享信息
 */
export async function getUserPosterInfo(params: { user_id: string }) {
  return postCaptured(GET_SHARE_POSTER_DATA, params)
}
/**
 * 访问带单人主页时记录
 */
export function visitUserTake(data: {
  take_user_id: string
  invite_user_id: string
}) {
  return postCaptured(VISIT_USER_TAKE, data)
}

/**
 * 获取全局通知
 */
export function getGlobalNotice() {
  return getCaptured(GET_GLOBAL_NOTICE)
}
/**
 * 跟单推荐总览
 */
export function getFollowRecommendOverview() {
  return getCaptured(GET_FOLLOW_RECOMMEND_OVERVIEW)
}
/**
 * 跟单推荐奖励明细
 */
export function getFollowRecommendRewardList(params: {
  page: number
  pageSize: number
}) {
  return getCaptured(GET_FOLLOW_RECOMMEND_REWARD_LIST, params)
}
/**
 * 跟单推荐明细
 */
export function getFollowRecommendRecordList(params: {
  page: number
  pageSize: number
}) {
  return getCaptured(GET_FOLLOW_RECOMMEND_RECORD_LIST, params)
}

/**
 * 查询是否是官方渠道
 */
export function getOfficialChannel(params: { content: string }) {
  return getCaptured(GET_OFFICIAL_CHANNEL, params)
}
/**
 * 查询是否是官方渠道logo
 */
export function getOfficialChannelLogo() {
  return getCaptured(GET_OFFICIAL_CHANNEL_LOGO)
}

// ------------------ 大转盘 --------------------------
/**
 * 查询转盘奖品列表
 */
export function getTurntablePrizeList(params: {
  turntable_activity_info_id: string | number
}) {
  return getCaptured(GET_TURNTABLE_PRIZE_LIST, params)
}
/**
 * 查询转盘轮播中奖记录
 */
export function getTurntableRewordList(params: {
  turntable_activity_info_id: string | number
}) {
  return getCaptured(GET_TURNTABLE_REWORD_LIST, params)
}
/**
 * 查询转盘抽奖基础信息
 */
export function getTurntableActivityInfo(params: {
  turntable_activity_info_id: string | number
}) {
  return getCaptured(GET_TURNTABLE_ACTIVITY_INFO, params)
}
/**
 * 查询我的中奖记录
 */
export function getTurntableUserRewordList(params: {
  turntable_activity_info_id: string | number
  page: number
  page_size: number
}) {
  return getCaptured(GET_TURNTABLE_USER_REWORD_LIST, params)
}
/**
 * 查询转盘活动用户邀请记录
 */
export function getTurntableInviteList(params: {
  turntable_activity_info_id: string | number
  page: number
  page_size: number
}) {
  return getCaptured(GET_TURNTABLE_INVITE_LIST, params)
}
/**
 * 抽奖
 */
export function turntableDraw(params: {
  turntable_activity_info_id: string | number
}) {
  return getCaptured(TURNTABLE_DRAW, params)
}

/**
 * 开通合约
 */
export function openFuture() {
  return getCaptured(OPEN_FUTURE)
}

interface GetPriceAlarmListParams {
  symbolId?: string
  /**
   * 1 币币 2 合约
   */
  symbolType?: string
}
/**
 * 查询价格提醒列表
 */
export function getPriceAlarmList(params?: GetPriceAlarmListParams) {
  return getCaptured(GET_PRICE_ALARM_LIST, params)
}

export type AddPriceAlarmParams = Pick<PriceAlarm, 'exchangeId' | 'symbolId' | 'symbolType' | 'currentPrice' | 'reminderType' | 'reminderValue' | 'frequency' >
/**
 * 添加价格提醒
 */
export function addPriceAlarm(data: AddPriceAlarmParams) {
  return postCaptured(ADD_PRICE_ALARM, data)
}
/**
 * 删除价格提醒
 */
export function removePriceAlarm(params: { id: string }) {
  return postCaptured(DELETE_PRICE_ALARM, params)
}
/**
 * 查询合仓分仓
 */
export function getPositionMergeMode(params: { symbol_id: string }) {
  return getCaptured(GET_POSITION_MERGE_MODE, params)
}
/**
 * 设置合仓分仓
 */
export function setPositionMergeMode(data: { symbol_id: string, merge_mode: PositionMergeMode }) {
  return postCaptured(SET_POSITION_MERGE_MODE, data)
}
/**
 * 检查ip
 */
export function checkIp(params: {
  ip?: string
  stamp: number
}) {
  return getCaptured(CHECK_IP, params)
}
/**
 * 获取用户跟单账户合约下单设置
 */
export function getFollowOrderCreateSetting(params: {
  symbol_ids: string
  exchange_id: number | string
}) {
  return getCaptured(GET_FOLLOW_ORDER_CREATE_SETTING, params)
}

/**
 * 获取用户跟单账户可交易资产
 */
export function getFollowTradeable(params: {
  token_ids: string
  exchange_id: number | string
}) {
  return getCaptured(GET_FOLLOW_TRADEABLE, params)
}

/**
 * 修改策略订单状态
 */
export function changeStrategyOrderStatus(data: { id: string, status: StrategyOrderProcessStatus }) {
  return postCaptured(CHANGE_STRATEGY_ORDER_STATUS, data)
}

/**
 * 创建策略订单
 */
export function createStrategyOrder(data: CreateStrategyParams<CreateGridParams> | CreateStrategyParams<CreateMartinParams>) {
  return postCaptured(CREATE_STRATEGY_ORDER, data)
}

/**
 * 查询策略订单
 */
export function getStrategyOrderList(params: { tactics_type: StrategyType, status: StrategyOrderTabType }) {
  return getCaptured(GET_STRATEGY_ORDER_LIST, params)
}

/**
 * 获取策略下单最大杠杆
 */
export function getStrategyMaxLeverage() {
  return getCaptured(GET_STRATEGY_MAX_LEVERAGE)
}

/**
 * 获取AI填充参数
 */
export function getStrategyAIParams(params: { symbol_id: string, tactics_type?: StrategyType, side: StrategySide }) {
  return getCaptured(GET_STRATEGY_AI_PARAMS, params)
}

/**
 * 获取价格基准
 */
export function getUserPriceBase() {
  return getCaptured(GET_USER_PRICE_BASE)
}

/**
 * 设置价格基准
 */
interface EditUserPriceBaseParams {
  type: FuturePriceBase
}
export function editUserPriceBase(data: EditUserPriceBaseParams) {
  return postCaptured(EDIT_USER_PRICE_BASE, data)
}

/**
 * 获取IEO列表
 */
export function getIeoProjectList(params: {
  limit: number
  fromId: string
  endId: string
}) {
  return getCaptured(GET_IEO_PROJECT_LIST, params)
}

/**
 * ieo详情
 */
export function getIeoBasicInfo(params: {
  projectCode: string
}) {
  return getCaptured(GET_IEO_ITEM_DETAIL, params)
}

/**
 * 下单
 */
interface CreateIEOOrderParams {
  lot_number: string
  gen_time: string
  captcha_id: string
  captcha_output: string
  pass_token: string
  gee_version: string
  clientOrderId: string
  amount?: string | number
  projectId: string
  projectCode: string
}
export function createIeoOrder(data: CreateIEOOrderParams) {
  return postCaptured(CREATE_IEO_NEW_ORDER, data)
}

/**
 * ieo下单列表
 */
export function getIeoOrderList(params: {
  limit: number
  fromId: string
  endId: string
}) {
  return getCaptured(GET_IEO_ORDER_LIST, params)
}

/**
 * 获取指数配置
 */
export function getIndexFormula(data: { name: string }) {
  return getCaptured(GET_INDEX_FORMULA, data)
}
export interface GetRatesHistoryParams {
  symbol_id: string
  limit: number
  from_id?: string
  end_id?: string
}

/**
 * 获取资金费率列表
 */
export function getRatesHistory(params: GetRatesHistoryParams) {
  return getCaptured(GET_RATES_HISTORY, params)
}
/**
 * 查询支持的汇率
 */
export function getSupportUnit() {
  return getCaptured(GET_BASIC_UNIT)
}
/**
 * 合约流水类型
 */
export function getTurnoverType() {
  return getCaptured(GET_TURNOVER_TYPE)
}

/**
 * 常规邀请基础信息
 */
export function getCustomInviteInfo() {
  return getCaptured(CUSTOM_INVITE_INFO)
}
/**
 * 常规邀请轮播信息
 */
export function getCustomInviteCarousel() {
  return getCaptured(CUSTOM_INVITE_CAROUSEL)
}
/**
 * 常规邀请-邀请明细
 */
export function getCustomInviteList(params: {
  pageNo: number
  pageSize: number
}) {
  return getCaptured(CUSTOM_INVITE_LIST, params)
}
/**
 * 常规邀请-收益明细
 */
export function getCustomAwardList(params: {
  pageNo: number
  pageSize: number
}) {
  return getCaptured(CUSTOM_INVITE_AWARD_LIST, params)
}

/**
 * 限时邀请-进行中限时邀请配置
 */
export function getTopInviteActivityInfo(params: {
  id: number | string
}) {
  return getCaptured(GET_TOP_INVITE_ACTIVITY_INFO, params)
}

/**
 * 限时邀请-邀请用户加入团队
 */
export function joinTopInviteTeam(data: {
  invite_user_id: number | string
  time_invite_activity_id: number | string
}) {
  return postCaptured(JOIN_TOP_INVITE_TEAM, data)
}

/**
 * 限时邀请-查询显示邀请团队信息
 */
export function getTopInviteActivityTeamInfo(params: {
  time_invite_activity_id: number | string
}) {
  return getCaptured(GET_TOP_INVITE_ACTIVITY_TEAM_INFO, params)
}

/**
 * 限时邀请-查询轮播数据
 */
export function getTopInviteAwardCarousel() {
  return getCaptured(GET_TOP_INVITE_AWARD_CAROUSEL)
}

/**
 * 学习中心文章详情
 */
export function getGuideArticleDetail(params: {
  articleBaseId: string
}) {
  return getCaptured(GET_GUIDE_ARTICLE_DETAIL, params)
}
/**
 * 学习中心配置
 */
export function getGuideConfig() {
  return getCaptured(GET_GUIDE_HOME_CONFIG)
}

/**
 * 获取文章预览信息
 */
export function getGuideArticlePreview(params: {
  articleBaseId: string
  language: string
}) {
  return getCaptured(GET_GUIDE_ARTICLE_PREVIEW, params)
}

/**
 * 圣诞节抽奖
 */
interface ChristmasLotteryParams {
  type: ChristmasLotteryType
}
export function christmasLottery(data: ChristmasLotteryParams) {
  return postCaptured(CHRISTMAS_LOTTERY, data)
}
/**
 * 获取圣诞活动用户活动信息
 */
export function getChristmasUserInfo() {
  return getCaptured(GET_CHRISTMAS_USER_INFO)
}

/**
 * 获取热门币种配置
 */
export function getHotSymbol() {
  return getCaptured(GET_HOT_SYMBOL)
}

/**
 * 上币申请
 */
export function applyToken(data: {
  projectName: string
  tokenFullName: string
  tokenName: string
  email: string
}) {
  return postCaptured(APPLY_TOKEN, data)
}
/**
 * 仓位历史记录
 */

export function getPositionHistory(params: {
  symbol_id?: string
  start_time?: string
  end_time?: string
  date_type?: PositionHistoryTimeType
}) {
  return getCaptured(GET_POSITION_HISTORY, params)
}

/**
 * 充值赠金活动信息查询
 */
export function getRechargeBonusActivityInfo(params: {
  activityId: number | string
}) {
  return getCaptured(GET_RECHARGE_BONUS_ACTIVITY_INFO, params)
}

/**
 * 充值赠金盈亏记录列表查询
 */
export function getRechargeBonusProfitLossList(params: {
  taskId: number | string
  pageSize?: number
  fromFlowId?: number
  endFlowId?: number
}) {
  return getCaptured(GET_RECHARGE_BONUS_TRADING_PROFIT_LOSS, params)
}

/**
 * 充值赠金-赠金领取
 */
export function getRechargeBonus(params: {
  activityId: number | string
}) {
  return getCaptured(GET_RECHARGE_BONUS, params)
}

/**
 * 查询币币盈亏汇总数据-盈亏分析
 */
export function getCoinProfitLossSummary(params: {
  token_id: string
}) {
  return getCaptured(GET_COIN_PROFIT_LOSS_SUMMARY, params)
}

/**
 * 查询币币盈亏汇总数据-盈亏分析
 */
export function getCoinProfitLossDaysList(params: {
  date_type?: DurationType
  start_time: number
  end_time: number
  token_id: string
}) {
  return getCaptured(GET_COIN_PROFIT_LOSS_DAYS_INFO, params)
}

/**
 * 查询币币盈亏分析开始时间-盈亏分析
 */
export function getCoinProfitLossStartTime() {
  return getCaptured(GET_COIN_PROFIT_LOSS_START_TIME)
}

/** 获取现货持仓成本信息 */
export function getExchangeProfitInfo() {
  return getCaptured(GET_EXCHANGE_PROFIT_INFO)
}

/**
 * 获取可用赠金信息
 */
export function getFutureBonusInfo() {
  return getCaptured(GET_AVAILABLE_BONUS)
}
/** 查询赠金交易杠杆 */
export function getFutureBonusLeverage(params: { symbol_id: string }) {
  return getCaptured(GET_BONUS_EXCHANGE_LEVERAGE, params)
}
/** 修改赠金交易杠杆 */
export function changeFutureBonusLeverage(data: {
  symbol_id: string
  leverage: number
  /**
   * 多仓1 空仓2
   */
  is_long: 1 | 2
}
) {
  return postCaptured(EDIT_BONUS_EXCHANGE_LEVERAGE, data)
}
/** 赠金交易下单 */
export function createBonusOrder(data: CreateOrderParams & { grant_gold_coupon_id?: string }) {
  return postCaptured(CREATE_BONUS_ORDER, data)
}
/** 赠金持仓列表 */
export function getBonusPositionList(params: GetPositionListParams) {
  return getCaptured(GET_BONUS_POSITION, params)
}
/** 赠金当前委托 */
export function getBonusCurrentEntrustList(params: GetEntrustParams) {
  return getCaptured(GET_BONUS_CURRENT_ORDER, params)
}
/** 赠金历史委托 */
export function getBonusHistoryEntrustList(params: GetEntrustParams) {
  return getCaptured(GET_BONUS_HISTORY_ORDER, params)
}
/** 赠金历史成交 */
export function getBonusHistoryTradeList(params: GetEntrustParams) {
  return getCaptured(GET_BONUS_HISTORY_TRADE, params)
}
/** 赠金止盈止损列表 */
export function getBonusStopProfitLossList(params: GetEntrustParams) {
  return getCaptured(GET_BONUS_STOP_PROFIT_LOSS, params)
}
/** 赠金设置止盈止损 */
export function setBonusStopProfitLoss(data: SetStopProfitLossParams) {
  return postCaptured(SET_BONUS_STOP_PROFIT_LOSS, data)
}
/** 赠金取消止盈止损 */
export function cancelBonusStopProfitLoss(data: CancelStopProfitLossParams) {
  return postCaptured(CANCEL_BONUS_STOP_PROFIT_LOSS, data)
}
interface GetPositionHistoryParams {
  symbol_id?: string
  start_time?: string
  end_time?: string
  date_type?: PositionHistoryTimeType
}
/** 赠金仓位历史 */
export function getBonusPositionHistory(params: GetPositionHistoryParams) {
  return getCaptured(GET_BONUS_POSITION_HISTORY, params)
}
/** 赠金可交易资产信息 */
export function getBonusTradeable(params: { token_ids: string, exchange_id: number | string }) {
  return getCaptured(GET_BONUS_TRADEABLE, params)
}
/** 赠金撤单 */
export function cancelBonusOrder(data: CancelEntrustParams) {
  return postCaptured(CANCEL_BONUS_ORDER, data)
}
/** 赠金止盈止损历史委托 */
export function getBonusStopProfitLossHistory(params: GetEntrustParams) {
  return getCaptured(GET_BONUS_HISTORY_STOP_LOSS_ENTRUST, params)
}
/** 赠金委托成交明细 */
export function getBonusEntrustTradeDetail(params: GetEntrustMatchInfoParams) {
  return getCaptured(GET_BONUS_ENTRUST_MATCH_INFO, params)
}
/** 赠金止盈止损订单详情 */
export function getBonusOrderInfo(params: { order_id: string }) {
  return getCaptured(GET_BONUS_ORDER_INFO, params)
}
